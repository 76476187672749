const fr = {
  title: 'Titre',
  descriptionChallengePlaceholder: 'Veuillez enregistrer toutes les modifications que vous avez apportées avant de quitter cette page',
  answerPlaceholder: 'Veuillez entrer la réponse ici',
  answerTitle: 'Réponse',
  addInfo: 'Ajouter des informations',
  addFAQs: 'Ajouter des FAQ',
  addFAQ: 'Ajouter une FAQ',
  createChallengeTitle: 'Nouveau défi',
  updateChallengeTitle: 'Mettre à jour le défi',
  challengeMyTitle: 'Mes défis',
  reviewChallengeTitle: 'La revue',
  regularUserTitle: 'Utilisateur régulier',
  expertTitle: 'Experte',
  decisionMakerTitle: 'Décideur',
  challengeWorkflowTitle: 'Workflow de défi',
  challengeExpiryTitle: 'Expiration du défi',
  logInTitle: 'Se connecter',
  errorPleaseFillOutThisField: 'Veuillez remplir ce champ',
  signUpTitle: 'S\'inscrire',
  forgotPasswordHeader: 'Mot de passe oublié?',
  verificationCodeHeader: 'Vérifiez votre email',
  forgotPasswordDescription: 'Entrez votre email ici. Nous vous enverrons un code de vérification.',
  verificationCodeDescription: 'Nous vous avons envoyé un code de vérification par e-mail. Si vous ne le trouvez pas, veuillez vérifier votre dossier spam!',
  yourFirstNameTitle: 'Ton prénom',
  yourLastNameTitle: 'Votre nom de famille',
  emailTitle: 'Email',
  enterEmailTitle: 'Entrez l\'e-mail et cliquez sur la touche "Entrée" du clavier pour ajouter',
  inviteUsersHeaderTitle: 'Veuillez saisir un e-mail pour inviter un nouvel utilisateur. Après chaque e-mail, cliquez sur la touche Entrée (ou Retour) de votre clavier. Veuillez ajouter autant d\'e-mails que vous le souhaitez.',
  passwordTitle: 'Mot de passe',
  confirmPasswordTitle: 'Confirmez le mot de passe',
  otpTitle: 'Entrez votre code à 6 chiffres',
  confirmationCode: 'Code de vérification',
  newPasswordTitle: 'nouveau mot de passe',
  termsConditionsText: "J'accepte les Swae",
  termsConditionsLink: 'Termes et conditions',
  forgotPasswordLink: 'Mot de passe oublié?',
  tryAgainLink: 'Vous n\'avez pas reçu de code?',
  securityLink: 'Sécurité',
  startTitle: 'Début',
  endTitle: 'Fin',
  dateTitle: 'Date',
  helpLink: 'Aidez-moi',
  firstNameError: 'Entrez votre prénom.',
  lastNameError: 'Saisissez votre nom de famille.',
  emailError: 'Saisissez un e-mail.',
  genderError: 'Sélectionnez le sexe.',
  ageError: 'Sélectionnez l\'âge.',
  sectorError: 'Sélectionnez le secteur.',
  underRepresentedGroupsError: 'Sélectionnez des groupes sous-représentés.',
  locationError: 'Sélectionnez l\'emplacement.',
  countryNationalityError: 'Sélectionnez la nationalité.',
  organisationError: 'Entrez dans l\'organisation.',
  emailValidationError: 'S\'il vous plaît, mettez une adresse email valide.',
  otpError: 'Saisissez un code de vérification.',
  passwordError: 'Entrer un mot de passe.',
  passwordValidationError: 'Utilisez 8 caractères ou plus avec un mélange de lettres et de chiffres en minuscules et en majuscules.',
  confirmPasswordValidationError: 'Le mot de passe ne correspond pas.',
  confirmPasswordError: 'Retapez le mot de passe.',
  logInButton: 'Continuer',
  oktaLogInButton: 'Connectez-vous avec Okta',
  signUpButton: 'Continuer',
  resetPasswordButton: 'Réinitialiser le mot de passe',
  verifyButton: 'Vérifier',
  continueButton: 'Continuer',
  commentsTitle: 'Débat',
  commentsDescription: 'Veuillez ajouter vos commentaires constructifs.',
  get commentTopConsTitle () {
    return 'Faiblesses'
  },
  commentTopNeutralTitle: 'Des questions',
  get commentTopProsTitle () {
    return 'Forces'
  },
  noCommentMessage: "Pas de retour d'information",
  commentsClosedTitle: 'Débat [clos]',
  commentsClosedDescription: 'Ces commentaires proviennent de la phase de collaboration et de vote.',
  postCommentDialogTitle: 'Ajouter des commentaires',
  get postAgainstCommentButtonText () {
    return `Poster une ${this.weakness}`
  },
  postNeutralCommentButtonText: 'Poster une question',
  get postProCommentButtonText () {
    return `Poster une ${this.strength}`
  },
  maxLimit: 'La limite maximale de caractères est de 125',
  noProposalMessage: 'Aucune proposition!',
  myOrganizationsDetails: 'Détails de mon organisation',
  customFaqsTitle: 'FAQ',
  flaggedBy: 'Signalé par',
  commentText: 'Retour d\'information',
  viewText: 'Vue',
  deletedText: 'Effacé',
  allowedText: 'Permise',
  deleteText: 'Supprimer',
  allowText: 'Autoriser',
  commentor: 'Commenteur',
  successDialogTitle: 'Succès',
  successDialogPrimaryButtonText: 'D\'accord',
  successDialogContentText: 'Votre proposition a été soumise à l\'examen de l\'IA augmentée par l\'homme. Un analyste humain examinera le travail de notre IA avant de vous renvoyer votre proposition, pour garantir la qualité et ajouter quelques touches personnelles. Ce processus prend normalement jusqu\'à 24 heures.',
  flaggedCommentsSubTitle: 'Veuillez consulter ces commentaires signalés par les utilisateurs.',
  inputType: 'Veuillez taper ici',
  shortAnswerPlaceHolder: 'Veuillez saisir jusqu\'à 125 caractères',
  customLinksTitle: 'Liens personnalisés',
  addAnotherAwardTitle: 'Ajouter un autre prix',
  addAnotherFaqTitle: 'Ajouter une autre FAQ',
  addAnotherLinkTitle: 'Ajouter un autre lien',
  themeTitle: 'Thème',
  customAwardsTitle: 'Prix',
  descriptionTitle: 'Choisissez des récompenses personnalisées dans les ajouts aux mieux notés, au plus grand influenceur, à l\'engagement le plus élevé, au meilleur collaborateur ',
  cancelButton: 'Annuler',
  saveButton: 'Enregistrer',
  companyName: 'Nom de la compagnie',
  logoTitle: 'Logo',
  videoExplainerTitle: 'Vidéo de marque',
  dragAndDropLogoHere: 'Faites glisser et déposez une image ici',
  dragAndDropVideoHere: 'Glissez et déposez la vidéo ici',
  or: 'ou',
  imageTaille: '150 x 100 px',
  browseFiles: 'Parcourir les fichiers',
  termsAndConditionsLinkName: 'Termes et conditions Nom du lien',
  termsAndConditionsLink: 'Lien vers les conditions générales',
  welcomeVideoTitle: 'Vidéo de bienvenue',
  welcomeVideoDescription: 'Description de la vidéo de bienvenue',
  introductionTitle: 'Introduction',
  introductionText: 'Pré-requis',
  introductionDescription: 'certaines questions sont automatiquement incluses: titre de la proposition, résumé, photos et vidéos',
  questionTitle: 'Question',
  sectionTitle: 'Nom de la section',
  ifAnswerIsTitle: 'Si la réponse est',
  jumpToSectionTitle: 'Aller à la section',
  requiredText: 'Obligatoire',
  sectionPlaceholder: 'Entrez un nom de section.',
  questionTitlePlaceholder: 'Entrez un titre de question.',
  sectionError: 'Entrez un nom de section.',
  questionError: 'Entrez une question.',
  placeHolderError: 'Entrez un texte d\'espace réservé.',
  optionError: 'Entrez un nom d\'option.',
  addSectionTitle: 'Ajouter une section',
  addQuestionTitle: 'Ajouter une question',
  addOptionTitle: 'Ajouter une option',
  deleteSection: 'Supprimer la section',
  deleteButton: 'Supprimer',
  copy: 'Copie',
  Move: 'Bouge toi',
  createFilter: 'Créer un filtre',
  logicJumpsTitle: 'Ajouter un saut logique',
  maxChoices: 'Max choix',
  date: 'Date',
  time: 'Temps',
  headerDescription: 'Personnalisez le processus de votre équipe pour mieux répondre aux besoins de votre organisation',
  aiReviewWorkflow: 'Revue AI',
  collaborationWorkflow: 'Collaboration',
  votingWorkflow: 'Voter',
  managementReviewWorkflow: 'Examen du gestionnaire',
  daysMaxWorkflow: 'Jours max',
  proposal: 'Proposition',
  viewButton: 'Vue',
  workflowStage: 'Étape du workflow',
  stageType: 'Type de scène',
  customWorkflowDescription: 'Description du workflow personnalisé',
  durationTitle: 'Durée',
  permissionsTitle: 'Autorisations',
  setEscalationCriteriaTitle: 'Définir les critères d\'escalade',
  setEscalationCriteriaDescription: 'Choisissez des critères qui dégénèrent une proposition',
  averageScore: 'Score moyen',
  minimumNumberOfVotes: 'Nombre minimum de votes',
  totalNumberOfComments: 'Nombre minimum de commentaires',
  get totalNumberOfProComments () {
    return `Nombre minimal de commentaires sur les ${this.strength}`
  },
  get totalNumberOfConComments () {
    return `Nombre minimal de commentaires sur les ${this.weakness}`
  },
  totalNumberOfProposalViews: 'Nombre minimum de vues de proposition',
  totalNumberOfVolunteers: 'Nombre minimum de volontaires',
  inviteButtonText: 'Inviter',
  inviteUsersButtonText: 'Inviter des utilisateurs',
  cancelButtonText: 'Annuler',
  noMoreInviteMessage: 'Bravo! Vous avez tout vu',
  noMoreFlaggedCommentsMessage: 'Bravo! Vous avez tout vu',
  noInviteMessage: 'No Invite!',
  addButtonText: 'Ajouter',
  removeButtonText: 'Retirer',
  expiryDateTitle: 'Date d\'expiration',
  inviteMgrHeaderTitle: 'Gestionnaire d\'invitation',
  shareButtonText: 'Partager',
  userPreTitle: 'Salut, je suis',
  userNoTitle: 'Salut, votre gestionnaire d\'intégration à Swae',
  userPostTitle: ', PDG de Swae',
  contactTitle: 'Contact',
  bookACallTitle: 'Réserver un appel',
  viewTitle: 'Vue',
  userPreSubTitle: 'Vous avez',
  userPostSubTitle: 'laissé dans votre intégration avec services. Permet de configurer votre Swae! ',
  workflowPrimary: 'Personnalisez votre workflow',
  questionnairePrimary: 'Customize Questionnaire',
  resourcesPrimary: 'Vos ressources',
  setPermissionsPrimary: 'Définir les autorisations',
  createWorkSpacePrimary: 'Créez votre espace de travail',
  profileInfoPrimary: 'Personnalisation des informations de profil',
  emailTemplatePrimary: 'Personnalisez votre messagerie',
  teamDescriptionPrimary: 'Description de l\'environnement',
  workflowSecondary: 'Comment vos utilisateurs structurent leurs propositions',
  questionnaireSecondary: 'Comment vos utilisateurs structurent leurs propositions',
  resourcesSecondary: 'Vos informations, FAQ, récompenses et liens',
  setPermissionsSecondary: 'Attribuer des rôles d\'administrateur, des gestionnaires et des experts.',
  createWorkSpaceSecondary: 'Créez votre espace de travail',
  profileInfoSecondary: 'Configurez les informations que vous souhaitez collecter sur vos utilisateurs',
  emailTemplateSecondary: 'Personnalisez les notifications que vos utilisateurs verront',
  teamDescriptionSecondary: 'Les informations générales sur votre espace de travail',
  noBoardingMessage: 'Pas d\'embarquement!',
  proposalTrendsTitle: 'Propos Trends',
  contributionsTitle: 'Contributions',
  votesTitle: 'Votes',
  proposalNumbersTitle: 'Numéros de proposition',
  timeTitle: 'Temps',
  usersTitle: 'Utilisateurs',
  activeUsersTitle: 'Utilisateurs actifs',
  totalsTitle: 'Totaux',
  averagesTitle: 'Moyennes',
  propositionTrendsTitle: 'Propos Trends',
  propositionNumériquesTitre: 'Numéros de proposition',
  noData: 'Aucune donnée',
  downloadCustomReportsButton: 'Télécharger des reports personnalisés',
  updateReportsButton: 'Mettre à jour les rapports',
  closeCustomReportsButton: 'Fermer les reports personnalisés',
  fromLaunchDateTitle: 'À partir de la date de lancement:',
  fromDateTitle: 'De la date:',
  toDateTitle: 'To Date:',
  downloadButton: 'Télécharger',
  noMoreProposalsMessage: 'Bravo! Vous avez tout vu',
  noMoreCommentsMessage: 'Bravo! Vous avez tout vu',
  noMoreUserMessage: 'Bravo! Vous avez tout vu',
  confirmDeleteDialogTitle: 'Delete',
  confirmDeleteDialogContentText: 'Êtes-vous sûr de vouloir supprimer',
  confirmDeleteDialogPrimaryButtonText: 'OK',
  noAward: 'No Awards!',
  submitAwards: 'Soumettre Prix',
  awardTitlePlaceholder: 'Ajoutez votre titre de récompense personnalisé',
  awardDescPlaceholder: 'Ajoutez ici plus de détails sur votre récompense personnalisée',
  confirmDeleteDialogSecondaryButtonText: 'Cancel',
  confirmAwardReplaceDialogPrimaryButtonText: 'Oui',
  confirmAwardReplaceDialogSecondaryButtonText: 'Non',
  confirmAwardReplaceDialogTitle: 'Conform',
  confirmAwardReplaceDialogContentText: 'Ce prix a déjà été attribué à quelqu\'un d\'autre que vous souhaitez remplacer.',
  draftsTitle: 'Brouillons',
  createdTitle: 'Établi',
  aiReviewTitle: 'Examen de l\'IA',
  sentToAiReviewTitle: 'Envoyé à la revue AI',
  aiReviewedTitle: 'Revue AI',
  collaborationTitle: 'Collaborer',
  voteTitle: 'Vote',
  proposalHeaderTitle: 'Paramètres de notification par e-mail de la proposition',
  underManagementReviewTitle: 'Examen en cours de gestion',
  acceptedTitle: 'Adoptée',
  rejectedTitle: 'Rejeté',
  implementedTitle: 'Mis en œuvre',
  archivedTitle: 'Archivé',
  noProposalsMessage: 'Pas de propositions!',
  domainTitle: 'Domaine',
  teamNameTitle: 'Nom de l\'équipe',
  noTeamsMessage: 'Pas d\'équipes',
  noMoreTeamsMessage: 'Yay! Vous avez tout vu',
  noChallengesMessage: 'Pas de défi!',
  noNotificationSettings: 'Pas de paramètres de notification!',
  noCreateChallenge: 'Pas de défi',
  addUsers: 'Ajouter des utilisateurs',
  updateQuestionnaire: 'Mettre à jour le questionnaire',
  updateWorkflow: 'Mettre à jour le workflow',
  propositionHeaderTitle: 'Paramètres de notification par e-mail de proposition',
  welcomeTitle: 'Bienvenue à',
  dotTitle: '.',
  linkTitle: 'Liens',
  fAQsTitle: 'FAQ',
  startDateEndDateWorkFlow: 'Veuillez sélectionner une date et une heure supérieures à la date de début',
  expiryDateEndDateWorkFlow: "Veuillez sélectionner une date et une heure inférieures à la date d'expiration",
  expiryDateChallenge: 'Veuillez sélectionner une date et une heure postérieures à la date de fin d\'examen',
  activeTitle: 'Active',
  expiredTitle: 'Expiré',
  qTitle: 'Q.',
  aTitle: 'UNE.',
  awardsTitle: 'Prix',
  propositionWorkflowTitle: 'Proposition Workflow',
  aiReview: 'Examen de l\'IA',
  collaboration: 'Collaboration',
  vote: 'Vote',
  descriptionResourcesTitle: 'Trouvez de nombreuses ressources utiles, des conseils et des solutions pour vous aider à mettre vos meilleures idées au sommet de la pile!',
  newProposal: 'Nouvelle proposition',
  startANewProposal: 'Démarrer une nouvelle proposition',
  firstNameTitle: 'Prénom',
  lastNameTitle: 'Nom de famille',
  roleTitle: 'Rôle',
  homeTitle: 'Accueil',
  feedTitle: 'Alimentation',
  brandingTitle: 'Image de marque',
  proposalsTitle: 'Les propositions',
  teamRequestTitle: 'Demande d\'équipe',
  newProposalTitle: 'Nouvelle proposition',
  myProposalsTitle: 'Mes propositions',
  myBookMarksTitle: 'Mes marque-pages',
  collaborateTitle: 'Collaborer',
  resourcesTitle: 'Ressources',
  graduatedTitle: ' Retenue',
  managementTitle: 'Gestion',
  underReviewTitle: 'À l\'étude',
  dashboardTitle: 'Tableau de bord',
  noFlaggedCommentsMessage: 'Aucun commentaire signalé!',
  managementProposalsTitle: 'Gestions propositions',
  healthReportTitle: 'Bilan de santé',
  teamInsightTitle: 'Aperçu d\'équipe',
  adminTitle: 'Admin',
  volunteersTitle: 'Bénévoles',
  proposalReviewTitle: 'Étude de la proposition',
  flaggedCommentsTitle: 'Signalé',
  emailNotificationSettingsTitle: 'Paramètres de notification par e-mail',
  refreshEmailTemplates: 'Refresh Email Templates',
  questionnaireTitle: 'Questionnaire',
  onBoardingTitle: 'Orientation',
  userManagementTitle: 'Utilisateurs',
  inviteUsersTitle: 'Inviter des utilisateurs',
  reportsTitle: 'Reports',
  workflowTitle: 'Processus',
  logoutTitle: 'Log Out',
  propositionChargement: 'Chargement ...',
  searchProposal: 'Rechercher une proposition',
  searchUser: 'Rechercher un utilisateur',
  inappropriateContentText: 'Cette proposition a été supprimée pour des termes disciplinaires ou inappropriés',
  quickVoteText: 'Vote rapide',
  closeText: 'Fermer',
  voteCastMessage: 'Faites glisser la barre pour voter. Les résultats apparaîtront une fois que vous aurez soumis votre vote!',
  reasonDialogTitle: 'Raison',
  reasonDialogText: 'Raison',
  reasonDialogTitleForAccepting: "Motif d'acceptation",
  reasonDialogTitleForRejecting: 'Motif du rejet',
  reasonDialogContentText: 'Veuillez indiquer à cet auteur la raison de cette décision',
  reasonDialogButtonText: 'Soumettre',
  readMoreText: 'En savoir plus',
  readLessText: 'Lire moins',
  disagreeText: 'Être en désaccord',
  agreeText: 'J\'accepte',
  submitVoteText: 'Soumettre vote',
  viewReason: 'Afficher la raison',
  closeReason: 'Fermer la raison',
  anonymousText: 'Anonymous',
  rejectedText: 'Rejeté',
  resourcesOnBoardingTitle: 'Configurer Image de marque',
  questionnaireOnBoardingTitle: 'Configurer le questionnaire',
  workflowOnBoardingTitle: 'Configurer le workflow',
  setPermissionsOnBoardingTitle: 'gérer les utilisateurs',
  dialogTitleCollaborator: 'Inviter un collaborateur',
  dialogTitleVoter: 'Invite Voter',
  dialogTitleEndorse: 'Nom du département',
  emailAddress: 'Adresse e-mail',
  yourDepartmentName: 'Entrez votre département',
  dialogContentTextCollaborator: 'Ajoutez l\'adresse e - mail ci - dessous pour inviter un nouveau collaborateur à cette idée!',
  dialogContentTextVoter: 'Ajoutez l\'adresse e - mail ci - dessous pour inviter un nouvel électeur à cette idée!',
  dialogButtonText: 'Inviter',
  dialogSubmitButtonText: 'Soumettre',
  collaborateText: 'Collaborer',
  voteText: 'Vote',
  voteSubText: "Veuillez voter pour faire entendre votre voix sur l'opportunité de donner suite à cette proposition",
  voteClosedText: 'Le vote est maintenant clos',
  propositionReviewText: 'Examen de la proposition',
  submitText: 'Soumettre',
  saveDraftText: 'enregistrer le brouillon',
  anonymizeText: 'Anonymiser',
  edit: 'Éditer',
  saveText: 'Sauvegarder',
  votingText: 'Vote',
  sendForHumanAugmentedAiReviewText: 'Analyser par l\'homme',
  votingSubText: 'Veuillez voter pour faire entendre votre voix sur l\'opportunité de donner suite à cette proposition.',
  analyzeText: 'Ré-analyse par l\'IA',
  imageFormatUploadInstruction: '*Format d\'image pris en charge: .jpeg | .jpg | .png | .gif',
  videoFormatUploadInstruction: '*Format vidéo pris en charge: .mp3 | .mp4 | .mov',
  videoSizeUploadInstruction: '*Taille vidéo maximale: 150 Mo',
  imageSizeUploadInstruction: '*Taille d\'image maximale: 5 Mo',
  backText: 'Arrière',
  nextText: 'Suivante',
  acceptButtonText: 'Accepter',
  rejectButtonText: 'Rejeter',
  votingResultsText: 'Résultats du vote',
  shareWithManagersText: 'Partager avec d\'autres gestionnaires',
  submitForVoteText: 'Soumettre pour vote',
  endorseNowText: 'Endorse Now',
  volunteerText: 'Volontaire',
  voteResultsText: 'Résultats du vote',
  remainingCriteriaHeading: 'Presque là!',
  remainingCriteriaSubHeading: 'Le vote s\'ouvrira',
  afterSubmitForVote: 'après avoir soumis au vote',
  autoSaveInited: 'Enregistrement automatique du document!',
  autoSaveSuccess: 'Enregistrement automatique réussi! Document à jour.',
  autoSaveInitedAnswer: 'Réponse de sauvegarde automatique!',
  autoSaveSuccessAnswer: 'Sauvegarde automatique réussie! Réponse à jour.',
  autoSaveFailed: 'Échec de l\'enregistrement automatique!',
  aiReviewText: 'Examen de l\'IA',
  aiReviewedText: 'Examen de l\'IA',
  partialReviewText: 'Revue partielle',
  sendForUserText: 'Envoyer pour examen par l\'utilisateur',
  publishText: 'Publier',
  feedbackTitle: 'Retour d\'information',
  timelineDialogTitle: 'Timeline',
  saveButtonText: 'Enregistrer',
  undoButtonText: 'Annuler',
  replyButtonTitle: 'Répondre',
  repliesTitleSingular: 'Répondre',
  loadMoreButtonText: 'Charger plus',
  flagCommentCancelButtonText: 'Cancel',
  flagCommentSubmitButtonText: 'Soumettre',
  flagCommentTitle: 'Dites - nous pourquoi vous signalez',
  commentTextError: 'Veuillez saisir des commentaires valides.',
  indefinitelyText: 'indéfiniment',
  aiSummaryHeader: 'Résumé de l\'IA',
  summaryHeader: 'Résumé',
  AcceptText: 'Adoptée',
  hurrayTitle: 'Hourra!',
  yourProposalText: 'Votre proposition',
  hasBeenSuccessfullyText: ' a été avec succès',
  publishedText: 'publié.',
  pageText: 'page.',
  editYourProposalText: 'Modifier votre proposition',
  createAiReviewMessageText: 'Les analystes de Swae en IA vous donneront leurs suggestions à réviser sous peu et vous pouvez vérifier le statut dans ',
  createCollaborateMessageText: 'Vos collègues peuvent désormais collaborer sur votre proposition et vérifier l\'état dans ',
  submittedToHumanAugmentedAiReviewText: 'soumis à l\'examen de l\'IA augmentée par l\'homme.',
  noMoreVolunteerMessage: 'Bravo! Vous avez tout vu ',
  noVolunteerMessage: 'No Volunteers!',
  volunteerButtonText: 'Bénévole maintenant',
  partialAIFeedbackDialogTitle: 'Retour d\'information',
  partialAIFeedbackDialogButtonText: 'Soumettre',
  partialAIFeedbackDialogButtonSkipText: 'Passer à la publication',
  confirmDialogTitle: 'Confirmer',
  confirmDialogContentText: 'Vos modifications dans le résumé AI seront perdues par cette action. Êtes-vous sur de vouloir continuer',
  confirmDialogPrimaryButtonText: 'OK',
  confirmDialogSecondaryButtonText: 'Cancel',
  proposalWorkflowTitle: ' Processus des propositions',
  proposalLoading: 'Chargement...',
  collabDesc: 'Trouver de nouvelles propositions',
  voteDesc: 'Aider à trouver notre prochain grand succès',
  averageScoreMet: 'Score moyen des votes rencontrés',
  voteCriteriaMet: 'Critères des électeurs remplis',
  feedbackCriteriaMet: 'Critères de rétroaction satisfaits',
  get riskFeedbackCriteriaMet () {
    return `Critères de rétroaction sur les ${this.weakness} remplis`
  },
  get neutralFeedbackCriteriaMet () {
    return `${this.question} critères remplis`
  },
  get evidenceFeedbackCriteriaMet () {
    return `Rétroaction des ${this.strength} rencontrée`
  },
  viewCriteriaMet: 'Voir les critères remplis',
  requiredAverageScore: '% plus de score moyen des votes requis',
  requiredVotes: "plus d'électeurs requis",
  requiredFeedbacks: 'plus de commentaires requis',
  get requiredEvidenceFeedbacks () {
    return `plus de ${this.strength.toLowerCase()} requises`
  },
  get requiredNeutralFeedbacks () {
    return `plus de ${this.question.toLowerCase()} requises`
  },
  get requiredRiskFeedbacks () {
    return `plus de ${this.weakness.toLowerCase()} requis`
  },
  requiredViews: 'plus de vues nécessaires',
  requiredVolunteers: 'plus de bénévoles requis',
  confirmSubmitVoteDialogTitle: 'Soumettre vote',
  confirmSubmitVoteDialogContentText1: 'Votre vote est',
  confirmSubmitVoteDialogContentText2: 'Une fois que vous avez confirmé, vous ne pouvez pas modifier votre vote.',
  confirmSubmitVoteDialogPrimaryButtonText: 'Soumettre',
  confirmSubmitVoteDialogSecondaryButtonText: 'Annuler',
  againstText: 'Contre',
  abstainText: 'Abstention',
  forText: 'Pour',
  hideTitle: 'Cacher',
  repliesTitle: 'Réponses',
  createPassword: 'Créer un mot de passe',
  collaborationEnded: "La soumission d'une nouvelle proposition a pris fin le {endDate}",
  VerificationLink: 'Besoin de nouveau de code de vérification?',
  resendVerificationLink: 'Renvoyer le code de vérification',
  reviewsText: 'Commentaires',
  myProfileTitle: 'Mon profil',
  showMyVoteText: 'Tu as voté',
  challengeTimelineTitle: 'Défis',
  noReports: 'Il n\'y a pas de défis créés par vous',
  noChallengeMessage: 'Il n\'y a aucun défi créé par vous.',
  reasonForDeletionText: 'Si oui, veuillez indiquer le motif de votre suppression.',
  noUserMessage: 'Aucun utilisateur!',
  pageNotFound: 'Page non trouvée!',
  accessDenied: 'Accès refusé',
  accessDeniedMessage: "Vous n'êtes pas autorisé à afficher cette page.",
  replaceWith: 'Remplacer par',
  replace: 'Remplacer',
  grammarAnalysis: 'Analyse grammaticale',
  readabilityAnalysis: 'Analyse de lisibilité',
  toneAnalysis: 'Analyse de tonalité',
  escalationCriteriaText: "Critères d'escalade",
  selectChallengeText: 'Sélectionnez un défi',
  hideChallenge: 'Masquer les détails du défi',
  showChallenge: 'Détails du défi',
  challengeAllTitle: 'Tous les défis',
  challengeTitle: 'Titre du défi',
  noActiveChallenge: 'Pas de défi actif',
  noExpiredChallenge: 'Aucun défi expiré',
  inappropriateFeedback: 'Ce commentaire a été supprimé pour un langage disciplinaire ou inapproprié',
  strength: 'force',
  question: 'Question',
  weakness: 'la faiblesse',
  siteAdminTitle: 'Site Admin',
  challengeLeaderTitle: 'Challenge Leader',
  deleteLogicJumpSection: 'Cette section contient un saut logique, veuillez supprimer le saut logique et le supprimer à nouveau!',
  formatProposalText: 'format avec éditeur de texte enrichi',
  editrteButtonText: 'Modifier avec les outils de mise en forme',
  activeUsersTabLabel: 'Actif',
  pendingUsersTabLabel: 'En attente',
  inviteUsersTabLabel: 'Inviter',
  invitedByTitle: 'Inviter par',
  confirmDialogRTEContentText: 'Confirmer: notre moteur AI ne peut plus analyser votre proposition une fois que vous l\'avez mise en forme. Êtes-vous sûr de vouloir continuer maintenant?',
  get noStrengthsFeedback () {
    return 'Pas de forces'
  },
  get noQuestionsFeedback () {
    return 'Pas de questions'
  },
  get noWeaknessesFeedback () {
    return 'Pas de faiblesses'
  },
  answerText: 'Répondre',
  statsHeader: 'Statistiques',
  postButton: 'Publier',
  implementText: 'Mettre en place',
  deletedTitle: 'Supprimé',
  noNotifications: 'Aucune notification',
  notificationsTitle: 'Notifications',
  genderTitle: 'Le genre*',
  ageTitle: 'Âge*',
  sectorTitle: 'Secteur*',
  underRepresentedGroupsTitle: 'Je m\'identifie à un groupe sous-représenté *',
  countryNationalityTitle: 'Pays d\'origine*',
  locationTitle: 'Lieu*',
  organizationTitle: 'Organisation',
  anonymousTitle: 'Je désire rester anonyme*',
  // Translation Part-2
  myFeed: 'Mon flux',
  recentlyViewed: 'vu récemment',
  feed: 'Nourrir',
  searchWhatInterestsYou: 'Cherchez ce qui vous intéresse',
  create: 'Créer',
  collaborate: 'Collaborer',
  selectAMission: 'Sélectionnez une mission',
  missionCreator: 'Créateur de missions',
  newMission: 'Nouvelle mission',
  myActivities: 'Mes activités',
  howItWorks: 'Comment ça fonctionne',
  following: 'Suivant',
  viewAll: 'Voir touts',
  addMission: 'Ajouter une mission',
  insights: 'Connaissances',
  siteAdminSettings: 'Paramètres d\'administration du site',
  settings: 'Réglages',
  inviteAColleague: 'Inviter un collègue',
  support: 'Soutien',
  suggestMission: 'Suggérer une mission',
  proposalReview: 'Examen de la proposition',
  users: 'Utilisateurs',
  branding: 'l\'image de marque',
  security: 'Sécurité',
  onboarding: 'Intégration',
  adminDashboard: 'Tableau de bord administrateur',
  editHomepage: 'Modifier la page d\'accueil',
  billing: 'Facturation',
  notifications: 'Notifications',
  organizationName: 'Nom de l\'organisme', // Branding Page
  accountUrl: 'URL du compte',
  logo: 'Logo',
  siteDesign: 'Conception de sites',
  termsAndConditionDescription: 'Termes & Conditions Description',
  termsAndConditions: 'Termes et conditions',
  type: 'Taper',
  externalLink: 'lien externe',
  editor: 'Éditeur',
  link: 'Lien',
  jpgPng2MBmax: 'JPG, PNG, 2 Mo maximum',
  webAddress: 'Adresse web',
  totalUsers: 'Nombre total d\'utilisateurs',
  pendingInvites: 'Invitations en attente',
  pending: 'En attente',
  invite: 'Inviter',
  firstName: 'Prénom',
  surname: 'Nom de famille',
  status: 'Statut',
  memberSince: 'Membre depuis',
  lastSeenLocation: 'Vu pour la dernière fois/ Emplacement',
  email: 'E-mail',
  permissions: 'Autorisations',
  save: 'Sauvegarder',
  searchForUser: 'Rechercher un utilisateur',
  inviteDescriptionText: 'Veuillez saisir une adresse e-mail pour inviter un nouvel utilisateur. Après chaque e-mail, cliquez sur la touche Entrée (ou Retour) de votre clavier. Veuillez ajouter autant d\'e-mails que vous le souhaitez.',
  ByNameOrEmail: 'Par nom ou email',
  resend: 'Renvoyer',
  home: 'Accueil',
  about: 'Sur',
  editAbout: 'Éditer',
  sectionType: 'Type de section',
  richText: 'Texte riche',
  updates: 'Mises à jour',
  start: 'Démarrer',
  end: 'Finir',
  sponsors: 'Commanditaires',
  decisions: 'Les décisions',
  escalation: 'Escalade',
  awards: 'Prix',
  faQs: 'FAQ',
  engagement: 'Engagement',
  leaderboard: 'Classement',
  milestones: 'Jalons',
  missions: 'Missions',
  comments: 'commentaires',
  proposals: 'Les propositions',
  createNewProposal: 'Créer une nouvelle proposition',
  active: 'Active',
  drafts: 'Brouillons',
  draft: 'Brouillon',
  underReview: 'À l\'étude',
  archived: 'Archivé',
  declined: 'Diminué',
  accepted: 'Accepté',
  implemented: 'Mis en œuvre',
  new: 'Nouveau',
  trending: 'Tendance',
  buzzing: 'Bourdonnement',
  highlyRated: 'Bien noté',
  nearlyDecisionReady: 'Presque prêt à prendre une décision',
  needsSupport: 'Besoin d\'aide',
  awarded: 'Récompensé',
  endingSoon: 'Bientôt se terminant',
  missionGoals: 'Objectifs de la mission',
  sponsorsAndStakeholders: 'Commanditaires et parties prenantes',
  escalationMetrics: 'Mesures d\'escalade',
  missionMilestones: 'Jalons de la mission',
  missionGoalsDescriptionText: 'Lorsque vous soumettez des propositions, veuillez garder ces objectifs de mission à l\'esprit. Nous visons à',
  decisionsDescriptionText: 'Un examen de toutes les propositions retenues sera effectué par les dirigeants ci-dessous. Ils donneront les raisons du résultat, qui peut être l\'une des suivantes',
  declinedDescription: 'ne convient pas actuellement',
  acceptedDescription: 'sera proposé pour la mise en œuvre',
  implementedDescription: 'sera mis en œuvre immédiatement',
  awardsDescription: '',
  configureBranding: 'Configurer la marque', // Onboarding Page
  configureQuestionnaire: 'Configurer le questionnaire',
  configureWorkflow: 'Configurer le flux de travail',
  manageUsers: 'gérer les utilisateurs',
  view: 'Voir',
  bookACall: 'RÉSERVER UN APPEL',
  introText: 'Salut je suis',
  welcomeBack: 'Content de te revoir {name}', // Admin Dashboard page
  welcomeBackDescription: 'Votre Swae bourdonne, il y a quelques choses à faire !',
  flaggedComments: 'Commentaires signaléss',
  flaggedComment: 'Commentaire signalé',
  item: 'Article',
  whoAndWhen: 'Qui et quand',
  metrics: 'Métrique',
  dueDate: 'Date d\'échéance',
  action: 'action',
  snapshot: 'Instantané',
  activeProposals: 'Propositions actives',
  avgProposalVote: 'Vote moyen sur la proposition',
  loading: 'Chargement...',
  activity: 'Activité',
  siteViews: 'Vues du site',
  proposalStages: 'Étapes de la proposition',
  getMoreInsights: 'Obtenez plus d\'informations',
  ignored: 'Ignoré',
  deleted: 'Supprimé',
  missionLeader: 'Chef de mission',
  who: 'qui',
  intervene: 'Intervenir',
  reason: 'Raison',
  postedBy: 'Posté par',
  reportedBy: 'Rapporté par',
  deletedBy: 'Supprimé par ',
  ignoredBy: 'Ignoré par ',
  readLess: 'Lire moins',
  readMore: 'Lire la suite',
  abidesByCodeOfConduct: 'Respecte le code de conduite',
  allowComment: 'Autoriser les commentaires',
  removeComment: 'Supprimer le commentaire',
  ignoreComment: 'Ignorer le commentaire',
  createNewMission: 'Créer une nouvelle mission',
  commentsViolateCommunityGuidelinesAndStandards: 'Les commentaires violent les directives et les normes de la communauté',
  commentsAreInaccurateMisleadingOrNonFactual: 'Les commentaires sont inexacts, trompeurs ou non factuels',
  commentsUseProhibitedProfanity: 'Les commentaires utilisent des grossièretés interdites',
  commentsIntendedToSlanderOtherCommunityMembers: 'Commentaires destinés à diffamer les autres membres de la communauté',
  customReasonOther: 'Motivo personalizado/Otro',
  reportedFor: 'Rapporté pour',
  showMeMoreInsights: 'Montre-moi plus d\'idées',
  reviewNow: 'Revoir maintenant',
  oneMinTask: '1 minute de tâche',
  activeUsers: 'Utilisateurs actifs', // Insights page
  monthlyActiveUsers: 'Utilisateurs actifs mensuels',
  averageOverPeriod: 'Moyenne sur la période',
  changeOverPeriod: 'Variation sur la période',
  inactiveUsers: 'Utilisateurs inactifs',
  totalViews: 'Vues totales',
  totalEngagements: 'Engagements totaux',
  totalSuggestions: 'Nombre total de suggestions',
  totalSuggestedEdits: 'Total des modifications suggérées',
  totalComments: 'Total des commentaires',
  totalProposals: 'Nombre total de propositions',
  engagements: 'Engagements',
  votes: 'Votes',
  languages: 'Langages',
  nationality: 'Nationalité',
  suggestedEdits: 'Modifications suggérées',
  engagementsSubtitle: 'Le nombre de Propositions, Votes, Commentaires et Suggestions',
  proposalsSubtitle: 'Où se trouvent les propositions dans la plateforme',
  votesSubtitle: 'Le nombre de voix',
  commentsSubtitle: 'Le nombre de commentaires',
  suggestedEditsSubtitle: 'Le nombre de suggestions',
  languagesSubtitle: 'Quelles langues les gens utilisent-ils sur la plateforme ?',
  nationalitySubtitle: 'De quelle nation les utilisateurs sont-ils originaires ?',
  usersSubtitle: 'Combien de personnes utilisent Swae de {clientname}',
  hallofFame: 'temple de la renommée',
  filterBy: 'Filtrer par',
  overview: 'Aperçu',
  downloadXLSReport: 'Télécharger le rapport XLS',
  addYourMissionTitleHere: 'Ajoutez le titre de votre mission ici', // Mission Creation
  missionIsContinuous: 'La mission est continue',
  questionnaire: 'Questionnaire',
  saveAsDraft: 'Enregistrer comme brouillon',
  launchMission: 'Lancement de la mission',
  updateMission: 'Mettre à jour la mission',
  goals: 'Buts',
  enableGoals: 'Permettre aux objectifs',
  goalsDescription: 'Lors de la soumission de propositions pour cette mission, veuillez travailler dans ces objectifs ...',
  addGoalsHeading: 'Ajouter des objectifs à votre mission',
  addCustomGoals: 'Ajouter des objectifs personnalisés',
  decisionHeaderForMissionCreation: 'Autoriser les propositions à effacer les décisions',
  decisionMakersQuestion: 'Qui sont les décideurs?',
  searchLeadership: 'Chercher le leadership',
  howDoProposalsEscalate: 'Comment les propositions augmentent-elles?',
  proposalReviewProcessAndMetrics: 'Processus et métriques de révision de la proposition',
  reviewProcess: 'Processus de vérification',
  reviewProcessStar: 'Processus de vérification*',
  manuallyEscalatedByLeadership: 'Escaladé manuellement par le leadership',
  sendProposalsToReviewAutomatically: 'Envoyer des propositions à révision automatiquement, en fonction des métriques',
  escalatedUsingMetrics: 'Escaladé en utilisant des métriques',
  escalationCriteria: 'Critères d\'escalade',
  escalationCriteriaDescription: 'Les propositions qui répondent à ces critères seront envoyées à l\'examen des décideurs',
  proposalsEscalationMetrics: 'Métriques d\'escalade des propositions',
  maximumLengthProposalCanBeActive: 'La proposition de longueur maximale peut être active',
  howLongCanProposalStayActive: 'Combien de temps la proposition peut-elle rester active',
  minimumAverageScoreFromVotes: 'Note moyenne minimale des votes',
  minimumNumberOfVoters: 'Nombre minimum d\'électeurs',
  minimumNumberOfCollaborativeEdits: 'Nombre minimum de modifications collaboratives',
  minimumNumberOfStrengths: 'Nombre minimum de forces',
  minimumNumberOfConcerns: 'Nombre minimum de préoccupations',
  minimumNumberOfQuestions: 'Nombre minimum de questions',
  minimumNumberOfProposalViews: 'Nombre minimum de vues de proposition',
  awardsHeaderDescription: 'Pour surcharger vos idées, nous donnons de superbes récompenses sur cette mission ...',
  addYourCustomAwardTitle: 'Ajoutez votre titre de prix personnalisé',
  addYourCustomAwardDescription: 'Ajoutez plus de détails sur votre prix personnalisé ici',
  orUploadYourOwn: 'ou télécharger votre propre',
  awardIcon: 'Icône de prix',
  awardType: 'Type de prix',
  awardGoesToPerson: 'Prix ​​va à la personne',
  awardGoesToProposal: 'Prix ​​va à la proposition',
  addAward: 'Ajouter un prix',
  addAnotherAward: 'Ajouter une autre récompense',
  awardGoesTo: 'Le prix va à',
  to: 'À',
  awardName: 'Nommation de prix',
  assignAwards: 'Attribuer des prix',
  addFaqsHere: 'Ajouter des faq ici',
  addYourQuestionHere: 'Ajoutez votre question ici',
  addYourAnswerHere: 'Ajoutez votre réponse ici',
  reorder: 'Réorganiser',
  delete: 'Supprimer',
  addAnyMissionMilestones: 'Ajouter des jalons de mission',
  addMissionMilestonesDescription: 'Ajoutez vos détails ici',
  addMilestoneHere: 'Ajouter de la jalon ici',
  addUpdateHere: 'Ajouter la mise à jour ici',
  addUpdateHereStar: 'Ajoutez la mise à jour ici*',
  addYourDetailHere: 'Ajoutez vos détails ici',
  imagesJpgPngGif: 'Images: JPG, PNG, GIF',
  videoMpegMov: 'Vidéo: MPEG, MOV',
  maxFilesize20MbMaxFilesize: '20Mb max FileSize',
  // Wizard Builder
  bestForSimpleMissions: 'Mejor para misiones simples',
  createReacherFullFeaturedMissions: 'Crear un llegado, misiones con todas las funciones.',
  around3Minutes: 'Alrededor de 3 minutos',
  whatOutcomeDoYouWant: '¿Qué resultado quieres ...',
  outcomeAnswer:
    'SWAE se puede usar simplemente para hacer una lluvia de ideas o puede brindarle un flujo de trabajo de una parada de ideas a un proceso completo de toma de decisiones. Con la toma de decisiones habilitadas, es probable que los usuarios estén más comprometidos, sabiendo que sus ideas podrían ser accionadas.',
  around5Minutes: 'Alrededor de 5 minutos',
  around4Minutes30Seconds: 'Alrededor de 4 minutos 30 segundos',
  optional: 'Optionnel',
  recommended: 'conseillé',
  letsSetUpYourMission: 'Confilions votre missions',
  collectIdeasMakeDecisions: 'Recueillir des idées et prendre des décisions',
  justCollectIdeas: 'Il suffit de recueillir des idées',
  back: 'Retour',
  next: 'Suivant',
  cancel: 'Annuler',
  required: 'Obligatoire',
  titleAndDescription: 'Description du titre*',
  addMedia: 'Ajouter des médias',
  missionDuration: 'Durée de la mission *',
  decisionMakers: 'Décideurs',
  proposalEscalation: 'Escalade de la proposition *',
  addFaQs: 'Ajouter des faq',
  inviteUsers: 'Inviter les utilisateurs',
  customiseQuestions: 'Personnaliser les questions',
  missionTitle: 'Titre de la mission *',
  whatDoYouWantToSolve: 'Que voulez-vous résoudre?',
  description: 'La description',
  addMoreDetailsHere: 'Ajouter plus de détains ici ..',
  missionMedia: 'Média de mission',
  addFromLibrary: 'Ajouter de la bibliothèque',
  poweredBy: 'Alimenté par',
  findAnImage: 'Trouver une image',
  orAddYourOwn: '... ou ajoutez votre propre ...',
  startAtSpecificTime: 'Commencer à l\'heure précise',
  startRightAway: 'Commencer tout de suite',
  missionHasAClosingDate: 'La mission a une date de clôture',
  proposalsEscalation: 'Propositions d\'escalade',
  howAreProposalsEscalated: 'Comment les propositions sont-elles escaladées?',
  escalatedManuallyByLeaders: 'Escaladé manuellement par les dirigeants',
  whatHappensOnEscalation: 'Que se passe-t-il sur l\'escalade?',
  bothMethods: 'Les deux méthodes',
  proposalGoesToReview: 'La proposition va à revoir',
  proposalIsAutomaticallyAccepted: 'La proposition est automatiquement acceptée',
  whatAreYourGoalsForThisMission: 'Quels sont vos objectifs pour cette mission?',
  generateAHighQuantityOfProposals: 'Générer une grande quantité de propositions',
  idPreferFewerHigherQualityProposals: 'Je préférerais moins de propositions de qualité supérieure',
  iWantALotOfDiscussionOnProposals: 'Je veux beaucoup de discussions sur les propositions',
  illCustomiseTheMetricsMyself: 'Je vais personnaliser les métriques moi-même',
  addAnotherFaq: 'Ajouter une autre faq',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  access: 'Accès',
  missionIsOpenToThePublic: 'La mission est ouverte au public',
  onlySelectedUsers: 'Seuls les utilisateurs sélectionnés',
  searchUsers: 'Rechercher les utilisateurs',
  inviteByEmail: 'Inviter par email',
  searchForPeopleOrTeams: 'Recherche de personnes ou d\'équipes',
  customiseProposalBuilder: 'Personnaliser la proposition Builder',
  defaultQuestionsAddCustomOnes: 'Questions par défaut + Ajouter celles personnalisées',
  usingDefaultQuestions: 'Utilisation des questions par défaut',
  around30Seconds: 'Environ 30 secondes',
  launchYourMissionRightAway: 'Lancez votre mission tout de suite!',
  missionTitleSubCardText: 'Les titres courts et percutants aident à vendre votre mission. Utilisez une langue évocatrice et finissez par une question pour obtenir l\'intérêt de vos lecteurs ....',
  missionDurationSubCardText: 'Combien de temps voulez-vous que votre mission reste active pour accepter des propositions?',
  missionGoalsSubCardText: 'Les objectifs aident les utilisateurs à comprendre ce qui est et n\'est pas acceptable pour leurs soumissions.',
  missionGoalsSubCardDescriptionText: 'Vous pouvez aider à guider les propositions vers le succès en ajoutant des objectifs bien examinés.',
  proposalEscalationSubCardVideoDescription: 'Le clip court aide à expliquer l\'escalade dans le flux de travail de Swae',
  awardSubCardText:
    'Étant donné que le crowdsourcing exige que la participation des foules clarifiant vos objectifs à partir du début, tout le monde verra-t-il ce que le succès apparaît et fixe des attentes sur le processus de prise de décision.',
  inviteUsersSubCardText: 'Une proposition doit répondre aux métriques ci-dessous pour passer de «actif» à «sous revue».',
  inviteUsersDescription: 'Veuillez noter que vous pouvez utiliser certains ou aucun de ceux-ci ...',
  reviewStar: 'La revue*',
  review: 'La revue',
  typeYourTitleHere: 'Tapez votre titre ici', // Proposal creation page
  typeCustomTagsHere: 'Tapez Tags personnalisés ici',
  addTrendingTags: 'Ajouter des étiquettes de tendance',
  anonymous: 'Anonyme',
  remindMe: 'Rappelle moi',
  addImageOrVideoHere: 'Ajouter une image ou une vidéo ici',
  submit: 'Soumettre',
  mission: 'Mission',
  spellingGrammar: 'Orthographe et grammaire',
  readability: 'Lisibilité',
  readingTime: 'Temps de lecture',
  sentiment: 'Sentiment',
  emotion: 'Émotion',
  titleRecommendation: 'Titre recommandation',
  titleRecommendationQuestion: 'Souhaitez-vous que SWAE recommande un titre?',
  titleRecommendationFailedText: 'Impossible d\'obtenir la suggestion de titres, essayez à nouveau avec un autre texte.',
  replaceCurrentTitleText: 'Remplacer le titre actuel "{proposalTitle}" avec {suggestedTitle}',
  suggest: 'Suggérer',
  search: 'Chercher',
  viewLess: 'Voir moins',
  showAll: 'Afficher tout',
  decisionsHeaderDescription: 'Les missions sont l\'avenir de {clientname}. Ils sont le point de départ de ce que nous devons innover à l\'avenir ou trouver des solutions pour les problèmes qui nous retiennent.',
  activeMissions: 'Missions actives',
  archivedMissions: 'Missions archivées',
  deletedMissions: 'Missions supprimées',
  collaborators: 'Collaborateurs',
  creators: 'Créateurs',
  voters: 'Électeurs',
  hi: 'Salut {name}',
  heyFirstName: 'Salut {name}!',
  welcomeTo: 'Bienvenue à',
  editHereToAddDescription: 'Modifier ici pour ajouter description',
  getStarted: 'Commencer',
  missionUsers: 'Utilisateurs de mission',
  missionViews: 'Vues de mission',
  missionProposals: 'Propositions de mission',
  missionVotes: 'Vote mission',
  awardMissionInfoDescription: 'Les principales propositions de cette mission seront attribuées de grandes incitations.',
  milestonesMissionInfoDescription: 'Voici les principales mises à jour de cette mission',
  integration: 'L\'intégration',
  aboutHowItWorks: 'À propos de {teamName}',
  welcomeDialogHeading1: 'Hé {userFirstName}...',
  welcomeDialogHeading2: "Vous cherchez à as Swae? Nous avons ce qu'il vous faut",
  welcomeDialogContent1: "S'habituer à de nouveaux outils peut sembler être un grand changement, mais nous sommes là pour vous aider à faire votre petit premier pas.",
  welcomeDialogContent2: "Choisissez parmi notre bibliothèque de démos à la demande pour voir à quel point il est facile d'utiliser Swae",
  viewKnowledgebase: 'Afficher la base de connaissances',
  seeFaqs: 'Voir la FAQ',
  watchTutorials: 'Regarder les tutoriels',
  continueToSwae: 'Continuer vers Swae',
  polls: 'Les sondages',
  poll: 'Sondage',
  comment: 'Commenter',
  newPoll: 'Nouveau sondage',
  dialogContentTextPollVoter: 'Ajoutez l\'adresse e-mail ci-dessous pour inviter de nouveaux électeurs à ce sondage !',
  useTemplate: 'Utilise le modèle',
  dailyActiveUsers: 'Utilisateurs actifs quotidiens',
  aiGuidedBuilder: "Builder guidé de l'AI",
  diyProposalBuilder: 'Constructeur de la proposition de bricolage',
  weWillDoThisStepBySTep: 'Nous ferons ce pas par étape',
  justGiveMeABlankPage: 'Donnez-moi juste une page vierge',
  ifYouHaveGotAnIdeaAndWantSomeStructuredHelp: 'Si vous avez une idée et que vous voulez une aide structurée',
  bestIfYouHaveAlreadyThoughtYourProposalThrough: 'Mieux si vous avez déjà pensé à votre proposition',
  whatIsYourTitle: 'Quel est votre titre?',
  imSwaeAI: "Je suis Swae's Ai",
  imHereToHelpYouBuildYourProposal: 'Je suis ici pour vous aider à construire votre proposition',
  illMakeAFewSuggestionsAlongTheWay: 'Je ferai quelques suggestions en cours de route',
  helpEngagePeopleWithAGreatImageOrVideo: 'Aider à engager les gens avec une excellente image ou une vidéo',
  yourProposalWillBuildHere: 'Votre proposition sera construite ici',
  proposalMedia: 'Médias de proposition',
  adminSecurityOpenSwaeText: 'Tous les principaux contenus (missions, propositions, sondages, flux, etc.) sont visibles à toute personne sur Internet, mais les utilisateurs doivent être signés pour interagir avec eux (pour commenter, voter, modifier, etc.).',
  adminSecurityOpenSwaeTextInfoText: "Vous pouvez définir individuellement des missions, des sondages pour être «privés» afin qu'ils ne soient visibles que pour les utilisateurs invités.",
  makeMySwaePublic: 'Rendre ma Swae publique',
  visibility: 'Visibilité',
  oneClickSignOnOptions: 'Options de connexion à un clic',
  enableFeatures: 'Activer les fonctionnalités',
  enableOrDisableTheFollowingFeatures: 'Activer ou désactiver les fonctionnalités suivantes',
  whichSignInOptionDoYouWantToUse: 'Quelle option de connexion souhaitez-vous utiliser?',
  microsoftSignInDisclaimer: "Microsoft La connexion fonctionne uniquement avec Active Directory et non des comptes personnels. Pour configurer Microsoft, connectez-vous, connectez-vous à SWAE en tant qu'administrateur et suivez les instructions spécifiées dans les paramètres / sécurité.",
  checkTutorial: 'Vérifier le didacticiel',
  domainAllowedError: 'Saisissez l\'adresse e-mail du domaine',
  emailDomainError: 'Veuillez saisir une adresse e-mail de domaine valide',
  restrictSignupAccess: 'Restreindre l\'accès à l\'inscription',
  gotThisFarAndNotSeenAnyProposalYouLike: "Vous avez si loin et je n'ai vu aucune proposition que vous aimez?",
  youNeedToCreateYourOwn: 'Vous devez créer le vôtre ...',
  addUrl: 'Ajouter l\'URL',
  url: 'URL'
}
export default fr
