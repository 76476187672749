import { Auth } from 'aws-amplify'
import helper from './helper'

const signOut = async () => {
  try {
    await Auth.signOut({ global: true })
    return true
  } catch (e) {
    if (e.code === 'NotAuthorizedException') {
      await Auth.signOut()
      return true
    } else {
      throw e
    }
  }
}
const getCurrentAuthenticatedUser = async () => {
  try {
    const Data = await Auth.currentAuthenticatedUser({ bypassCache: true })
    return Data
  } catch (e) {
    const address = helper.metaMaskAddress()
    return address
      ? { address, email: address }
      : {}
  }
}

const getCurrentSession = async () => {
  try {
    return await Auth.currentSession()
  } catch (e) {
    const address = helper.metaMaskAddress()
    return address ? { address } : {}
  }
}

export default { getCurrentAuthenticatedUser, signOut, getCurrentSession }
