const es = {
  myFeed: 'Mi feeds',
  recentlyViewed: 'visto recientemente',
  feed: 'Alimentación',
  searchWhatInterestsYou: 'Busca lo que te interese',
  create: 'Crear',
  collaborate: 'Colaborar',
  selectAMission: 'Selecciona una misión',
  newMission: 'Nueva misión',
  myActivities: 'Mis actividades',
  howItWorks: 'Cómo funciona',
  following: 'Siguiente',
  viewAll: 'Ver todo',
  support: 'Apoyo',
  addMission: 'Agregar misión',
  insights: 'Perspectivas',
  siteAdminSettings: 'Configuración del administrador del sitio',
  settings: 'Ajustes',
  inviteAColleague: 'Invitar a un colega',
  suggestMission: 'Sugerir Misión',
  proposalReview: 'Revisión de la propuesta',
  users: 'Usuarios',
  branding: 'Marca',
  security: 'Seguridad',
  onboarding: 'Inducción',
  adminDashboard: 'Panel de administración',
  editHomepage: 'Editar página de inicio',
  billing: 'Facturación',
  notifications: 'Notificaciones',
  organizationName: 'nombre de la organización', // Branding Page
  accountUrl: 'URL de la cuenta',
  logo: 'Logo',
  type: 'Escribe',
  siteDesign: 'diseño del sitio',
  termsAndConditionDescription: 'Términos y condiciones Descripción',
  termsAndConditions: 'Términos y condiciones',
  externalLink: 'Enlace externo',
  editor: 'Editor',
  link: 'Enlace',
  jpgPng2MBmax: 'JPG, PNG, 2 MB máx.',
  webAddress: 'dirección web',
  totalUsers: 'Total de usuarios', // User Management Page
  pendingInvites: 'Invitaciones pendientes',
  missionCreator: 'Creador de misiones',
  pending: 'Pendiente',
  invite: 'Invitación',
  firstName: 'Primer nombre',
  surname: 'Apellido',
  status: 'Estado',
  memberSince: 'Miembro desde',
  lastSeenLocation: 'Ultima vez visto/ Ubicación',
  email: 'Correo electrónico',
  permissions: 'permisos',
  save: 'Ahorrar',
  searchForUser: 'Buscar usuario',
  inviteDescriptionText:
    'Introduzca un correo electrónico para invitar a un nuevo usuario. Después de cada correo electrónico, haga clic en la tecla Intro (o Retorno) de su teclado. Agregue tantos correos electrónicos como desee.',
  ByNameOrEmail: 'Por nombre o correo electrónico',
  resend: 'Reenviar',
  home: 'Casa',
  about: 'Acerca de',
  editAbout: 'Editar',
  sectionType: 'Tipo de sección',
  richText: 'Texto rico',
  updates: 'Actualizaciones',
  start: 'Comienzo',
  end: 'Fin',
  sponsors: 'Patrocinadores',
  decisions: 'Decisiones',
  escalation: 'Escalada',
  awards: 'recompensas',
  faQs: 'preguntas frecuentes',
  engagement: 'Compromiso',
  leaderboard: 'Tabla de clasificación',
  milestones: 'Hitos',
  missions: 'Misiones',
  comments: 'Comentarios',
  proposals: 'Propuestas',
  createNewProposal: 'Crear nueva propuesta',
  active: 'Activa',
  drafts: 'Borradores',
  draft: 'Sequía',
  underReview: 'Bajo revisión',
  archived: 'Archivado',
  declined: 'Rechazado',
  accepted: 'Aceptado',
  implemented: 'Implementado',
  edit: 'Editar',
  new: 'Nuevo',
  trending: 'Tendencias',
  buzzing: 'BuzZumbidoing',
  highlyRated: 'Altamente calificado',
  nearlyDecisionReady: 'Casi lista para tomar decisiones',
  needsSupport: 'Necesita apoyo',
  awarded: 'Galardonado',
  endingSoon: 'Terminando pronto',
  missionGoals: 'Objetivos de la misión',
  sponsorsAndStakeholders: 'Patrocinadores y partes interesadas',
  escalationMetrics: 'Métricas de escalamiento',
  missionMilestones: 'Hitos de la misión',
  missionGoalsDescriptionText: 'Al enviar propuestas, tenga en cuenta estos objetivos de la Misión. Apuntamos a',
  decisionsDescriptionText: 'Los siguientes líderes llevarán a cabo una revisión de todas las propuestas exitosas. Darán las razones del resultado, que puede ser uno de los siguientes',
  declinedDescription: 'Actualmente no es adecuado',
  acceptedDescription: 'se propondrá para su ejecución',
  implementedDescription: 'se actuará inmediatamente',
  awardsDescription: '',
  configureBranding: 'Configurar marca', // Onboarding Page
  configureQuestionnaire: 'Configurar cuestionario',
  configureWorkflow: 'Configurar flujo de trabajo',
  manageUsers: 'Administrar usuarios',
  view: 'Vista',
  bookACall: 'RESERVA UNA LLAMADA',
  introText: 'Hola yo soy',
  welcomeBack: 'Bienvenido de nuevo {name}', // Admin Dashboard page
  welcomeBackDescription: 'Su Swae está zumbando, ¡hay algunas cosas que hacer!',
  flaggedComments: 'Comentarios marcados',
  flaggedComment: 'Comentario marcado',
  item: 'Articulo',
  whoAndWhen: 'quien y cuando',
  metrics: 'Métrica',
  dueDate: 'Fecha de vencimiento',
  action: 'Acción',
  snapshot: 'Instantánea',
  activeProposals: 'Propuestas Activas',
  avgProposalVote: 'Promedio de votos de propuesta',
  loading: 'Cargando...',
  activity: 'Actividad',
  siteViews: 'Vistas del sitio',
  proposalStages: 'Etapas de la propuesta',
  getMoreInsights: 'Obtenga más información',
  ignored: 'Ignorado',
  deleted: 'Eliminado',
  missionLeader: 'líder de la misión',
  who: 'quién',
  intervene: 'Intervenir',
  reason: 'Razón',
  postedBy: 'Publicado por',
  reportedBy: 'Reportado por',
  deletedBy: 'Eliminado por',
  ignoredBy: 'Ignorado por',
  readLess: 'Leer menos',
  readMore: 'Lee mas',
  abidesByCodeOfConduct: 'Cumple con el código de conducta',
  allowComment: 'Permitir comentario',
  removeComment: 'Eliminar comentario',
  ignoreComment: 'Ignorar el comentario',
  createNewMission: 'Crear nueva misión',
  commentsViolateCommunityGuidelinesAndStandards: 'Los comentarios violan las pautas y estándares de la comunidad.',
  commentsAreInaccurateMisleadingOrNonFactual: 'Comments are inaccurate, misleading or non-factual',
  commentsUseProhibitedProfanity: 'Los comentarios usan blasfemias prohibidas',
  commentsIntendedToSlanderOtherCommunityMembers: 'Comentarios destinados a calumniar a otros miembros de la comunidad',
  customReasonOther: 'Motivo personalizado/Otro',
  reportedFor: 'Informado por',
  showMeMoreInsights: 'Muéstrame más ideas',
  reviewNow: 'Revisión ahora',
  oneMinTask: '1 minuto de tarea',
  activeUsers: 'Usuarios activos', // Insights page
  monthlyActiveUsers: 'Usuarios activas mensuales',
  averageOverPeriod: 'Promedio durante el período',
  changeOverPeriod: 'Cambio durante el período',
  inactiveUsers: 'Usuarios inactivos',
  totalViews: 'Vistas totales',
  totalEngagements: 'Compromisos totales',
  totalSuggestions: 'Sugerencias totales',
  totalSuggestedEdits: 'Total de ediciones sugeridas',
  totalComments: 'Comentarios totales',
  totalProposals: 'Propuestas totales',
  engagements: 'Compromisos',
  votes: 'Votos',
  languages: 'Idiomas',
  nationality: 'Nacionalidad',
  suggestedEdits: 'Ediciones sugeridas',
  engagementsSubtitle: 'La cantidad de Propuestas, Votos, Comentarios y Sugerencias',
  proposalsSubtitle: 'Dónde están las Propuestas en la plataforma',
  votesSubtitle: 'La cantidad de votos',
  commentsSubtitle: 'La cantidad de comentarios',
  suggestedEditsSubtitle: 'La cantidad de Sugerencias',
  languagesSubtitle: 'Qué idiomas usan las personas en la plataforma',
  nationalitySubtitle: 'De qué nación son los usuarios',
  usersSubtitle: 'Cuántas personas están usando Swae de {clientname}?',
  hallofFame: 'Salón de la Fama',
  filterBy: 'Filtrado por',
  overview: 'Descripción general',
  downloadXLSReport: 'Descargar el informe XLS',
  addYourMissionTitleHere: 'Añade el título de tu misión aquí', // Mission Creation
  missionIsContinuous: 'La misión es continua',
  questionnaire: 'Cuestionario',
  saveAsDraft: 'Guardar como borrador',
  launchMission: 'Misión de lanzamiento',
  updateMission: 'Misión de actualización',
  goals: 'Metas',
  enableGoals: 'Habilitar los objetivos',
  goalsDescription: 'Al enviar propuestas para esta misión, por favor, trabaje dentro de estos objetivos ...',
  addGoalsHeading: 'Añadir metas a tu misión',
  addCustomGoals: 'Añadir metas personalizadas',
  decisionHeaderForMissionCreation: 'Permitir que las propuestas aumenten a las decisiones.',
  decisionMakersQuestion: '¿Quiénes son los tomadores de decisiones?',
  searchLeadership: 'Liderazgo de búsqueda',
  howDoProposalsEscalate: '¿Cómo se intensifican las propuestas?',
  proposalReviewProcessAndMetrics: 'Proceso de revisión de la propuesta y métricas.',
  reviewProcess: 'Proceso de revisión',
  reviewProcessStar: 'Proceso de revisión*',
  manuallyEscalatedByLeadership: 'Escalado manualmente por el liderazgo',
  sendProposalsToReviewAutomatically: 'Enviar propuestas para revisar automáticamente, según las métricas.',
  escalatedUsingMetrics: 'Escalado utilizando métricas',
  escalationCriteria: 'Criterios de escalada',
  escalationCriteriaDescription: 'Las propuestas que cumplen con estos criterios se enviarán para su revisión a los tomadores de decisiones.',
  proposalsEscalationMetrics: 'Propuestas escalation métricas',
  maximumLengthProposalCanBeActive: 'La propuesta de longitud máxima puede ser activa.',
  howLongCanProposalStayActive: '¿Cuánto tiempo puede mantener la propuesta activa?',
  minimumAverageScoreFromVotes: 'Puntuación media mínima de los votos',
  minimumNumberOfVoters: 'Número mínimo de votantes',
  minimumNumberOfCollaborativeEdits: 'Número mínimo de ediciones colaborativas.',
  minimumNumberOfStrengths: 'Número mínimo de fortalezas',
  minimumNumberOfConcerns: 'Número mínimo de preocupaciones',
  minimumNumberOfQuestions: 'Número mínimo de preguntas',
  minimumNumberOfProposalViews: 'Número mínimo de opiniones de propuesta.',
  awardsHeaderDescription: 'Para supercarte tus ideas, estamos dando algunos premios grandes en esta misión ...',
  addYourCustomAwardTitle: 'Añade tu título de premio personalizado',
  addYourCustomAwardDescription: 'Añada más detalles sobre su premio personalizado aquí',
  orUploadYourOwn: 'o subir tu propio',
  awardIcon: 'Icono de premio',
  awardType: 'Tipo de premio',
  awardGoesToPerson: 'El premio va a la persona',
  awardGoesToProposal: 'El premio va a propuesta.',
  addAward: 'Adjudicar',
  addAnotherAward: 'Añadir otro premio',
  awardGoesTo: 'El premio va a',
  to: 'A',
  awardName: 'Nombre de premio',
  assignAwards: 'Asignar premios',
  addFaqsHere: 'Agregue Preguntas frecuentes aquí',
  addYourQuestionHere: 'Añade tu pregunta aquí',
  addYourAnswerHere: 'Añade tu respuesta aquí',
  reorder: 'Reordenar',
  delete: 'Borrar',
  addAnyMissionMilestones: 'Agregue cualquier hito de la misión',
  addMissionMilestonesDescription: 'Aquí están las principales actualizaciones de esta misión.',
  addMilestoneHere: 'Añadir hito aquí',
  addUpdateHere: 'Agregar actualización aquí',
  addUpdateHereStar: 'Agregar actualización aquí*',
  addYourDetailHere: 'Añade tu detalle aquí',
  imagesJpgPngGif: 'Imágenes: JPG, PNG, GIF',
  videoMpegMov: 'Video: MPEG, MOV',
  maxFilesize20MbMaxFilesize: '20MB Max Filessize',
  // Wizard Builder
  bestForSimpleMissions: 'Mejor para misiones simples',
  createReacherFullFeaturedMissions: 'Crear un llegado, misiones con todas las funciones.',
  around3Minutes: 'Alrededor de 3 minutos',
  whatOutcomeDoYouWant: '¿Qué resultado quieres ...',
  outcomeAnswer:
    'SWAE se puede usar simplemente para hacer una lluvia de ideas o puede brindarle un flujo de trabajo de una parada de ideas a un proceso completo de toma de decisiones. Con la toma de decisiones habilitadas, es probable que los usuarios estén más comprometidos, sabiendo que sus ideas podrían ser accionadas.',
  around5Minutes: 'Alrededor de 5 minutos',
  around4Minutes30Seconds: 'Alrededor de 4 minutos 30 segundos',
  optional: 'Opcional',
  recommended: 'Recomendado',
  letsSetUpYourMission: 'Vamos a configurar su misión',
  collectIdeasMakeDecisions: 'Recoge ideas y tome decisiones.',
  justCollectIdeas: 'Solo recolecta ideas',
  back: 'atrás',
  next: 'próximo',
  cancel: 'Cancelar',
  required: 'Requerido',
  titleAndDescription: 'Descripción del Título*',
  addMedia: 'Agregar medios',
  missionDuration: 'Duración de la misión *',
  decisionMakers: 'Tomadores de decisiones',
  proposalEscalation: 'Propuesta de escalada *',
  addFaQs: 'Añadir Preguntas frecuentes',
  inviteUsers: 'Invitar a los usuarios',
  customiseQuestions: 'Personalizar preguntas',
  missionTitle: 'Título de la Misión *',
  whatDoYouWantToSolve: '¿Qué quieres resolver?',
  description: 'Descripción',
  addMoreDetailsHere: 'Añadir más detas aquí ..',
  missionMedia: 'Medios de comunicación',
  addFromLibrary: 'Añadir de biblioteca',
  poweredBy: 'Energizado por',
  findAnImage: 'Encontrar una imagen',
  orAddYourOwn: '... o agregue su propio ...',
  startAtSpecificTime: 'Empezar en tiempo específico',
  startRightAway: 'Comienza de inmediato',
  missionHasAClosingDate: 'La misión tiene una fecha de cierre',
  proposalsEscalation: 'Propuestas escalada',
  howAreProposalsEscalated: '¿Cómo se intensifican las propuestas?',
  escalatedManuallyByLeaders: 'Escalado manualmente por los líderes',
  whatHappensOnEscalation: '¿Qué pasa en la escalada?',
  bothMethods: 'Ambos métodos',
  proposalGoesToReview: 'La propuesta va a revisión.',
  proposalIsAutomaticallyAccepted: 'Se acepta automáticamente la propuesta.',
  whatAreYourGoalsForThisMission: '¿Cuáles son tus metas para esta misión?',
  generateAHighQuantityOfProposals: 'Generar una alta cantidad de propuestas.',
  idPreferFewerHigherQualityProposals: 'Preferiría menos, propuestas de mayor calidad.',
  iWantALotOfDiscussionOnProposals: 'Quiero mucha discusión sobre las propuestas.',
  illCustomiseTheMetricsMyself: 'Personalizaré las métricas yo mismo',
  addAnotherFaq: 'Añadir Otros Preguntas frecuentes',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha final',
  access: 'Acceso',
  missionIsOpenToThePublic: 'La misión está abierta al público.',
  onlySelectedUsers: 'Solo usuarios seleccionados',
  searchUsers: 'Buscar usuarios',
  inviteByEmail: 'Invitar por correo electrónico',
  searchForPeopleOrTeams: 'Búsqueda de personas o equipos.',
  customiseProposalBuilder: 'Personalizar constructor de propuestas',
  defaultQuestionsAddCustomOnes: 'Preguntas por defecto + Agregar a su encargo',
  usingDefaultQuestions: 'Usando las preguntas predeterminadas',
  around30Seconds: 'Alrededor de 30 segundos',
  launchYourMissionRightAway: '¡Lanza tu misión de inmediato!',
  missionTitleSubCardText: 'Los títulos cortos y punzantes ayudan a vender su misión. Use el lenguaje evocador y termine con una pregunta para obtener el interés de sus lectores ...',
  missionDurationSubCardText: '¿Cuánto tiempo quiere que su misión permanezca activa para aceptar propuestas?',
  missionGoalsSubCardText: 'Los objetivos ayudan a los usuarios a comprender qué es y no es aceptable para sus presentaciones.',
  missionGoalsSubCardDescriptionText: 'Puede ayudar a guiar propuestas hacia el éxito agregando objetivos bien considerados.',
  proposalEscalationSubCardVideoDescription: 'El clip corto ayuda a explicar la escalada en el flujo de trabajo de SWAE.',
  awardSubCardText:
    'Dado que Crowdsourcing requiere la participación de las multitudes que aclaran sus objetivos del inicio, todos vendrán lo que el éxito analiza y establece las expectativas sobre el proceso de toma de decisiones.',
  inviteUsersSubCardText: 'Una propuesta debe cumplir con las siguientes métricas para pasar de "activo" a "bajo revisión".',
  inviteUsersDescription: 'Tenga en cuenta que puede usar algunos, o ninguno de estos ...',
  reviewStar: 'Revisar*',
  review: 'Revisar',
  typeYourTitleHere: 'Escriba su título aquí', // Proposal creation page
  typeCustomTagsHere: 'Escriba etiquetas personalizadas aquí',
  addTrendingTags: 'Añadir etiquetas de tendencia',
  anonymous: 'Anónimo',
  remindMe: 'Recuerdame',
  addImageOrVideoHere: 'Añadir imagen o video aquí',
  submit: 'Entregar',
  mission: 'Misión',
  spellingGrammar: 'Ortografía y gramática',
  readability: 'Legibilidad',
  readingTime: 'Tiempo de leer',
  sentiment: 'Sentimiento',
  emotion: 'Emoción',
  titleRecommendation: 'Recomendación de título',
  titleRecommendationQuestion: 'Te gustaría que Swae recomiende un título?',
  titleRecommendationFailedText: 'Error al obtener sugerencias de título, intente nuevamente con un poco más de texto.',
  replaceCurrentTitleText: 'Reemplace el título actual "{proposalTitle}" con {suggestedTitle}',
  suggest: 'Sugerir',
  search: 'Búsqueda',
  viewLess: 'Ver menos',
  showAll: 'Mostrar todo',
  decisionsHeaderDescription:
    'Las misiones son el futuro de {clientname}. Son el punto de partida para lo que necesitamos innovar el futuro, o encontrar soluciones para los problemas que nos están contando.',
  activeMissions: 'Misiones activas',
  archivedMissions: 'Misiones archivadas',
  deletedMissions: 'Misiones eliminadas',
  collaborators: 'Colaboradores',
  creators: 'Creadores',
  voters: 'Votantes',
  hi: 'Hola {name}',
  heyFirstName: 'Oye {name}!',
  welcomeTo: 'Bienvenido a',
  editHereToAddDescription: 'Editar aquí para agregar descripción',
  getStarted: 'Empezar',
  missionUsers: 'Usuarios de la misión',
  missionViews: 'Vistas de la misión',
  missionProposals: 'Propuestas de misión',
  missionVotes: 'Misionero',
  awardMissionInfoDescription: 'Las mejores propuestas en esta misión se otorgarán algunos grandes incentivos.',
  milestonesMissionInfoDescription: 'Aquí están las principales actualizaciones de esta misión.',
  integration: 'Integración',
  aboutHowItWorks: 'Acerca de {teamName}',
  welcomeDialogHeading1: 'Oye {userFirstName}...',
  welcomeDialogHeading2: 'Buscando a Ace Swae? Te tenemos cubierto',
  welcomeDialogContent1: 'Acostumbrarse a las nuevas herramientas puede parecer un gran cambio, pero estamos aquí para ayudarlo a dar su pequeño primer paso.',
  welcomeDialogContent2: 'Elija de nuestra biblioteca de demostraciones a pedido para ver lo fácil que es usar Swae',
  viewKnowledgebase: 'Ver base de conocimientos',
  seeFaqs: 'Ver preguntas frecuentes',
  watchTutorials: 'Ver tutoriales',
  continueToSwae: 'Continuar a Swae',
  polls: 'Centro',
  poll: 'Encuesta',
  comment: 'Comentario',
  newPoll: 'Nueva encuesta',
  dialogContentTextPollVoter: '¡Agregue la dirección de correo electrónico a continuación para invitar a un nuevo votante a esta encuesta!',
  useTemplate: 'Usar plantilla',
  dailyActiveUsers: 'Usuarios activos diarios',
  aiGuidedBuilder: 'AI Guiado Builder',
  diyProposalBuilder: 'Creador de propuestas de bricolaje',
  weWillDoThisStepBySTep: 'Haremos esto paso a paso',
  justGiveMeABlankPage: 'Solo dame una página en blanco',
  ifYouHaveGotAnIdeaAndWantSomeStructuredHelp: 'Si tienes una idea y quieres ayuda estructurada',
  bestIfYouHaveAlreadyThoughtYourProposalThrough: 'Mejor si ya has pensado tu propuesta a través de',
  whatIsYourTitle: '¿Cuál es tu título?',
  imSwaeAI: "Soy swae's ai",
  imHereToHelpYouBuildYourProposal: 'Estoy aquí para ayudarte a construir tu propuesta',
  illMakeAFewSuggestionsAlongTheWay: 'Haré algunas sugerencias en el camino',
  helpEngagePeopleWithAGreatImageOrVideo: 'Ayudar a involucrar a las personas con una excelente imagen o video',
  yourProposalWillBuildHere: 'Su propuesta se construirá aquí',
  proposalMedia: 'Propuesta de medios',
  adminSecurityOpenSwaeText: 'Todo el contenido principal (misiones, propuestas, encuestas, feed, etc.) se puede ver para cualquier persona en Internet, pero los usuarios deben iniciarse para interactuar con ellas (para comentar, votar, editar, etc.).',
  adminSecurityOpenSwaeTextInfoText: "Puede establecer misiones individuales, las encuestas para ser 'privadas' para que solo sean visibles para los usuarios invitados.",
  makeMySwaePublic: 'Haz mi swae pública',
  visibility: 'Visibilidad',
  oneClickSignOnOptions: 'Opciones de inicio de sesión de un clic',
  enableFeatures: 'Habilitar características',
  enableOrDisableTheFollowingFeatures: 'Habilitar o deshabilitar las siguientes funciones',
  whichSignInOptionDoYouWantToUse: '¿Qué opción de inicio de sesión desea usar?',
  microsoftSignInDisclaimer: 'Microsoft Semill In solo funciona con Active Directory y no cuentas personales. Para configurar el inicio de sesión de Microsoft, inicie sesión en Swae como administrador y siga las instrucciones especificadas en Configuración/seguridad.',
  checkTutorial: 'Ver tutorial',
  domainAllowedError: 'Ingrese la dirección de correo electrónico del dominio',
  emailDomainError: 'Ingrese una dirección de correo electrónico de dominio válida',
  restrictSignupAccess: 'Restringir el acceso de registro',
  gotThisFarAndNotSeenAnyProposalYouLike: '¿Tienes tan lejos y no he visto ninguna propuesta que te guste?',
  youNeedToCreateYourOwn: 'Necesitas crear el tuyo ...',
  addUrl: 'Agregar URL',
  url: 'URL'
}

export default es
