const fr = {
  voteTitle: 'Votez sur des idées',
  noProposalMessage: 'Aucune Idée!',
  successDialogContentText: 'Votre Idée a été soumise à l\'examen de l\'IA augmentée par l\'homme. Un analyste humain examinera le travail de notre IA avant de vous renvoyer votre Idée, pour garantir la qualité et ajouter quelques touches personnelles. Ce processus prend normalement jusqu\'à 24 heures.',
  introductionDescription: 'certaines questions sont automatiquement incluses: titre de la Idée, résumé, photos et vidéos',
  proposal: 'Idée',
  setEscalationCriteriaDescription: 'Choisissez des critères qui dégénèrent une Idée',
  totalNumberOfProposalViews: 'Nombre minimum de vues de Idée',
  proposalNumbersTitle: 'Numéros de Idée',
  propositionNumériquesTitre: 'Numéros de Idée',
  proposalHeaderTitle: 'Paramètres de notification par e-mail de la Idée',
  propositionHeaderTitle: 'Paramètres de notification par e-mail de Idée',
  propositionWorkflowTitle: 'Idée Workflow',
  collabDesc: 'Trouvez de nouvelles idées et collaborez',
  newProposal: 'Nouvelle Idée',
  noProposalsMessage: 'No Idées!',
  startANewProposal: 'Démarrer une nouvelle Idée',
  newProposalTitle: 'Nouvelle Idée',
  proposalReviewTitle: 'Étude de la Idée',
  searchProposal: 'Rechercher une Idée',
  inappropriateContentText: 'Cette Idée a été supprimée pour des termes disciplinaires ou inappropriés',
  voteSubText: "Veuillez voter pour faire entendre votre voix sur l'opportunité de donner suite à cette Idée",
  propositionReviewText: 'Examen de la Idée',
  votingSubText: 'Veuillez voter pour faire entendre votre voix sur l\'opportunité de donner suite à cette Idée.',
  yourProposalText: 'Votre Idée',
  editYourProposalText: 'Modifier votre Idée',
  createCollaborateMessageText: 'Vos collègues peuvent désormais collaborer sur votre Idée et vérifier l\'état dans ',
  myProposalsTitle: 'Mes Idée',
  managementProposalsTitle: 'Gestions Des idées'
}
export default fr
