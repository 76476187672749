/**
 *
 * No Item
 *
 */

import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    color: theme.palette.primary.text
  }
}))

function Loading () {
  const isOpenPoll = window && window.location && window.location.pathname && window.location.pathname.split('/') && window.location.pathname.split('/').length ? window.location.pathname.split('/')[1] === 'poll' : undefined
  const classes = useStyles()
  return (
    <Container className={classes.container}>
      <h4 className={`${isOpenPoll ? 'font-family-bold-24' : 'font-family-bold-16'}`}>
        <FormattedMessage id="loading" />
      </h4>
    </Container>
  )
}

Loading.propTypes = {}
export default memo(Loading)
