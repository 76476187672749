export default {
  createProposal: '/create',
  createProposalByBuilder: '/create/proposal/builderType',
  createTeam: '/team/{teamId}',
  getAccessChallenges: '/get/access/challenges/{state}/{from}/{pgsize}/{search}',
  action: '/action/{actionName}',
  getLeaderBoard: '/get/leaderboard/{type}/{teamId}/{challengeId}',
  getCount: '/get/count/{type}/{state}/{challengeIds}/{isown}',
  getProposals: '/getProposals/{state}/{sort}/{category}/{from}/{pgsize}/{isown}/{search}/{challengeId}/{challengeIds}/{bookMarkIds}',
  getProposalsWithStatus: '/getProposalsWithStatus/{state}/{sort}/{category}/{from}/{pgsize}/{isown}/{search}/{challengeId}/{challengeIds}/{bookMarkIds}/{status}',
  getSampleProposals: '/getProposals/{state}/{sort}/{category}/{from}/{pgsize}/{isown}/{search}/{challengeId}/{challengeIds}/{bookMarkIds}/{sampleProposal}/{status}',
  getIntervalProposals: '/getProposals/{state}/{sort}/{category}/{from}/{pgsize}/{isown}/{search}/{challengeId}/{challengeIds}/{bookMarkIds}/{sampleProposal}/{startDate}/{endDate}/{isPagination}',
  updateProposalState: '/proposal/state/{documentId}/{preState}/{postState}/{teamId}',
  updateVolunteer: '/volunteer/{documentId}/{teamId}',
  getTeams: '/teams/teams/{from}/{pgsize}/{search}/{filterBy}',
  getVolunteers: '/volunteers/{documentId}/{teamId}/{pgsize}',
  getUsers: '/users/{filterBy}/{from}/{pgsize}/{search}/{sort}/{challengeId}',
  getUserList: '/user/list/{filterBy}/{from}/{pgsize}/{search}/{sort}/{challengeId}',
  getPendingUsers: '/pendingUsers/{from}/{pgsize}/{sort}',
  deleteProposal: '/proposal/delete/{documentId}/{teamId}',
  createUserSession: '/session/create/{teamId}',
  deleteUserSession: '/session/delete/{teamId}',
  getComments: '/comments/{documentId}/{emotion}',
  comment: '/comment',
  sendEmail: '/sendEmail',
  getChallengesFaqs: '/challenges/faqs/{teamId}/{lang}/{challengeId}',
  updateDocument: '/update/document',
  getReplies: '/replies/{documentId}/{parentCommentId}',
  commentEndorse: '/comment/endorse/{documentId}/{commentId}',
  getReports: '/get/reports/{teamId}/{challengeId}',
  getActiveUserReports: '/get/active/user/reports/{teamId}/{type}/{startDate}/{endDate}',
  getGraphReports: '/get/graph/reports/{teamId}/{challengeId}/{type}/{startdate}/{endDate}',
  getPeriodicReports: '/get/periodic/reports/{teamId}/{challengeId}/{type}/{startdate}/{endDate}',
  getMyChallenges: '/get/my/state/challenges/{teamId}/{state}',
  getAwards: '/awards/{teamId}',
  getChallengeAwards: '/get/challenge/awards/{teamId}/{challengeId}',
  getChallengeSponsors: '/get/challenge/sponsors/{teamId}/{challengeId}',
  getChallengeDecisionUsers: '/get/challenge/decision/users/{teamId}/{challengeId}',
  getChallengeDecisionUsersPageSize: '/get/challenge/decision/users/pgsize/{teamId}/{challengeId}/{pgsize}',
  getAssignAwards: '/awards/assign/{teamId}/{userName}/{challengeId}',
  updateAwards: '/awards/{teamId}/{userName}',
  getCustomReports: '/reports/{teamId}/{challengeId}/{startDate}/{endDate}',
  updateReports: '/update/reports/{teamId}/{challengeId}',
  getPreSignedUrl: '/getPreSignedUrl/{object}/{thumbnailUrl}/{fileType}',
  getPreSignedUrlUnauthorized: '/getPreSignedUrl/{object}/{thumbnailUrl}/{fileType}/{teamId}',
  updateTeam: '/team/team/{teamId}/{lang}/{challengeId}',
  challengeAction: '/challenges/challenge/{action}/{challengeId}',
  getUserChallenge: '/challenges/user/challenges',
  getUserChallengesReport: '/challenges/user/challengesReport',
  getCont: '/get/count/{type}/{state}/{challengeIds}',
  getChallengesSearch: '/get/challenges/search/{state}/{from}/{pgsize}/{search}/{challengeIds}',
  getChallenge: '/get/challenges/challenge/{teamId}/{challengeId}',
  getChallengeReport: '/get/challenges/challenge/report/{teamId}/{challengeId}',
  updateChallenge: '/challenges/update/challenge/{teamId}/{challengeId}',
  updateChallengeUser: '/update/challenge/user/{filterBy}/{challengeId}',
  getChallengeUsers: '/challenge/users/{pgsize}/{challengeId}',
  updateUsers: '/update/users/{teamId}/{challengeId}',
  getBranding: '/teams/branding/{teamId}/{lang}/{challengeId}',
  getQuestionnaire: '/getQuestionnaire/{lang}/{challengeId}',
  updateQuestionnaire: '/updateQuestionnaire/{version}/{lang}/{challengeId}',
  updateQuestionnaireNew: '/updateQuestionnaireNew/{type}/{version}/{lang}/{challengeId}',
  updateCommentFlag: '/comments/flagged/{commentId}',
  updateInlineComment: '/inline-comment',
  getInlineComments: '/inline-comments/{proposalId}/{threadId}/{attributes}',
  updateInlineSuggestion: '/inline-suggestion',
  getInlineSuggestion: '/inline-suggestion/{proposalId}/{id}',
  updateViewCount: '/views/{state}/{proposalId}',
  updateSiteView: '/site/view/{teamId}/{challengeId}/{type}',
  getProposal: '/proposals/{state}/{proposalId}/{teamId}',
  vote: '/vote',
  isVoted: '/vote/{documentId}',
  getProposalNotificationSettings: '/proposal/notification/settings/{documentId}/{type}',
  updateProposalNotificationSettings: '/proposal/notification/settings/{documentId}/{type}',
  getUser: '/user/{teamId}',
  getUserByUserName: '/user/by/username/{teamId}/{userName}',
  getUnauthorizedUser: '/unauthorized/user/{teamId}/{userName}',
  updateUser: '/user/{teamId}',
  deleteUser: '/delete/user/{userId}',
  getProposalState: '/proposals/state/{proposalId}',
  getFlaggedComments: '/comments/flagged-comments/{pgsize}/{challengeId}',
  deleteFlaggedComments: '/manager/delete-flagged-comments/{documentId}/{commentId}',
  getCollaborators: '/collaborators/{proposalId}',
  updateVirtualTour: '/update/virtualTour/{state}',
  getVirtualTour: '/get/virtualTour',
  getVirtualTourTemplate: '/get/virtualTourTemplate',
  createVirtualTour: '/create/virtualTour',
  partialAIRatingFeedback: '/partialAIRatingFeedback',
  quickAI: '/test/article',
  summarizerQuickAI: '/summarizer/article',
  grammarCheckQuickAI: '/grammarcheck/article',
  htmlGrammarCheckQuickAI: '/htmlgrammarcheck/article',
  readAbilityQuickAI: '/readAbility/article',
  translatorQuickAI: '/translate/article',
  getCurrentUserVotedList: '/vote/votedProposalList',
  updateProposal: '/proposal/update/{object}/{documentId}',
  getChallenges: '/get/challenges/{teamId}',
  getAISuggestions: '/api/?text=example',
  refreshEmailTemplates: '/refresh-email-templates',
  getNotifications: '/getNotifications',
  updateNotification: '/updateNotification',
  getVersionHistory: '/getVersionHistory/{documentId}',
  uploadCKEditorImage: '/uploadCKEditorImage/{teamId}',
  getMissionsByState: '/action/getMissionsByState',
  getCommentsByUserName: '/action/getCommentsByUserName',
  getRandomUnsplashImage: '/photos/random',
  searchUnsplashImage: '/search/photos',
  getCategories: '/action/getGlobalQuestionCategories',
  getCategoriesQuestion: '/action/getGlobalQuestions',
  titleSuggestion: '/titleSuggestion/article',
  createContactInActiveCampaign: '/api/3/contact/sync',
  addReactionToComment: '/action/addReactionToComment',
  getCommentReactedUserList: '/action/getCommentReactedUserList',
  slackApps: '/apps',
  getSlackAuthAccessToken: '/api/oauth.v2.access',
  slackAuthorize: '/oauth/v2/authorize',
  deactivateSlackApp: '/api/apps.uninstall',
  paraphrasingAI: '/paraphrasing/article',
  graphQL: '/graphql',
  snapshotProposalUrl: '/#/{spaceId}/proposal/{proposalId}',
  getStripeProduct: '/v1/products/{productId}',
  getStripePrice: '/v1/prices/{priceId}'
}
