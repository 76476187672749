const en = {
  title: 'Title',
  descriptionChallengePlaceholder: 'Please save any changes you have made before you leave this page',
  answerPlaceholder: 'Please enter answer here',
  answerTitle: 'Answer',
  addInfo: 'Add Info',
  addFAQs: 'Add FAQs',
  addFAQ: 'Add FAQ',
  createChallengeTitle: 'New Mission',
  updateChallengeTitle: 'Update Mission',
  challengeMyTitle: 'My Missions',
  reviewChallengeTitle: 'Review',
  regularUserTitle: 'Regular User',
  expertTitle: 'Expert',
  decisionMakerTitle: 'Decision Maker',
  challengeWorkflowTitle: 'Mission Workflow',
  challengeExpiryTitle: 'Mission Expiry',
  logInTitle: 'Log In',
  errorPleaseFillOutThisField: 'Please fill out this field',
  signUpTitle: 'Sign Up',
  forgotPasswordHeader: 'Forgot password?',
  verificationCodeHeader: 'Check your email',
  forgotPasswordDescription: 'Enter your email here. We\'ll send you a verification code.',
  verificationCodeDescription: 'We\'ve emailed you a verification code. If you can\'t find it, please check your spam folder!',
  yourFirstNameTitle: 'Your First Name',
  yourLastNameTitle: 'Your Last Name',
  emailTitle: 'Email',
  enterEmailTitle: 'Enter Email and click on "Enter" key on the keyboard to add',
  inviteUsersHeaderTitle: 'Please enter an email to invite a new user. After each email, click the Enter(or Return) key on your keyboard. Please add as many emails as you wish.',
  passwordTitle: 'Password',
  createPassword: 'Create Password',
  confirmPasswordTitle: 'Confirm Password',
  otpTitle: 'Enter your 6 digit code',
  confirmationCode: 'Verification code',
  newPasswordTitle: 'New Password',
  termsConditionsText: "I accept Swae's",
  termsConditionsLink: 'Terms and Conditions',
  forgotPasswordLink: 'Forgot password?',
  tryAgainLink: 'Didn\'t get a code?',
  securityLink: 'Security',
  startTitle: 'Start',
  endTitle: 'End',
  dateTitle: 'Date',
  helpLink: 'Help',
  firstNameError: 'Enter your first name.',
  lastNameError: 'Enter your last name.',
  emailError: 'Enter an email.',
  genderError: 'Select gender.',
  ageError: 'Select age.',
  sectorError: 'Select sector.',
  underRepresentedGroupsError: 'Select under-represented groups.',
  locationError: 'Select location.',
  countryNationalityError: 'Select nationality.',
  organisationError: 'Enter organisation.',
  emailValidationError: 'Please enter a valid email address.',
  otpError: 'Enter a verification code.',
  passwordError: 'Enter a password.',
  passwordValidationError: 'Use 8 or more characters with a mix of lower-case and upper-case letters & numbers.',
  confirmPasswordValidationError: 'Password did not match.',
  confirmPasswordError: 'Retype the password.',
  logInButton: 'Continue',
  oktaLogInButton: 'Login with Okta',
  signUpButton: 'Continue',
  resetPasswordButton: 'Reset password',
  verifyButton: 'Verify',
  VerificationLink: 'Need verification code again?',
  continueButton: 'Continue',
  commentsTitle: 'Debate',
  commentsDescription: 'Please add your constructive feedback.',
  get commentTopConsTitle () {
    return `${this.weakness}es`
  },
  commentTopNeutralTitle: 'Questions',
  get commentTopProsTitle () {
    return `${this.strength}s`
  },
  noCommentMessage: 'No Feedback',
  commentsClosedTitle: 'Debate [closed]',
  commentsClosedDescription: 'These feedback are from the Collaboration and Voting phase.',
  postCommentDialogTitle: 'Add Feedback',
  get postAgainstCommentButtonText () {
    return `Post ${this.weakness}`
  },
  postNeutralCommentButtonText: 'Post Question',
  get postProCommentButtonText () {
    return `Post ${this.strength}`
  },
  maxLimit: 'Maximum character limit is 125',
  noProposalMessage: 'No Proposal!',
  myOrganizationsDetails: 'My organization\'s details',
  customFaqsTitle: 'FAQs',
  startDateEndDateWorkFlow: 'Please select date and time greater than start date',
  expiryDateEndDateWorkFlow: 'Please select date and time lesser than expiry date',
  expiryDateChallenge: 'Please select date and time greater than review end date',
  activeTitle: 'Active',
  expiredTitle: 'Expired',
  flaggedBy: 'Flagged by',
  commentText: 'Feedback',
  viewText: 'View',
  deletedText: 'Deleted',
  deleteText: 'Delete',
  allowedText: 'Allowed',
  allowText: 'Allow',
  commentor: 'Commentor',
  flaggedCommentsSubTitle: 'Please review these feedback flagged by users.',
  customLinksTitle: 'Custom Links',
  addAnotherAwardTitle: 'Add another award',
  addAnotherFaqTitle: 'Add another FAQ',
  addAnotherLinkTitle: 'Add another link',
  themeTitle: 'Theme',
  customAwardsTitle: 'Awards',
  descriptionTitle: 'Choose custom awards in additions to Top Rated, Biggest Influencer, Highest Engagement, Top Collaborator',
  cancelButton: 'Cancel',
  saveButton: 'Save',
  companyName: 'Company Name',
  logoTitle: 'Logo',
  videoExplainerTitle: 'Branding Video',
  dragAndDropLogoHere: 'Drag and drop an image here',
  dragAndDropVideoHere: 'Drag and drop video here',
  or: 'or',
  imageSize: '150 x 100 px',
  browseFiles: 'Browse files',
  termsAndConditionsLinkName: 'Terms and Conditions Link Name',
  termsAndConditionsLink: 'Terms and Conditions Link',
  welcomeVideoTitle: 'Welcome Video',
  welcomeVideoDescription: 'Welcome Video Description',
  questionnaireTitle: 'Questionnaire',
  introductionTitle: 'Introduction',
  introductionText: 'Pre-Requisites',
  introductionDescription: 'some questions are automatically included: Proposal Title, Summary, Photos & Videos',
  questionTitle: 'Question',
  sectionTitle: 'Section Name',
  ifAnswerIsTitle: 'If Answer Is',
  jumpToSectionTitle: 'Jump to Section',
  requiredText: 'Required',
  sectionPlaceholder: 'Enter a section name.',
  questionTitlePlaceholder: 'Enter a question title.',
  sectionError: 'Enter a section name.',
  questionError: 'Enter a question.',
  placeHolderError: 'Enter a placeholder text.',
  optionError: 'Enter an option name.',
  addSectionTitle: 'Add Section',
  addQuestionTitle: 'Add Question',
  addOptionTitle: 'Add Option',
  deleteSection: 'Delete Section',
  deleteButton: 'Delete',
  copy: 'Copy',
  move: 'Move',
  createFilter: 'Create filter',
  logicJumpsTitle: 'Add Logic Jump',
  maxChoices: 'Max Choices',
  date: 'Date',
  time: 'Time',
  headerDescription: 'Customise your team\'s workflow to serve your organizations needs better',
  aiReviewWorkflow: 'AI Review',
  collaborationWorkflow: 'Collaboration',
  votingWorkflow: 'Voting',
  managementReviewWorkflow: 'Manager Review',
  daysMaxWorkflow: 'Days max',
  proposal: 'Proposal',
  viewButton: 'View',
  workflowStage: 'Workflow Stage',
  stageType: 'Stage type',
  customWorkflowDescription: 'Custom workflow description',
  durationTitle: 'Duration',
  permissionsTitle: 'Permissions',
  setEscalationCriteriaTitle: 'Set Escalation Criteria',
  setEscalationCriteriaDescription: 'Choose criteria that escalates a proposal',
  averageScore: 'Average Score',
  minimumNumberOfVotes: 'Minimum number of votes',
  totalNumberOfComments: 'Minimum number of feedback',
  get totalNumberOfProComments () {
    return `Minimum number of ${this.strength} feedback`
  },
  get totalNumberOfConComments () {
    return `Minimum number of ${this.weakness} feedback`
  },
  totalNumberOfProposalViews: 'Minimum number of Proposal Views',
  totalNumberOfVolunteers: 'Minimum number of volunteers',
  inviteMgrHeaderTitle: 'Invite Manager',
  shareButtonText: 'Share',
  inviteButtonText: 'Invite',
  inviteUsersButtonText: 'Invite Users',
  noMoreInviteMessage: 'Yay! You have seen it all',
  noMoreFlaggedCommentsMessage: 'Yay! You have seen it all',
  noInviteMessage: 'No Invite!',
  addButtonText: 'Add',
  removeButtonText: 'Remove',
  expiryDateTitle: 'Expiry Date',
  userPreTitle: 'Hi, I\'m ',
  userNoTitle: 'Hi, your onboarding manager at Swae',
  userPostTitle: ', CEO at Swae',
  contactTitle: 'Contact',
  bookACallTitle: 'Book a call',
  viewTitle: 'View',
  userPreSubTitle: 'You have ',
  userPostSubTitle: ' left in your serviced onboarding. Lets get your Swae set up!',
  workflowPrimary: 'Customize your Workflow',
  questionnairePrimary: 'Customize Questionnaire',
  resourcesPrimary: 'Your Resources',
  setPermissionsPrimary: 'Set Permissions',
  createWorkSpacePrimary: 'Create your workspace',
  profileInfoPrimary: 'Profile Info Customization',
  emailTemplatePrimary: 'Customize your Messaging',
  teamDescriptionPrimary: 'Environment Description',
  workflowSecondary: 'How your users structure their proposals',
  questionnaireSecondary: 'How your users structure their proposals',
  resourcesSecondary: 'Your Infor, FAQ\'s, Awards, and links',
  setPermissionsSecondary: 'Assign admin roles, managers and experts.',
  createWorkSpaceSecondary: 'Create your workspace',
  profileInfoSecondary: 'Setup the information you want to collect about your users',
  emailTemplateSecondary: 'Customize the notifications your users will see',
  teamDescriptionSecondary: 'The general information about your workspace',
  noBoardingMessage: 'No Boarding!',
  proposalTrendsTitle: 'Proposal Trends',
  contributionsTitle: 'Contributions',
  votesTitle: 'Votes',
  proposalNumbersTitle: 'Proposal Numbers',
  timeTitle: 'Time',
  usersTitle: 'Users',
  activeUsersTitle: 'Active Users',
  totalsTitle: 'Totals',
  averagesTitle: 'Averages',
  noData: 'No data',
  downloadCustomReportsButton: 'Download Custom Reports',
  updateReportsButton: 'Update Reports',
  closeCustomReportsButton: 'Close Custom Reports',
  fromLaunchDateTitle: 'From Launch Date :',
  fromDateTitle: 'From Date :',
  toDateTitle: 'To Date :',
  downloadButton: 'Download',
  noMoreProposalsMessage: 'Yay! You have seen it all',
  noMoreCommentsMessage: 'Yay! You have seen it all',
  noMoreUserMessage: 'Yay! You have seen it all',
  confirmDeleteDialogTitle: 'Delete',
  confirmDeleteDialogContentText: 'Are you sure you want to delete',
  confirmDeleteDialogPrimaryButtonText: 'OK',
  noAward: 'No Awards!',
  submitAwards: 'Submit Awards',
  To: 'To',
  awardTitlePlaceholder: 'Add your Custom Award Title',
  awardDescPlaceholder: 'Add any more details about your custom Award here',
  uploadOwn: 'or upload your own',
  confirmDeleteDialogSecondaryButtonText: 'Cancel',
  confirmAwardReplaceDialogPrimaryButtonText: 'Yes',
  confirmAwardReplaceDialogSecondaryButtonText: 'No',
  confirmAwardReplaceDialogTitle: 'Conform',
  confirmAwardReplaceDialogContentText: 'This award has already been assigned to someone else do you want replace.',
  draftsTitle: 'Drafts',
  createdTitle: 'Created',
  aiReviewTitle: 'AI Review',
  sentToAiReviewTitle: 'Sent to AI review',
  aiReviewedTitle: 'AI reviewed',
  collaborationTitle: 'Collaborate',
  voteTitle: 'Vote',
  underManagementReviewTitle: 'Under Management Review',
  acceptedTitle: 'Accepted',
  rejectedTitle: 'Declined',
  implementedTitle: 'Implemented',
  archivedTitle: 'Archived',
  noTeamsMessage: 'No Teams!',
  domainTitle: 'Domain',
  teamNameTitle: 'Team Name',
  noMoreTeamsMessage: 'Yay! You have seen it all',
  noProposalsMessage: 'No Proposals!',
  noChallengesMessage: 'No challenge!',
  noNotificationSettings: 'No Notification Settings!',
  noCreateChallenge: 'No Mission',
  addUsers: 'Add Users',
  updateQuestionnaire: 'Update Questionnaire',
  updateWorkflow: 'Update Workflow',
  proposalHeaderTitle: 'Proposal Email Notification settings',
  welcomeTitle: 'Welcome to',
  noFlaggedCommentsMessage: 'No Flagged Feedback!',
  dotTitle: '.',
  linkTitle: 'Links',
  fAQsTitle: 'FAQs',
  qTitle: 'Q.',
  aTitle: 'A.',
  awardsTitle: 'Awards',
  proposalWorkflowTitle: 'Proposal Workflow',
  aiReview: 'AI Review',
  collaboration: 'Collaboration',
  voting: 'Voting',
  dayMaxWorkflow: 'Day max',
  managementReview: 'Management Review',
  descriptionResourcesTitle: 'Find loads of useful resources, tips and solutions to help you get your best ideas to the top of the pile!',
  newProposal: 'New Proposal',
  startANewProposal: 'Start a new proposal',
  firstNameTitle: 'First Name',
  lastNameTitle: 'Last Name',
  roleTitle: 'Role',
  homeTitle: 'Home',
  feedTitle: 'Feed',
  brandingTitle: 'Branding',
  proposalsTitle: 'Proposals',
  teamRequestTitle: 'Team Request',
  newProposalTitle: 'New Proposal',
  myProposalsTitle: 'My Proposals',
  myBookMarksTitle: 'My Bookmarks',
  collaborateTitle: 'Collaborate',
  resourcesTitle: 'Resources',
  graduatedTitle: 'Graduated',
  managementTitle: 'MANAGEMENT',
  underReviewTitle: 'Under Review',
  dashboardTitle: 'Dashboard',
  managementProposalsTitle: 'Proposals',
  healthReportTitle: 'Health Report',
  teamInsightTitle: 'Team Insight',
  adminTitle: 'Admin',
  onBoardingTitle: 'Onboarding',
  userManagementTitle: 'Users',
  inviteUsersTitle: 'Invite Users',
  reportsTitle: 'Reports',
  workflowTitle: 'Workflow',
  volunteersTitle: 'Volunteers',
  proposalReviewTitle: 'Proposal Review',
  flaggedCommentsTitle: 'Flagged',
  emailNotificationSettingsTitle: 'Email Notification Settings',
  refreshEmailTemplates: 'Refresh Email Templates',
  logoutTitle: 'Log Out',
  proposalLoading: 'Loading...',
  searchProposal: 'Search Proposal',
  searchUser: 'Search User',
  inappropriateContentText: 'This proposal has been deleted for disciplinary or inappropriate language',
  reasonDialogTitleForAccepting: 'Reason for Accepting',
  reasonDialogTitleForRejecting: 'Reason for Rejecting',
  reasonDialogContentText: 'Please provide this author your reason for this decision.',
  reasonDialogText: 'Reason',
  closeText: 'Close',
  voteCastMessage: 'Slide the bar to vote. Results will appear once you submit your vote!',
  submitText: 'Submit',
  saveDraftText: 'Save Draft',
  imageFormatUploadInstruction: '*Supported image format : .jpeg | .jpg | .png | .gif',
  videoFormatUploadInstruction: '*Supported video format : .mp3 | .mp4 | .mov',
  videoSizeUploadInstruction: '*Maximum video size : 150 MB',
  imageSizeUploadInstruction: '*Maximum image size : 5 MB',
  anonymizeText: 'Anonymize',
  edit: 'Edit',
  saveText: 'Save',
  analyzeText: 'Re-Analyse by AI',
  sendForHumanAugmentedAiReviewText: 'Analyze by Human',
  backText: 'Back',
  nextText: 'Next',
  readMoreText: 'Read more',
  readLessText: 'Read less',
  disagreeText: 'Disagree',
  agreeText: 'Agree',
  submitVoteText: 'Submit Vote',
  viewReason: 'View Reason',
  closeReason: 'Close Reason',
  anonymousText: 'Anonymous',
  rejectedText: 'Declined',
  resourcesOnBoardingTitle: 'Configure Branding',
  questionnaireOnBoardingTitle: 'Configure Questionnaire',
  workflowOnBoardingTitle: 'Configure Workflow',
  setPermissionsOnBoardingTitle: 'Manage Users',
  dialogTitleCollaborator: 'Invite Collaborator',
  dialogTitleVoter: 'Invite Voter',
  dialogTitleEndorse: 'Department Name',
  emailAddress: 'Email Address',
  yourDepartmentName: 'Enter your department',
  dialogContentTextCollaborator: 'Add email address below to invite new collaborator to this idea!',
  dialogContentTextVoter: 'Add email address below to invite new voter to this idea!',
  dialogButtonText: 'Invite',
  dialogSubmitButtonText: 'Submit',
  collaborateText: 'Collaborate',
  voteText: 'Vote',
  quickVoteText: 'Quick Vote',
  votingText: 'Voting',
  votingSubText: 'Please vote to make your voice heard on whether this proposal should be actioned',
  votingClosedText: 'Voting is now closed',
  proposalReviewText: 'Proposal Review',
  reasonDialogTitle: 'Reason',
  reasonDialogButtonText: 'Submit',
  acceptButtonText: 'Accept',
  rejectButtonText: 'Decline',
  shareWithManagersText: 'Share with other managers',
  submitForVoteText: 'Submit For Vote',
  endorseNowText: 'Endorse Now',
  volunteerText: 'Volunteer',
  votingResultsText: 'Voting Results',
  remainingCriteriaHeading: 'Nearly there!',
  remainingCriteriaSubHeading: 'Voting will open',
  afterSubmitForVote: 'after you submit for vote',
  autoSaveInited: 'Autosaving your draft!',
  autoSaveSuccess: 'Autosave successful! Document up to date.',
  autoSaveInitedAnswer: 'Autosaving your draft!',
  autoSaveSuccessAnswer: 'Autosave successful! Draft up to date.',
  autoSaveFailed: 'Autosave failed!',
  aiReviewText: 'AI Review',
  aiReviewedText: 'AI Reviewed',
  partialReviewText: 'AI Review',
  formatProposalText: 'Format Proposal',
  sendForUserText: 'Send for user review',
  publishText: 'Publish',
  feedbackTitle: 'Feedback',
  timelineDialogTitle: 'Timeline',
  saveButtonText: 'Save',
  cancelButtonText: 'Cancel',
  undoButtonText: 'Undo',
  replyButtonTitle: 'Reply',
  repliesTitleSingular: 'Reply',
  loadMoreButtonText: 'Load More',
  flagCommentCancelButtonText: 'Cancel',
  flagCommentSubmitButtonText: 'Submit',
  flagCommentTitle: 'Tell us why you are flagging',
  commentTextError: 'Please enter valid feedback.',
  inputType: 'Please type here',
  shortAnswerPlaceHolder: 'Please type up to 125 characters',
  indefinitelyText: 'indefinitely',
  aiSummaryHeader: 'AI Summary',
  summaryHeader: 'Summary',
  acceptedText: 'Accepted',
  hurrayTitle: 'Hurray!',
  yourProposalText: 'Your proposal',
  hasBeenSuccessfullyText: ' has been successfully',
  publishedText: 'published.',
  pageText: 'page.',
  editYourProposalText: 'Edit your proposal',
  createAiReviewMessageText: 'Swae’s AI analysts will give you their suggestions to review shortly and you can check the status in ',
  createCollaborateMessageText: 'Your colleagues can now collaborate on your proposal and can check the status in ',
  submittedToHumanAugmentedAiReviewText: 'submitted to human-augmented AI review.',
  successDialogTitle: 'Success',
  successDialogPrimaryButtonText: 'OK',
  successDialogContentText: 'Your proposal has been submitted to human-augmented AI review. A human analyst will review our AI\'s work prior to sending your proposal back to you, to ensure quality and add some personal touches. This process normally takes up to 24 hours.',
  noMoreVolunteerMessage: 'Yay! You have seen it all',
  noVolunteerMessage: 'No Volunteers!',
  volunteerButtonText: 'Volunteer Now',
  partialAIFeedbackDialogTitle: 'Feedback',
  partialAIFeedbackDialogButtonText: 'Submit',
  partialAIFeedbackDialogButtonSkipText: 'Skip to Publish',
  confirmDialogTitle: 'Confirm',
  confirmDialogContentText: 'Your edits in AI Summary will be lost by this action. Are you sure you want to proceed?',
  confirmDialogRTEContentText: 'Our AI Engine cannot analyze your proposal again after you format your proposal. Are you sure you want to proceed now?',
  confirmDialogPrimaryButtonText: 'OK',
  confirmDialogSecondaryButtonText: 'Cancel',
  collabDesc: 'Find new proposals and collaborate',
  voteDesc: 'Help find our next big success',
  averageScoreMet: 'Average score from votes met',
  voteCriteriaMet: 'Voters criteria met',
  feedbackCriteriaMet: 'Feedback criteria met',
  get riskFeedbackCriteriaMet () {
    return `${this.weakness} criteria met`
  },
  get neutralFeedbackCriteriaMet () {
    return `${this.question} criteria met`
  },
  get evidenceFeedbackCriteriaMet () {
    return `${this.strength} criteria met`
  },
  viewCriteriaMet: 'View criteria met',
  requiredAverageScore: '% more average score from votes required',
  requiredVotes: 'more voters required',
  requiredFeedbacks: 'more feedback required',
  get requiredEvidenceFeedbacks () {
    return `more ${this.strength.toLowerCase()}s required`
  },
  get requiredNeutralFeedbacks () {
    return `more ${this.question.toLowerCase()}s required`
  },
  get requiredRiskFeedbacks () {
    return `more ${this.weakness.toLowerCase()}es required`
  },
  requiredViews: 'more views required',
  requiredVolunteers: 'more volunteers required',
  confirmSubmitVoteDialogTitle: 'Submit Vote',
  confirmSubmitVoteDialogContentText1: 'Your vote is',
  confirmSubmitVoteDialogContentText2: 'Once you confirm, you cannot change your vote.',
  confirmSubmitVoteDialogPrimaryButtonText: 'Submit',
  confirmSubmitVoteDialogSecondaryButtonText: 'Cancel',
  againstText: 'Against',
  abstainText: 'Abstain',
  forText: 'For',
  hideTitle: 'Hide',
  repliesTitle: 'Replies',
  statusTitle: 'Status',
  firstLoginTitle: 'First Login',
  lastSeenTitleAndLocationTitle: 'Last Seen/Location',
  myProfileTitle: 'My Profile',
  showMyVoteText: 'You voted',
  public: 'Open to all',
  challengeTimelineTitle: 'Missions',
  noReports: "You do not have access to currently selected challenge's reports.",
  noChallengeMessage: 'There are no challenges created by you.',
  noUserMessage: 'No Users!',
  collaborationEnded: 'Submitting a new proposal ended on {endDate}',
  reviewsText: 'Reviews',
  resendVerificationLink: 'Need verification code again?',
  replaceWith: 'Replace With',
  replace: 'Replace',
  grammarAnalysis: 'Grammar Analysis',
  readabilityAnalysis: 'Readability Analysis',
  toneAnalysis: 'Tone Analysis',
  noPendingUsers: 'No pending users',
  reasonForDeletionText: 'If yes, please provide your reason for deletion.',
  pageNotFound: 'Page not found!',
  accessDenied: 'Access Denied',
  accessDeniedMessage: 'You are not authorized to view this page.',
  escalationCriteriaText: 'Escalation Criteria',
  selectChallengeText: 'Select a Mission',
  hideChallenge: 'Hide challenge details',
  showChallenge: 'Mission Details',
  challengeAllTitle: 'All Missions',
  challengeTitle: 'Mission Title',
  noActiveChallenge: 'No Active Mission',
  noExpiredChallenge: 'No Expired Mission',
  inappropriateFeedback: 'This feedback has been deleted for disciplinary or inappropriate language',
  strength: 'Strength',
  question: 'Question',
  weakness: 'Weakness',
  siteAdminTitle: 'Site Admin',
  challengeLeaderTitle: 'Challenge Leader',
  deleteLogicJumpSection: 'This section contains a logic jump, please remove logic jump and delete again!',
  editrteButtonText: 'Format Proposal',
  activeUsersTabLabel: 'Active',
  pendingUsersTabLabel: 'Pending',
  inviteUsersTabLabel: 'Invite',
  invitedByTitle: 'Invited By',
  get noStrengthsFeedback () {
    return `No ${this.strength}s`
  },
  get noQuestionsFeedback () {
    return `No ${this.question}s`
  },
  get noWeaknessesFeedback () {
    return `No ${this.weakness}es`
  },
  answerText: 'Answer',
  statsHeader: 'Stats',
  postButton: 'Post',
  implementText: 'Implement',
  chooseWorkflowType: 'Select a workflow type for your default challenge',
  chooseWorkflowTypeDescriptionContinuous: 'Continuous - Each proposal independently will get to stay in a specified stage for specified period of days',
  chooseWorkflowTypeDescriptionTimeBound: 'Time-bound - Time duration specified for each stage is applied to all the proposals in that stage hence all the proposals move to next stage at once',
  teamAlreadyExists: 'This team already exists',
  deletedTitle: 'Deleted',
  noNotifications: 'No Notifications',
  notificationsTitle: 'Notifications',
  genderTitle: 'Gender*',
  ageTitle: 'Age*',
  sectorTitle: 'Sector*',
  underRepresentedGroupsTitle: 'I identify with an Under-represented groups*',
  countryNationalityTitle: 'Nationality*',
  locationTitle: 'Location*',
  organizationTitle: 'Organization',
  anonymousTitle: 'I wish to remain anonymous*',
  // Translation Part 2
  myFeed: 'My Feed',
  recentlyViewed: 'Recently Viewed',
  feed: 'Feed',
  searchWhatInterestsYou: 'Search what interests you',
  create: 'Create',
  collaborate: 'Collaborate',
  feedSearchText: 'Search what intrests you',
  selectAMission: 'Select a mission',
  newMission: 'New Mission',
  myActivities: 'My Activities',
  howItWorks: 'How it works',
  following: 'Following',
  viewAll: 'View All',
  support: 'Support',
  addMission: 'Add Mission',
  insights: 'Insights',
  siteAdminSettings: 'Site Admin Settings',
  settings: 'Settings',
  inviteAColleague: 'Invite a colleague',
  suggestMission: 'Suggest Mission',
  proposalReview: 'Proposal Review',
  users: 'Users',
  branding: 'Branding',
  security: 'Security',
  onboarding: 'Onboarding',
  adminDashboard: 'Admin Dashboard',
  editHomepage: 'Edit Homepage',
  editAbout: 'Edit About',
  billing: 'Billing',
  notifications: 'Notifications',
  organizationName: 'Organisation Name', // Branding Page
  accountUrl: 'Account URL',
  logo: 'Logo',
  siteDesign: 'Site Design',
  termsAndConditionDescription: 'Terms & Conditions Description',
  termsAndConditions: 'Terms & Conditions',
  type: 'Type',
  externalLink: 'External Link',
  editor: 'Editor',
  link: 'Link',
  jpgPng2MBmax: 'JPG, PNG, 2MB max',
  webAddress: 'web address',
  totalUsers: 'Total Users', // User management page
  pendingInvites: 'Pending Invites',
  pending: 'Pending',
  invite: 'Invite',
  firstName: 'First Name',
  surname: 'Surname',
  status: 'Status',
  memberSince: 'Member Since',
  lastSeenLocation: 'Last Seen/ Location',
  email: 'Email',
  permissions: 'Permissions',
  save: 'Save',
  searchForUser: 'Search for User',
  inviteDescriptionText: 'Please enter an email to invite a new user. After each email, click the Enter(or Return) key on your keyboard. Please add as many emails as you wish.',
  ByNameOrEmail: 'By name or email',
  resend: 'Resend',
  home: 'Home',
  about: 'About',
  sectionType: 'Section Type',
  richText: 'Rich Text',
  updates: 'Updates',
  missionCreator: 'Mission Creator',
  start: 'Start',
  end: 'End',
  sponsors: 'Sponsors',
  decisions: 'Decisions',
  awards: 'Awards',
  faQs: 'FAQs',
  escalation: 'Escalation',
  engagement: 'Engagement',
  leaderboard: 'Leaderboard',
  milestones: 'Milestones',
  missions: 'Missions',
  comments: 'Comments',
  proposals: 'Proposals',
  createNewProposal: 'Create New Proposal',
  active: 'Active',
  drafts: 'Drafts',
  draft: 'Draft',
  underReview: 'Under Review',
  archived: 'Archived',
  declined: 'Declined',
  accepted: 'Accepted',
  implemented: 'Implemented',
  new: 'New',
  trending: 'Trending',
  buzzing: 'Buzzing',
  highlyRated: 'Highly Rated',
  nearlyDecisionReady: 'Nearly Decision Ready',
  needsSupport: 'Needs Support',
  awarded: 'Awarded',
  endingSoon: 'Ending Soon',
  missionGoals: 'Mission Goals',
  sponsorsAndStakeholders: 'Sponsors & Stakeholders',
  escalationMetrics: 'Escalation Metrics',
  missionMilestones: 'Mission Milestones',
  missionGoalsDescriptionText: 'When submitting proposals please have these Mission goals in mind. We aim to...',
  decisionsDescriptionText: 'A Review of all successful proposals will be conducted by the below leaders. They will give reasons for the outcome, which can be one of the following',
  declinedDescription: 'is not currently suitable',
  acceptedDescription: 'will be put forward for implementation',
  implementedDescription: 'will be acted upon immediately',
  configureBranding: 'Configure Branding', // Onboarding Page
  configureQuestionnaire: 'Configure Questionnaire',
  configureWorkflow: 'Configure Workflow',
  manageUsers: 'Manage Users',
  view: 'View',
  bookACall: 'BOOK A CALL',
  introText: 'Hi, I\'m',
  welcomeBack: 'Welcome back {name}', // Admin Dashboard page
  welcomeBackDescription: 'Your Swae is buzzing, there\'s a few things to do!',
  flaggedComments: 'Flagged Comments',
  flaggedComment: 'Flagged Comment',
  item: 'Item',
  whoAndWhen: 'Who & When',
  metrics: 'Metrics',
  dueDate: 'Due Date',
  action: 'Action',
  snapshot: 'Snapshot',
  activeProposals: 'Active Proposals',
  avgProposalVote: 'Avg Proposal Vote',
  loading: 'Loading...',
  activity: 'Activity',
  siteViews: 'Site Views',
  proposalStages: 'Proposal Stages',
  getMoreInsights: 'Get more insights',
  ignored: 'Ignored',
  deleted: 'Deleted',
  missionLeader: 'Mission Leader',
  who: 'Who',
  intervene: 'Intervene',
  reason: 'Reason',
  postedBy: 'Posted by',
  reportedBy: 'Reported by',
  deletedBy: 'Deleted by',
  ignoredBy: 'Ignored by',
  readLess: 'Read Less',
  readMore: 'Read More',
  abidesByCodeOfConduct: 'Abides by Code of Conduct',
  allowComment: 'Allow Comment',
  removeComment: 'Remove Comment',
  ignoreComment: 'Ignore Comment',
  createNewMission: 'Create new Mission',
  commentsViolateCommunityGuidelinesAndStandards: 'Comments violate community guidelines and standards',
  commentsAreInaccurateMisleadingOrNonFactual: 'Comments are inaccurate, misleading or non-factual',
  commentsUseProhibitedProfanity: 'Comments use prohibited profanity',
  commentsIntendedToSlanderOtherCommunityMembers: 'Comments intended to slander other community members',
  customReasonOther: 'Custom Reason/Other',
  reportedFor: 'Reported for',
  showMeMoreInsights: 'Show me more insights',
  reviewNow: 'Review Now',
  oneMinTask: '1 min task',
  activeUsers: 'Active Users', // Insights page
  monthlyActiveUsers: 'Monthly Active Users',
  averageOverPeriod: 'Average over period',
  changeOverPeriod: 'Change over period',
  inactiveUsers: 'Inactive Users',
  totalViews: 'Total Views',
  totalEngagements: 'Total Engagements',
  totalSuggestions: 'Total Suggestions',
  totalSuggestedEdits: 'Total Suggested Edits',
  totalComments: 'Total Comments',
  totalProposals: 'Total Proposals',
  engagements: 'Engagements',
  votes: 'Votes',
  languages: 'Languages',
  nationality: 'Nationality',
  suggestedEdits: 'Suggested Edits',
  engagementsSubtitle: 'The amount of Proposals, Votes, Comments and Suggestions',
  proposalsSubtitle: 'Where Proposals are in the platform',
  votesSubtitle: 'The amount of Votes',
  commentsSubtitle: 'The amount of Comments',
  suggestedEditsSubtitle: 'The amount of Suggestions',
  languagesSubtitle: 'Which languages are people using on the platform',
  nationalitySubtitle: 'Which nation users are from',
  usersSubtitle: 'How many people are using {clientname}\'s Swae',
  hallofFame: 'Hall of Fame',
  filterBy: 'Filter by',
  overview: 'Overview',
  downloadXLSReport: 'Download XLS Report',
  missionIsContinuous: 'Mission is Continuous', // Mission Creation
  questionnaire: 'Questionnaire',
  saveAsDraft: 'Save as Draft',
  launchMission: 'Launch Mission',
  updateMission: 'Update Mission',
  addYourMissionTitleHere: 'Add your Mission\'s title here',
  goals: 'Goals',
  enableGoals: 'Enable Goals',
  goalsDescription: 'When submitting proposals for this Mission please work within these Goals...',
  addGoalsHeading: 'Add goals to your mission',
  addCustomGoals: 'Add Custom Goals',
  decisionHeaderForMissionCreation: 'Allow proposals to escalate to decisions',
  decisionMakersQuestion: 'Who are the Decision Makers?',
  searchLeadership: 'Search Leadership',
  howDoProposalsEscalate: 'How do Proposals escalate?',
  proposalReviewProcessAndMetrics: 'Proposal review process and metrics',
  manuallyEscalatedByLeadership: 'Manually escalated by leadership',
  sendProposalsToReviewAutomatically: 'Send proposals to review automatically, based on metrics',
  escalatedUsingMetrics: 'Escalated using metrics',
  escalationCriteria: 'Escalation Criteria',
  escalationCriteriaDescription: 'Proposals that meet these criteria will be sent for review to Decision Makers',
  proposalsEscalationMetrics: 'Proposals escalation Metrics',
  maximumLengthProposalCanBeActive: 'Maximum length Proposal can be active',
  howLongCanProposalStayActive: 'How long can proposal stay active',
  minimumAverageScoreFromVotes: 'Minimum Average Score from Votes',
  minimumNumberOfVoters: 'Minimum Number of Voters',
  minimumNumberOfCollaborativeEdits: 'Minimum Number of Collaborative Edits',
  minimumNumberOfStrengths: 'Minimum Number of Strengths',
  minimumNumberOfConcerns: 'Minimum Number of Concerns',
  minimumNumberOfQuestions: 'Minimum Number of Questions',
  minimumNumberOfProposalViews: 'Minimum Number of Proposal Views',
  awardsHeaderDescription: 'To supercharge your ideas, we\'re giving some great awards on this Mission...',
  addYourCustomAwardTitle: 'Add your Custom Award Title',
  addYourCustomAwardDescription: 'Add any more details about your custom Award here',
  orUploadYourOwn: 'or upload your own',
  awardIcon: 'Award Icon',
  awardType: 'Award Type',
  awardGoesToPerson: 'Award goes to person',
  awardGoesToProposal: 'Award goes to proposal',
  addAward: 'Add Award',
  addAnotherAward: 'Add another Award',
  awardGoesTo: 'Award goes to',
  to: 'To',
  awardName: 'Award Name',
  assignAwards: 'Assign Awards',
  addFaqsHere: 'Add FAQ\'s here',
  addYourQuestionHere: 'Add your question here',
  addYourAnswerHere: 'Add your answer here',
  reorder: 'Reorder',
  delete: 'Delete',
  addAnyMissionMilestones: 'Add any Mission Milestones',
  addMissionMilestonesDescription: 'Here\'s the major updates from this Mission',
  addMilestoneHere: 'Add Milestone here',
  addUpdateHere: 'Add Update here',
  addUpdateHereStar: 'Add Update here*',
  addYourDetailHere: 'Add your detail here',
  imagesJpgPngGif: 'Images: JPG, PNG, GIF',
  videoMpegMov: 'Video: MPEG, MOV',
  maxFilesize20MbMaxFilesize: '20MB max filesize',
  // Wizard Builder
  bestForSimpleMissions: 'Best for simple Missions',
  createReacherFullFeaturedMissions: 'Create reacher, full-featured Missions',
  around3Minutes: 'Around 3 minutes',
  whatOutcomeDoYouWant: 'What outcome do you want...',
  outcomeAnswer:
    'Swae can be used simply to brainstorm ideas or can give you a one-stop workflow from ideas to a full decision making process. With decision making enabled, users are likely to be more engaged, knowing their ideas might be actioned.',
  around5Minutes: 'Around 5 minutes',
  around4Minutes30Seconds: 'Around 4 minutes 30 seconds',
  optional: 'Optional',
  recommended: 'Recommended',
  letsSetUpYourMission: 'Let\'s set up your Mission',
  collectIdeasMakeDecisions: 'Collect ideas & make decisions',
  justCollectIdeas: 'Just collect ideas',
  back: 'Back',
  next: 'Next',
  cancel: 'Cancel',
  required: 'Required',
  titleAndDescription: 'Title & Description*',
  addMedia: 'Add Media',
  missionDuration: 'Mission Duration*',
  decisionMakers: 'Decision Makers',
  proposalEscalation: 'Proposal Escalation*',
  addFaQs: 'Add FAQ\'s ',
  inviteUsers: 'Invite Users',
  customiseQuestions: 'Customise Questions',
  missionTitle: 'Mission Title*',
  whatDoYouWantToSolve: 'What do you want to solve?',
  description: 'Description',
  addMoreDetailsHere: 'Add more detals here..',
  missionMedia: 'Mission Media',
  addFromLibrary: 'Add from Library',
  poweredBy: 'Powered by',
  findAnImage: 'Find an image',
  orAddYourOwn: '...or add your own...',
  startAtSpecificTime: 'Start at specific time',
  startRightAway: 'Start right away',
  missionHasAClosingDate: 'Mission has a closing date',
  proposalsEscalation: 'Proposals Escalation',
  howAreProposalsEscalated: 'How are Proposals escalated?',
  escalatedManuallyByLeaders: 'Escalated manually by leaders',
  whatHappensOnEscalation: 'What happens on escalation?',
  bothMethods: 'Both methods',
  proposalGoesToReview: 'Proposal goes to Review',
  proposalIsAutomaticallyAccepted: 'Proposal is automatically accepted',
  whatAreYourGoalsForThisMission: 'What are your goals for this mission?',
  generateAHighQuantityOfProposals: 'Generate a high quantity of proposals',
  idPreferFewerHigherQualityProposals: 'I\'d prefer fewer, higher quality proposals',
  iWantALotOfDiscussionOnProposals: 'I want a lot of discussion on proposals',
  illCustomiseTheMetricsMyself: 'I\'ll customise the metrics myself',
  startDate: 'Start Date',
  endDate: 'End Date',
  addAnotherFaq: 'Add another FAQ',
  access: 'Access',
  missionIsOpenToThePublic: 'Mission is open to the public',
  onlySelectedUsers: 'Only selected users',
  searchUsers: 'Search Users',
  inviteByEmail: 'Invite By Email',
  searchForPeopleOrTeams: 'Search for people or teams',
  customiseProposalBuilder: 'Customise Proposal Builder',
  defaultQuestionsAddCustomOnes: 'Default questions + add custom ones',
  usingDefaultQuestions: 'Using default questions',
  around30Seconds: 'Around 30 seconds',
  launchYourMissionRightAway: 'Launch your mission right away!',
  missionTitleSubCardText: 'Short and punchy titles help to sell your Mission. Use evocative language and end with a question to gain the interest of your readers....',
  missionDurationSubCardText: 'How long do you want your Mission to remain active to accept proposals?',
  missionGoalsSubCardText: 'Goals help users understand what is and isn\'t acceptable for their submissions.',
  missionGoalsSubCardDescriptionText: 'You can help guide proposals towards success by adding well considered goals.',
  proposalEscalationSubCardVideoDescription: 'The short clip helps explain Escalation in Swae\'s workflow',
  awardSubCardText:
    'Since crowdsourcing requires the participation of crowds clarifying your goals from the start will everyone see what success looks and set expectations on the decision making process.',
  inviteUsersSubCardText: 'A proposal must meet the below metrics to move from \'Active\' to \'Under Review\'.',
  inviteUsersDescription: 'Please note that you can use some, or none of these...',
  reviewStar: 'Review*',
  reviewProcess: 'Review Process',
  reviewProcessStar: 'Review Process*',
  typeYourTitleHere: 'Type your title here', // Proposal creation page
  typeCustomTagsHere: 'Type custom tags here',
  addTrendingTags: 'Add trending tags',
  anonymous: 'Anonymous',
  remindMe: 'Remind Me',
  addImageOrVideoHere: 'Add Image or video here',
  mission: 'Mission',
  submit: 'Submit',
  spellingGrammar: 'Spelling & Grammar',
  readability: 'Readability',
  readingTime: 'Reading time',
  sentiment: 'Sentiment',
  emotion: 'Emotion',
  titleRecommendation: 'Title recommendation',
  titleRecommendationQuestion: 'Would you like Swae to recommend a title?',
  titleRecommendationFailedText: 'Failed to get title suggestion, try again with some more text.',
  replaceCurrentTitleText: 'Replace current title "{currentTitle}" with',
  suggest: 'Suggest',
  search: 'Search',
  viewLess: 'View Less',
  showAll: 'Show All',
  decisionsHeaderDescription:
    'Missions are the future of {clientname}. They are the starting point for what we need to innovate the future, or find solutions for the problems that are holding us back.',
  activeMissions: 'Active Missions',
  archivedMissions: 'Archived Missions',
  deletedMissions: 'Deleted Missions',
  collaborators: 'Collaborators',
  creators: 'Creators',
  voters: 'Voters',
  hi: 'Hi {name}',
  heyFirstName: 'Hey {name}!',
  welcomeTo: 'Welcome to',
  editHereToAddDescription: 'Edit here to add description',
  getStarted: 'Get Started',
  missionUsers: 'Mission Users',
  missionViews: 'Mission Views',
  missionProposals: 'Mission Proposals',
  missionVotes: 'Mission Votes',
  awardMissionInfoDescription: 'The top proposals in this Mission will be awarded some great incentives.',
  milestonesMissionInfoDescription: 'Here\'s the major updates from this Mission',
  closeDate: 'Close Date',
  startAllMissionsAtASpecificTime: 'Start all Missions at a specific time',
  startAllImmediately: 'Start all immediately',
  missionsHaveAClosingDate: 'Missions have a closing date',
  allMissionsAreContinuous: 'All Missions are Continuous',
  openToAllUsers: 'Open to all {clientName} users',
  invitedUsersOnly: 'Invited users only',
  illInviteUsersLater: 'I\'ll invite users later',
  aboutHowItWorks: 'About {teamName}',
  integration: 'Integration',
  raiseTicket: 'Raise Ticket',
  howSwaeWorks: 'How Swae Works',
  faq: 'FAQ',
  welcomeDialogHeading1: 'Hey {userFirstName}...',
  welcomeDialogHeading2: "Looking to ace Swae? We've got you covered",
  welcomeDialogContent1: "Getting used to new tools can feel like a big change, but we're here to help you take your small first step.",
  welcomeDialogContent2: 'Choose from our library of on-demand demos to see how easy it is to use Swae',
  viewKnowledgebase: 'View Knowledgebase',
  seeFaqs: 'See FAQs',
  watchTutorials: 'Watch Tutorials',
  continueToSwae: 'Continue to Swae',
  polls: 'Polls',
  poll: 'Poll',
  comment: 'Comment',
  newPoll: 'New Poll',
  dialogContentTextPollVoter: 'Add the email address below to invite new voter to this poll!',
  useTemplate: 'Use template',
  startPollAtASpecificTime: 'Start Poll at a specific time',
  startPollImmediately: 'Start Poll immediately',
  endPollAtASpecificTime: 'End Poll at a specific time',
  pollIsContinuous: 'Poll is Continuous',
  startTime: 'Start time',
  closingTime: 'Closing time',
  dailyActiveUsers: 'Daily Active Users',
  aiGuidedBuilder: 'AI Guided Builder',
  diyProposalBuilder: 'DIY Proposal Builder',
  weWillDoThisStepBySTep: "We'll do this step-by-step",
  justGiveMeABlankPage: 'Just give me a blank page',
  ifYouHaveGotAnIdeaAndWantSomeStructuredHelp: "If you've got an idea and want some structured help",
  bestIfYouHaveAlreadyThoughtYourProposalThrough: "Best if you've already thought your proposal through",
  whatIsYourTitle: "What's your Title?",
  imSwaeAI: "I'm Swae's AI",
  imHereToHelpYouBuildYourProposal: "I'm here to help you build your proposal",
  illMakeAFewSuggestionsAlongTheWay: "I'll make a few suggestions along the way",
  helpEngagePeopleWithAGreatImageOrVideo: 'Help engage people with a great image or video',
  yourProposalWillBuildHere: 'Your proposal will build here',
  proposalMedia: 'Proposal Media',
  adminSecurityOpenSwaeText: 'All the main content (Missions, Proposals, Polls, Feed etc) are viewable to anyone on the internet but Users must be signed in to interact with them (to Comment, Vote, Edit etc).',
  adminSecurityOpenSwaeTextInfoText: "You can individually set Missions, Polls to be 'Private' so they are only visible to invited users.",
  makeMySwaePublic: 'Make my Swae Public',
  visibility: 'Visibility',
  oneClickSignOnOptions: 'One click sign-on options',
  enableFeatures: 'Enable Features',
  enableOrDisableTheFollowingFeatures: 'Enable or disable the following features',
  whichSignInOptionDoYouWantToUse: 'Which sign in option do you wish to use?',
  microsoftSignInDisclaimer: 'Microsoft sign in works only with Active Directory and not personal accounts. To configure Microsoft sign in, sign in to swae as an admin and follow the instructions specified in settings/security.',
  checkTutorial: 'Check Tutorial',
  domainAllowedError: 'Enter domain email address',
  emailDomainError: 'Please enter a valid domain email address',
  restrictSignupAccess: 'Restrict Signup Access',
  gotThisFarAndNotSeenAnyProposalYouLike: 'Got this far and not seen any proposal you like?',
  youNeedToCreateYourOwn: 'You need to create your own...',
  addUrl: 'Add Url',
  url: 'URL',
  snapshotDialog: 'Snapshot Dialog'
}
export default en
