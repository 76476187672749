import React from 'react'

export const langs = {
  en: {
    code: 'en',
    id: 'English',
    name: 'English'
  },
  fr: {
    code: 'fr',
    id: 'French',
    name: 'Français'
  },
  ru: {
    code: 'ru',
    id: 'Russian',
    name: 'русский'
  },
  'zh-CN': {
    code: 'zh-CN',
    id: 'Mandarin',
    name: '中国人'
  },
  ar: {
    code: 'ar',
    id: 'Arabic',
    name: 'عربى'
  },
  es: {
    code: 'es',
    id: 'Spanish',
    name: 'Española'
  }
}

export const langArr = ['English', 'French', 'Russian', 'Mandarin', 'Arabic', 'Spanish']

export const LangContext = React.createContext({
  lang: langs.en.code, // default value
  toggleLang: (...args) => {}
})
