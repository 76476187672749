import React, { useEffect, useState, memo, Suspense } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import Amplify, { Auth } from 'aws-amplify'
import { IntlProvider } from 'react-intl'
import { LangContext, langs } from './context/langContext'
import httpHelper from './shared/httpHelper'
import urlConstants from './constants/urlConstants'
import * as moment from 'moment'
import * as momentz from 'moment-timezone'
import { Translator } from 'react-auto-translate'
// import AdminDashboardLoading from './components/Loading/AdminDashboardLoading.js';
// import FeedLoading from './components/Loading/FeedLoading.js';
import helper from './shared/helper'
import constants from './constants/constants'
import TeamContext from './context/TeamContext'
import ReactGA from 'react-ga'
import Loading from './layout/Loading'
// import NewApp from './NewApp'
const AppRouter = React.lazy(() => import('./AppRouter'))

// This is just an example of how you could wire this to localStorage
const cacheProvider = {
  get: (language, key) => ((JSON.parse(localStorage.getItem('translations')) || {})[key] || {})[language],
  set: (language, key, value) => {
    const existing = JSON.parse(localStorage.getItem('translations')) || {
      [key]: {}
    }
    existing[key] = { ...existing[key], [language]: value }
    localStorage.setItem('translations', JSON.stringify(existing))
  }
}

const config = {
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    mandatorySignIn: false,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env[`REACT_APP_${helper.getDomain()}_COGNITO_APP_CLIENT_ID`] || process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_COGNITO_AUTHENTICATION_FLOW,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${helper.getHostName()}/log-in`,
      redirectSignOut: `${helper.getHostName()}/sign-out`,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  API: {
    endpoints: [
      {
        name: 'api_swae_2.0',
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_COGNITO_REGION,
        custom_header: async () => {
          try {
            const currentSession = await Auth.currentSession()
            return {
              Authorization: `Bearer ${currentSession.idToken.jwtToken}`
            }
          } catch (e) {
            return {}
          }
        }
      },
      {
        name: 'ai_api_swae_2.0',
        endpoint: process.env.REACT_APP_AI_API_URL,
        region: process.env.REACT_APP_COGNITO_REGION
      },
      {
        name: constants.AI_ARTICLE_API_NAME,
        endpoint: process.env.REACT_APP_AI_ARTICLE_API_URL,
        region: process.env.REACT_APP_COGNITO_REGION
      },
      {
        name: 'summary_api_swae_2.0',
        endpoint: process.env.REACT_APP_SUMMARY_API_URL,
        region: process.env.REACT_APP_COGNITO_REGION
      },
      {
        name: constants.AI_TITLE_SUGGESTION_API_NAME,
        endpoint: process.env.REACT_APP_AI_TITLE_SUGGESTION_API_URL,
        region: process.env.REACT_APP_COGNITO_REGION
      },
      {
        name: constants.AI_READ_ABILITY_API_NAME,
        endpoint: process.env.REACT_APP_AI_READ_ABILITY_API_URL,
        region: process.env.REACT_APP_COGNITO_REGION
      },
      {
        name: constants.PARAPHRASING_AI_API_NAME,
        endpoint: process.env.REACT_APP_AI_PARAPHRASING_API_URL,
        region: process.env.REACT_APP_COGNITO_REGION
      }
    ]
  }
}

Amplify.configure(config)

// Initialize google analytics
ReactGA.initialize(process.env.REACT_APP_GA_ID)

function App () {
  const [team, setTeam] = React.useState(null)
  const toggleLang = async (lang = 'English', updateLang = true) => {
    if (updateLang) {
      if (window.location.pathname.endsWith('new-proposal')) {
        if (window.confirm('Please make sure you click \'Save Draft\'. Otherwise, you may lose the changes you have made.')) {
          setAppState({ ...state, lang: languageMapping(lang) })
          updateMomentLocale(lang)
          await updateUserLang(lang)
        }
      } else if (window.location.pathname.endsWith('questionnaire')) {
        if (window.confirm('Please make sure you click \'Save\'. Otherwise, you may lose the changes you have made.')) {
          setAppState({ ...state, lang: languageMapping(lang) })
          updateMomentLocale(lang)
          await updateUserLang(lang)
          window.location.reload()
        }
      } else {
        setAppState({ ...state, lang: languageMapping(lang) })
        updateMomentLocale(lang)
        await updateUserLang(lang)
      }
    } else {
      setAppState({ ...state, lang: languageMapping(lang) })
      updateMomentLocale(lang)
    }
  }

  const languageMapping = (langFull) => {
    let lang = 'en'
    Object.keys(langs).forEach((key) => {
      if (langs[key] && langs[key].id === langFull) {
        lang = langs[key].code
      }
    })
    return lang
  }

  const updateMomentLocale = (langFull) => {
    const lang = languageMapping(langFull)
    if (lang !== 'en') {
      moment.locale(lang, helper.getMomentLocaleData(lang))
      momentz.locale(lang, helper.getMomentLocaleData(lang))
    } else {
      moment.locale(lang)
      momentz.locale(lang)
    }
  }

  const updateUserLang = async (lang) => {
    const params = [{ param: '{teamId}', value: helper.getDomain }]
    const postData = {
      body: {
        languageSelection: lang
      }
    }
    const address = helper.metaMaskAddress()
    if (address) {
      postData.body.address = address
    }
    return await httpHelper.put(urlConstants.updateUser, httpHelper.getUri(urlConstants.updateUser, params), postData)
  }

  const [state, setAppState] = useState({ lang: langs.en.code, toggleLangFn: toggleLang })
  const isOpenPoll = window && window.location && window.location.pathname && window.location.pathname.split('/') && window.location.pathname.split('/').length ? window.location.pathname.split('/')[1] === 'poll' : undefined
  const updateTeamData = async () => {
    try {
      const teamData = await helper.getTeam()
      teamData.updateTeamData = await updateTeamData
      setTeam(teamData || {})
    } catch (err) {
      console.log('Error in updateTeamData', err)
    }
  }

  useEffect(() => {
    const url = window.location.pathname
    const urlSplitArray = url.split('/')
    if (urlSplitArray.length > 0 && urlSplitArray.includes('slack-integration')) {
      const domainName = urlSplitArray[urlSplitArray.length - 1]
      const query = new URLSearchParams(window.location.search)
      window.location.replace(`https://${domainName}/management/integration?${query}`)
    }
    async function fetchData () {
      try {
        // Creating async function reference in order to execute both await (api calls) parallelly
        const teamData = await helper.getTeam(isOpenPoll ? { queryStringParameters: { isOpen: true } } : {})
        console.log('teamData-------', teamData)
        if (teamData && teamData.cognitoClientId) {
          config.Auth.userPoolWebClientId = teamData.cognitoClientId
          Amplify.configure(config)
        }
        teamData.updateTeamData = await updateTeamData
        setTeam(teamData || {})
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  // const currentPathName = window.location.pathname
  if (!team && !isOpenPoll) {
    return (
      <LangContext.Provider value={state}>
        <IntlProvider locale={state.lang} messages={helper.getLanguageMessages()[state.lang]}>
          <Loading />
          {/* {currentPathName === '/admin-dashboard' ? <AdminDashboardLoading /> : currentPathName === '/feed' ? <FeedLoading /> :<Loading />} */}
        </IntlProvider>
      </LangContext.Provider>
    )
  }
  const teamSpecificKeyword = team && team.customFeatures && team.customFeatures.teamSpecific ? team.customFeatures.teamSpecific : undefined
  const colorCode = team ? team.colorCode : constants.THEME_COLOR_MAPPING.default
  theme.palette.primary.main = colorCode
  theme.palette.primary.dark = colorCode
  // Set Support Widget Background
  if (window.FreshWidget) {
    window.FreshWidget.update({ buttonBg: theme.palette.primary.main })
  }

  return (
    <LangContext.Provider value={state}>
      <IntlProvider locale={state.lang} messages={team ? helper.getLanguageMessages(teamSpecificKeyword)[state.lang] : helper.getLanguageMessages()[state.lang]}>
        <MuiThemeProvider theme={theme}>
          <TeamContext.Provider value={team}>
            <Translator cacheProvider={cacheProvider} to={state.lang} from="en" googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
              <Suspense fallback={<Loading />}>
                {/* <NewApp /> */}
                <AppRouter team={team} updateTeamData={updateTeamData} isOpenPoll={isOpenPoll} />
              </Suspense>
            </Translator>
          </TeamContext.Provider>
        </MuiThemeProvider>
      </IntlProvider>
    </LangContext.Provider>
  )
}

export default memo(App)
