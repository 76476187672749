import { API } from 'aws-amplify'
import axios from 'axios'
import FileType from 'file-type'
import * as moment from 'moment'
import uuidv4 from 'uuid/v4'
import constants from '../constants/constants'
import httpconstants from '../constants/httpconstants'
import urlConstants from '../constants/urlConstants'
import Auth from '../shared/auth'
import messagesEn from '../translations/en.js'
import messagesEnIn from '../translations/enin.js'
import messagesFr from '../translations/fr.js'
import messagesFrIn from '../translations/frin.js'
import messagesEs from '../translations/es.js'
import messagesRu from '../translations/ru.js'
import messagesAr from '../translations/ar.js'
import messagesCh from '../translations/ch.js'
import httpHelper from './httpHelper'
import lowerCase from 'lodash/lowerCase'

const getHostedZone = () => {
  return process.env.REACT_APP_HOSTED_ZONE || constants.APP_DOMAIN_NAME
}

const isDomainAllowed = (hostname) => {
  const item = constants.APP_DOMAIN_NAMES.find(i => hostname.includes(i))
  const status = item ? hostname.includes(item) : false
  return status
}

const getHostName = () => {
  return window && window.location && window.location.origin ? window.location.origin : `https://${getDomain}`
}

const getLocationHostName = () => {
  return `${window.location.pathname}`
}

const getDomain = () => {
  if (window.location.hostname.includes(constants.ET_STAGING)) {
    return constants.ET_STAGING_MAP_TO_TEAM_ID
  } else if (window.location.hostname.includes(getHostedZone()) || window.location.hostname.includes(constants.APP_DOMAIN_NAME) || isDomainAllowed(window.location.hostname)) {
    return window.location.hostname
  } else {
    return constants.DEFAULT_TEAM_ID
  }
}
const getLanguageMessages = (teamSpecifc) => {
  if (typeof teamSpecifc !== 'undefined') {
    if (teamSpecifc === constants.IN_KEYWORD) {
      console.log('team IN', teamSpecifc)
      console.log('object Assign', Object.assign(messagesEn, messagesEnIn))
      return {
        fr: Object.assign(messagesFr, messagesFrIn),
        en: Object.assign(messagesEn, messagesEnIn)
      }
    } else {
      return {
        fr: messagesFr,
        en: messagesEn,
        ar: messagesAr,
        es: messagesEs,
        ru: messagesRu,
        'zh-CN': messagesCh
      }
    }
  } else {
    return {
      fr: messagesFr,
      en: messagesEn,
      ar: messagesAr,
      es: messagesEs,
      ru: messagesRu,
      'zh-CN': messagesCh
    }
  }
}

const objToParam = (obj) => {
  return Object.keys(obj).reduce(function (str, key, i) {
    const delimiter = i === 0 ? '?' : '&'
    key = encodeURIComponent(key)
    const val = encodeURIComponent(obj[key])
    return [str, delimiter, key, '=', val].join('')
  }, '')
}

const getMyProposals = async () => {
  try {
    const { Data, Count } = await API.get(
      'api_swae_2.0',
      `/getProposals/${constants.MY_PROPOSAL_DEFAULT_VALUE.STATE}/${constants.MY_PROPOSAL_DEFAULT_VALUE.SORT}/${constants.MY_PROPOSAL_DEFAULT_VALUE.CATEGORY}/${constants.MY_PROPOSAL_DEFAULT_VALUE.FROM}/${constants.MY_PROPOSAL_DEFAULT_VALUE.PG_SIZE}/${constants.MY_PROPOSAL_DEFAULT_VALUE.IS_OWN}`
    )
    const proposalsList = {
      proposalList: Data,
      count: Count
    }
    return proposalsList
  } catch (e) {
    await handleUnauthorizedError(e)
    throw e
  }
}

const signOut = async () => {
  try {
    const params = [{ param: '{teamId}', value: getDomain() }]
    const postData = {
      body: {
        action: constants.ACTION.UPDATE
      }
    }
    const address = metaMaskAddress()
    if (address) {
      postData.body.address = address
    }
    localStorage.setItem('gettingStartedUserChecklistClosed', false)
    try {
      await httpHelper.put(urlConstants.updateUser, httpHelper.getUri(urlConstants.updateUser, params), postData)
    } catch (e) {
      console.log('error', e)
    }
    try {
      await Auth.signOut()
    } catch (e) {
      console.log('error', e)
    }
    try {
      await window.gapi.auth2.getAuthInstance().signOut()
    } catch (e) {
      console.log('error', e)
    }
    window.localStorage.removeItem('appAuth')
    window.localStorage.removeItem('walletconnect')
  } catch (e) {
    if (Error && Error.response && Error.response.status && Error.response.status === httpconstants.STATUS_401) {
      console.error(Error.response.data)
    } else {
      throw e
    }
  }
}

const handleUnauthorizedError = async (Error) => {
  if (Error && Error.response && Error.response.status && Error.response.status === httpconstants.STATUS_401) {
    console.error(Error.response.data)
    // await signOut()
  }
}

const getTeam = async (init) => {
  const Data = await API.get('api_swae_2.0', `/getTeam/${getDomain()}`, init)
  return Data.Item
}

const checkTeamAvailabilty = async (domain) => {
  const Data = await API.get('api_swae_2.0', `/getTeam/${domain}`)
  return Data.Item
}

const getTeamUnAuth = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_API_URL}/getTeam/${getDomain()}`)
  } catch (e) {
    return e
  }
}

const getQuestionnaire = async (lang, challengeId, startKey) => {
  try {
    const params = [
      { param: '{lang}', value: lang },
      { param: '{challengeId}', value: challengeId }
    ]
    const Data = await httpHelper.get(urlConstants.getQuestionnaire, httpHelper.getUri(urlConstants.getQuestionnaire + (startKey || ''), params))
    return Data
  } catch (e) {
    await handleUnauthorizedError(e)
    throw e
  }
}

const getCategoryId = (list, value) => {
  const arrayList = Array.from(list)
  let categoryId
  const index = arrayList.findIndex((item) => item.name === value)
  if (index !== -1) {
    categoryId = list[index].categoryId
  }
  return categoryId
}

const getProposals = async (state, sort, category, from, isown, search, challengeId, challengeIds, bookMarkIds, type = 'all', pgSize, statusFilter, hashTagList, isFeed, reportDuration) => {
  const pgsize = pgSize === undefined ? (bookMarkIds ? constants.MY_PROPOSAL_DEFAULT_VALUE.PG_SIZE : type === 0 ? constants.PAGE_SIZE.proposal_500 : constants.PAGE_SIZE.proposal) : pgSize
  category = category === 'All Categories' ? 'all' : category
  try {
    const queryStringParameters = {
      tId: getDomain()
    }
    const address = metaMaskAddress()
    if (address) {
      queryStringParameters.address = address
    }
    const params = [
      { param: '{state}', value: state },
      { param: '{sort}', value: sort },
      { param: '{category}', value: category },
      { param: '{from}', value: from },
      { param: '{pgsize}', value: pgsize },
      { param: '{isown}', value: isown },
      { param: '{search}', value: search },
      { param: '{challengeId}', value: challengeId },
      { param: '{challengeIds}', value: challengeIds },
      { param: '{bookMarkIds}', value: bookMarkIds }
    ]
    if (isown) {
      params.push({ param: '{sampleProposal}', value: undefined })
      params.push({ param: '{startDate}', value: undefined })
      params.push({ param: '{endDate}', value: undefined })
      params.push({ param: '{isPagination}', value: true })
    }
    if (type === 0) {
      params.push({ param: '{sampleProposal}', value: type })
    } else if (type !== 'all') {
      params.push({ param: '{sampleProposal}', value: type })
    }
    if (statusFilter) {
      params.push({ param: '{status}', value: statusFilter })
    }
    if (hashTagList && hashTagList.length) {
      queryStringParameters.hashTagList = JSON.stringify(hashTagList)
    }
    if (isFeed) {
      queryStringParameters.isFeed = isFeed
    }
    if (reportDuration) {
      queryStringParameters.reportDuration = reportDuration
    }
    const { Data, Count } = await httpHelper.get(
      type !== 'all' ? urlConstants.getSampleProposals : statusFilter ? urlConstants.getProposalsWithStatus : isown ? urlConstants.getIntervalProposals : urlConstants.getProposals,
      httpHelper.getUri(
        type !== 'all' ? urlConstants.getSampleProposals : statusFilter ? urlConstants.getProposalsWithStatus : isown ? urlConstants.getIntervalProposals : urlConstants.getProposals,
        params
      ),
      { queryStringParameters }
    )
    const proposalsList = {
      proposalList: Data,
      count: Count,
      from: from + Data.length,
      hasMoreStatus: from + Data.length < Count
    }
    return proposalsList
  } catch (e) {
    if (e && e.response && e.response.status && e.response.status === httpconstants.STATUS_501 && e.response.data && e.response.data.status) {
      return {
        status: e.response.data.status,
        message: e.response.data.message
      }
    }
    await handleUnauthorizedError(e)
    throw e
  }
}

const getHomeImage = (imageName) => {
  return `https://${process.env.REACT_APP_S3_BUCKET}.s3-${process.env.REACT_APP_COGNITO_REGION}.amazonaws.com/${getDomain()}/images/static/${imageName}`
}

const uploadImage = async (s3preSignedUrl, file, type) => {
  return await axios.put(s3preSignedUrl, file, { headers: { 'Content-Type': type } })
}

const getUUID = () => {
  return uuidv4()
}

const metaMaskAddress = () => {
  const address = localStorage.getItem('appAuth') && JSON.parse(localStorage.getItem('appAuth')) && JSON.parse(localStorage.getItem('appAuth')).address
  return address
}

const getVirtualTour = async (isValidTour) => {
  try {
    const finalObject = {}
    const queryStringParameters = {
      tId: getDomain()
    }
    const postData = {
      body: {
        teamId: getDomain()
      }
    }
    const address = metaMaskAddress()
    if (address) {
      queryStringParameters.address = address
      postData.body.address = address
    }
    const res = await httpHelper.get(urlConstants.getVirtualTour, httpHelper.getUri(urlConstants.getVirtualTour), { queryStringParameters })
    if (isValidTour) {
      const response = await httpHelper.get(urlConstants.getVirtualTourTemplate, httpHelper.getUri(urlConstants.getVirtualTourTemplate), { queryStringParameters })
      console.log('Virtual Tour Template', response)
      finalObject.virtualTourTemplate = response && response.Item && response.Item.virtualTour ? response.Item.virtualTour : {}
    }
    if (!res.Item) {
      httpHelper.put(urlConstants.createVirtualTour, httpHelper.getUri(urlConstants.createVirtualTour), postData)
      console.log('Virtual tour created')
    }
    finalObject.virtualTour = res.Item ? res.Item : {}
    return finalObject
  } catch (err) {
    console.log('Error', err)
  }
}

const deleteProposal = async (documentId, teamId, deleteReason) => {
  const params = [
    { param: '{documentId}', value: documentId },
    { param: '{teamId}', value: teamId }
  ]
  const postData = {
    body: {}
  }
  if (deleteReason) {
    postData.body.deleteReason = deleteReason
  }
  const address = metaMaskAddress()
  if (address) {
    postData.body.address = address
  }
  const { Item } = await httpHelper.del(urlConstants.deleteProposal, httpHelper.getUri(urlConstants.deleteProposal, params), postData)
  return Item || {}
}

const updateVirtualTour = async (stateName) => {
  const params = [{ param: '{state}', value: stateName }]
  const postData = {
    body: {
      teamId: getDomain()
    }
  }
  const address = metaMaskAddress()
  if (address) {
    postData.body.address = address
  }
  try {
    const res = await httpHelper.put(urlConstants.updateVirtualTour, httpHelper.getUri(urlConstants.updateVirtualTour, params), postData)
    console.log('Virtual tour updated ', res)
    return res.Attributes
  } catch (err) {
    console.log('Error', err)
  }
}
const getDuration = (startTime, endTime) => {
  const seconds = moment(endTime - startTime)
  const minutes = moment((endTime - startTime) / (1000 * 60))
  const hours = moment((endTime - startTime) / (1000 * 60 * 60))
  const days = moment((endTime - startTime) / (1000 * 60 * 60 * 24))

  if (seconds < 60) {
    return seconds > 1 ? seconds + ' Secs' : seconds + ' Sec'
  } else if (minutes < 60) {
    return minutes > 1 ? minutes + ' Mins' : minutes + ' Min'
  } else if (hours < 24) {
    return hours > 1 ? hours + ' Hrs' : hours + ' Hr'
  } else {
    return days > 1 ? days + ' Days' : days + ' Day'
  }
}

const checkFileType = async (file, type) => {
  console.log('Check File Type', file)
  const imgExtensions = ['jpeg', 'jpg', 'png', 'gif']
  const videoExtensions = ['mp3', 'mp4', 'mov']
  const sizeLimit = type === constants.IMAGE_KEYWORD ? constants.IMAGE_SIZE_LIMIT_IN_MB : type === constants.VIDEO_KEYWORD ? constants.VIDEO_SIZE_LIMIT_IN_MB : 0
  const fileSize = file.size
  let result = false

  return new Promise((resolve) => {
    let reason = ''
    const maxPreviewSize = constants.IMAGE_VIDEO_SHOW_PREVIEW_LIMIT
    if (fileSize / 1000000 <= sizeLimit) {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = async (event) => {
        console.log(event.target.result)
        const res = await FileType.fromBuffer(event.target.result)
        if (res) {
          result = type === constants.IMAGE_KEYWORD ? imgExtensions.includes(res.ext) : type === constants.VIDEO_KEYWORD ? videoExtensions.includes(res.ext) : false
        }
        reason = fileSize / 1000000 >= maxPreviewSize ? 'PREVIEW_FAILED' : ''
        resolve({ result, reason: !result ? 'UNSUPPORTED_FORMAT' : reason })
      }
    } else {
      resolve({ result, reason: !result ? 'SIZE_LIMIT_EXCEEDED' : reason })
    }
  })
}

const checkIconFileSize = async (file) => {
  if (file.size / 1000000 <= constants.IMAGE_SIZE_LIMIT_IN_MB) {
    return { result: true }
  } else {
    return { result: false, reason: 'SIZE_LIMIT_EXCEEDED' }
  }
}

const getMomentLocaleData = (locale) => {
  return momentLocale[locale]
}

const momentLocale = {
  fr: {
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY HH:mm',
      LLLL: 'dddd D MMMM YYYY HH:mm'
    },
    calendar: {
      sameDay: '[Aujourd’hui à] LT',
      nextDay: '[Demain à] LT',
      nextWeek: 'dddd [à] LT',
      lastDay: '[Hier à] LT',
      lastWeek: 'dddd [dernier à] LT',
      sameElse: 'L'
    },
    relativeTime: {
      future: 'dans %s',
      past: 'il y a %s',
      s: 'quelques secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
      return number + (number === 1 ? 'er' : 'e')
    },
    // meridiemParse: /PD|MD/,
    // isPM: function (input) {
    //   return input.charAt(0) === 'M';
    // },
    // // In case the meridiem units are not separated around 12, then implement
    // // this function (look at locale/id.js for an example).
    // // meridiemHour : function (hour, meridiem) {
    // //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // // },
    // meridiem: function (hours, minutes, isLower) {
    //   return hours < 12 ? 'PD' : 'MD';
    // },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4 // Used to determine first week of the year.
    }
  }
}

const challengeIds = (challengeList) => {
  return challengeList.map((challenge) => challenge.challengeId)
}

const challengeCreatedByChallengeLeader = (context, challengeId) => {
  const { attributes, challenges } = context
  const isChallengeLeader = attributes ? constants.USER_TYPE.CHALLENGE_LEADER === attributes['custom:user_role'] : false
  let noChallenge
  if (isChallengeLeader && challenges) {
    noChallenge = !!challenges.map((challenge) => (challenge && challengeId && challengeId === challenge.challengeId && challenge.createdBy === attributes.email && challenge.challengeRole === constants.USER_TYPE.CHALLENGE_LEADER))
  }
  return noChallenge
}

const dataURItoBlob = (dataURI) => {
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

const translator = async (mimeType, targetLanguage, sourceLanguage, contents) => {
  try {
    // let postData = {
    //   body: {
    //     "mimeType": mimeType,
    //     "targetLanguageCode": targetLanguage,
    //     "sourceLanguageCode": sourceLanguage,
    //     "contents": contents
    //   }
    // };
    // let data = await httpHelper.post(urlConstants.translatorQuickAI, httpHelper.getUri(urlConstants.translatorQuickAI), postData, 2);
    return {
      translations: contents
    }
  } catch (e) {
    return contents
  }
}

const getUserByUserName = async (createdBy) => {
  const userParams = [
    { param: '{teamId}', value: getDomain() },
    { param: '{userName}', value: createdBy }
  ]
  const data = await httpHelper.get(urlConstants.getUserByUserName, httpHelper.getUri(urlConstants.getUserByUserName, userParams))
  return data
}

const getSearchData = async (state, from, pgsize, search, challengeIds) => {
  try {
    const params = [
      { param: '{state}', value: state },
      { param: '{from}', value: from },
      { param: '{pgsize}', value: pgsize },
      { param: '{search}', value: search },
      { param: '{challengeIds}', value: challengeIds }
    ]
    const startKey = objToParam({ tId: getDomain() })
    const { Data, Count } = await httpHelper.get(urlConstants.getChallengesSearch, httpHelper.getUri(urlConstants.getChallengesSearch + startKey, params))
    const searchData = {
      Data,
      Count,
      from: from + Data.length,
      hasMoreStatus: from + Data.length < Count
    }
    return searchData
  } catch (e) {
    if (e && e.response && e.response.status && e.response.status === httpconstants.STATUS_501 && e.response.data && e.response.data.status) {
      return {
        status: e.response.data.status,
        message: e.response.data.message
      }
    }
    await handleUnauthorizedError(e)
    throw e
  }
}
const getCont = async (type, state, isown, challengeIds) => {
  const params = [
    { param: '{type}', value: type },
    { param: '{state}', value: state },
    { param: '{isown}', value: isown || false },
    { param: '{challengeIds}', value: challengeIds }
  ]
  const queryStringParameters = {
    tId: getDomain()
  }
  const address = metaMaskAddress()
  if (address) {
    queryStringParameters.address = address
  }
  const data = await httpHelper.get(urlConstants.getCount, httpHelper.getUri(urlConstants.getCount, params), { queryStringParameters })
  return data
}

const getAccessChallenges = async (state, from, pgsize, search, reportDuration) => {
  const params = [
    { param: '{state}', value: state },
    { param: '{from}', value: from },
    { param: '{pgsize}', value: pgsize },
    { param: '{search}', value: search }
  ]
  const startKey = objToParam({ tId: getDomain(), reportDuration, address: metaMaskAddress() })
  const data = await httpHelper.get(urlConstants.getAccessChallenges, httpHelper.getUri(urlConstants.getAccessChallenges + startKey, params))
  return data
}

const getPreSignedUrl = async (object, thumbnailUrl, fileType) => {
  const params = [
    { param: '{object}', value: object },
    { param: '{thumbnailUrl}', value: thumbnailUrl },
    { param: '{fileType}', value: fileType }
  ]
  const queryStringParameters = {
    tId: getDomain()
  }
  const address = metaMaskAddress()
  if (address) {
    queryStringParameters.address = address
  }
  const data = await httpHelper.get(urlConstants.getPreSignedUrl, httpHelper.getUri(urlConstants.getPreSignedUrl, params), { queryStringParameters })
  return data
}

const updateSiteView = async (type) => {
  const params = [{ param: '{actionName}', value: 'updateSiteView' }]
  const postData = {
    body: {
      teamId: getDomain(),
      challengeId: constants.ALL_CHALLENGE_OBJECT.challengeId,
      type
    }
  }
  const res = await httpHelper.post(urlConstants.action, httpHelper.getUri(urlConstants.action, params), postData)
  return res
}

const updateRecentlyViewed = async (type, id) => {
  const params = [{ param: '{actionName}', value: 'updateRecentlyViewed' }]
  const postData = {
    body: {
      teamId: getDomain(),
      id,
      type
    }
  }
  const address = metaMaskAddress()
  if (address) {
    postData.body.address = address
  }
  const res = await httpHelper.post(urlConstants.action, httpHelper.getUri(urlConstants.action, params), postData)
  return res
}

const getProposalsCount = async (state, challengeId, isown) => {
  const params = [{ param: '{actionName}', value: 'getProposalsCount' }]
  const postData = {
    body: {
      teamId: getDomain(),
      state,
      challengeId,
      isown
    }
  }
  const address = metaMaskAddress()
  if (address) {
    postData.body.address = address
  }
  const res = await httpHelper.post(urlConstants.action, httpHelper.getUri(urlConstants.action, params), postData)
  return res
}

const getUnsplashImages = async (endpoint, params) => {
  const response = await axios({
    method: 'GET',
    url: httpHelper.getUri(endpoint, '', true, 2),
    headers: {
      Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_API_ACCESS_KEY.toString()}`
    },
    params
  })
  return response
}

const getStage = () => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL.split('/')[process.env.REACT_APP_API_URL.split('/').length - 1]
  }
}

const uploadFile = async (file, id, category, cropped) => {
  let preSignedUrlCopy
  let finalUrl
  if (file && file.type.includes('video')) {
    const fileExtension = file.name.split('.').pop()
    const object = id ? category === constants.CHALLENGE_KEYWORD ? category : lowerCase(category) : '' // should come from constants
    const thumbnailUrl = `${getDomain()}-${category === constants.CHALLENGE_KEYWORD ? 'missionvideo' : lowerCase(category) + 'Video'}.${fileExtension}`
    const fileType = file.type
    const { preSignedUrl } = await getPreSignedUrl(object, thumbnailUrl, fileType)
    preSignedUrlCopy = preSignedUrl
    await uploadImage(preSignedUrlCopy, file, fileType)
    finalUrl = preSignedUrlCopy.split('?')[0] + '?' + Date.now()
  } else if (file) {
    const fileExtension = cropped ? file.split(';')[0].replace(/^data:image\//, '') : file.name.split('.').pop()
    const object = id ? category : '' // should come from constants
    const thumbnailUrl = id + '.' + fileExtension
    const fileType = cropped ? file.split(';')[0].replace(/^data:/, '') : file.type
    const data = cropped ? dataURItoBlob(file) : file
    const { preSignedUrl } = await getPreSignedUrl(object, thumbnailUrl, fileType)
    preSignedUrlCopy = preSignedUrl
    await uploadImage(preSignedUrlCopy, data, fileType)
    finalUrl = preSignedUrlCopy.split('?')[0] + '?' + Date.now()
  }
  return finalUrl
}

const updateViewAudit = async (id, type) => {
  try {
    const params = [{ param: '{actionName}', value: 'updateViewAudit' }]
    const postData = {
      body: {
        teamId: getDomain(),
        id,
        type
      }
    }
    const address = metaMaskAddress()
    if (address) {
      postData.body.address = address
    }
    await httpHelper.post(urlConstants.action, httpHelper.getUri(urlConstants.action, params), postData)
  } catch (e) {
    console.log('error', e)
  }
}

const getUserList = async (pgSize, challengeId) => {
  const params = [{ param: '{actionName}', value: 'getUserByHandle' }]
  const postBody = {
    body: {
      teamId: getDomain(),
      from: 0,
      pgsize: pgSize,
      challengeId
    }
  }
  const address = metaMaskAddress()
  if (address) {
    postBody.body.address = address
  }
  let userlist = await httpHelper.post(urlConstants.action, httpHelper.getUri(urlConstants.action, params), postBody)
  userlist = userlist && userlist.Data && userlist.Data.length > 0
    ? userlist.Data.map((item) => {
      return {
        id: item.myHandle,
        avatar: item.profilePic || '',
        userId: item.userNameTeamId,
        name: item.firstName || item.lastName ? `${item.firstName || ''} ${item.lastName || ''}` : item.userName && item.userName.includes('@') ? item.userName.split('@')[0] : ''
      }
    })
    : []
  return userlist
}

export default {
  getHostedZone,
  getDomain,
  getTeam,
  getProposals,
  getCategoryId,
  getMyProposals,
  getHomeImage,
  getQuestionnaire,
  getUUID,
  metaMaskAddress,
  uploadImage,
  objToParam,
  getTeamUnAuth,
  signOut,
  handleUnauthorizedError,
  checkFileType,
  getVirtualTour,
  updateVirtualTour,
  deleteProposal,
  getLanguageMessages,
  getDuration,
  getMomentLocaleData,
  challengeIds,
  challengeCreatedByChallengeLeader,
  dataURItoBlob,
  checkTeamAvailabilty,
  translator,
  getUserByUserName,
  getSearchData,
  getCont,
  getAccessChallenges,
  getPreSignedUrl,
  updateSiteView,
  getProposalsCount,
  getUnsplashImages,
  getStage,
  getHostName,
  checkIconFileSize,
  updateRecentlyViewed,
  getLocationHostName,
  uploadFile,
  updateViewAudit,
  getUserList
}
