import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
import detect from 'detect.js'
import constants from './constants/constants'
import { ApolloProvider } from '@apollo/client'
import AWSAppSyncClient from 'aws-appsync'
import App from './App'
import './App.scss'
// import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from "@apollo/client";
// import { createAuthLink } from 'aws-appsync-auth-link';
// import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

// const url = process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT;
// const region = process.env.REACT_APP_APPSYNC_REGION;
// const auth = {
//     type: process.env.REACT_APP_APPSYNC_AUTHENTICATION_TYPE,
//     apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
// };

// const httpLink = createHttpLink({ uri: url })
// const link = ApolloLink.from([
//     createAuthLink({ url, region, auth }),
//     createSubscriptionHandshakeLink(url, httpLink)
// ]);

// const client = new ApolloClient({
//     cache: new InMemoryCache(),
//     link
// });
const client = new AWSAppSyncClient({
  url: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
  region: process.env.REACT_APP_APPSYNC_REGION,
  auth: {
    type: process.env.REACT_APP_APPSYNC_AUTHENTICATION_TYPE,
    apiKey: process.env.REACT_APP_APPSYNC_API_KEY
    // jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  }
})
ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (!sessionStorage.getItem('compatibleBrowserAlert')) {
  const user = detect.parse(navigator.userAgent)
  const isEdge = navigator.userAgent.indexOf('Edg') !== -1 || navigator.userAgent.indexOf('Edge') !== -1
  const browserName = user.browser.family
  const majorVersion = user.browser.version
  if (
    (browserName === 'Chrome' && (isEdge ? majorVersion < 42 : majorVersion < 67)) ||
    (browserName === 'Safari' && majorVersion < 11) ||
    (browserName === 'Firefox' && majorVersion < 65) ||
    (isEdge && majorVersion < 42) ||
    browserName === 'IE'
  ) {
    alert(constants.COMPATIBLE_BROWSER_ALERT_TEXT)
    sessionStorage.setItem('compatibleBrowserAlert', true)
  }
}

if (process.env.REACT_APP_DISABLE_LOGS) {
  console.log = function () {}
}
serviceWorker.unregister()
