const ru = {
  myFeed: 'Моя лента',
  recentlyViewed: 'Недавно просмотренные',
  searchWhatInterestsYou: 'Ищите то, что вас интересует',
  feed: 'Подача',
  missionCreator: 'Создатель миссии',
  selectAMission: 'Выберите миссию',
  newMission: 'Новая миссия',
  create: 'Создавать',
  collaborate: 'Сотрудничать',
  myActivities: 'Моя деятельность',
  howItWorks: 'Как это работает',
  following: 'Следующий',
  viewAll: 'Посмотреть все',
  support: 'Служба поддержки',
  addMission: 'Добавить миссию',
  insights: 'Инсайты',
  siteAdminSettings: 'Настройки администратора сайта',
  settings: 'Настройки',
  inviteAColleague: 'Пригласить коллегу',
  suggestMission: 'Предложить миссию',
  proposalReview: 'Проверка предложения',
  users: 'Пользователи',
  branding: 'Брендинг',
  security: 'Безопасность',
  onboarding: 'Онбординг',
  adminDashboard: 'Панель администратора',
  editHomepage: 'Изменить домашнюю страницу',
  billing: 'Выставление счетов',
  notifications: 'Уверенность',
  organizationName: 'название организации', // Branding Page
  accountUrl: 'URL-адрес учетной записи',
  logo: 'Логотип',
  siteDesign: 'Дизайн сайта',
  termsAndConditionDescription: 'Условия и положения Описание',
  termsAndConditions: 'Условия',
  type: 'Тип',
  externalLink: 'внешняя ссылка',
  editor: 'редактор',
  link: 'Ссылка на сайт',
  jpgPng2MBmax: 'JPG, PNG, не более 2 МБ',
  webAddress: 'веб-адрес',
  totalUsers: 'Всего пользователей', // User Management Page
  pendingInvites: 'Ожидающие приглашения',
  pending: 'В ожидании',
  invite: 'Приглашать',
  firstName: 'Имя',
  surname: 'Фамилия',
  status: 'Статус',
  memberSince: 'Член с',
  lastSeenLocation: 'Последнее посещение/ Место расположения',
  email: 'Электронное письмо',
  permissions: 'Разрешения',
  save: 'Сохранять',
  searchForUser: 'Поиск пользователя',
  inviteDescriptionText:
    'Пожалуйста, введите адрес электронной почты, чтобы пригласить нового пользователя. После каждого письма нажимайте клавишу Enter (или Return) на клавиатуре. Пожалуйста, добавьте столько писем, сколько хотите.',
  ByNameOrEmail: 'По имени или по электронной почте',
  resend: 'Отправить',
  home: 'Домой',
  about: 'О',
  editAbout: 'Редактировать о',
  sectionType: 'Тип раздела',
  richText: 'Богатый текст',
  updates: 'Обновления',
  start: 'Начинать',
  end: 'Конец',
  sponsors: 'Спонсоры',
  decisions: 'Решения',
  escalation: 'Эскалация',
  awards: 'Награды',
  faQs: 'часто задаваемые вопросы',
  engagement: 'помолвка',
  leaderboard: 'Таблица лидеров',
  milestones: 'Вехи',
  missions: 'Миссии',
  comments: 'Комментарии',
  proposals: 'Предложения',
  createNewProposal: 'Создать новое предложение',
  active: 'Активный',
  drafts: 'Черновик',
  draft: 'Черновик',
  underReview: 'На рассмотрении',
  archived: 'В архиве',
  declined: 'Отклоненный',
  accepted: 'Принятый',
  implemented: 'Реализовано',
  edit: 'Редактировать',
  new: 'Новый',
  trending: 'В тренде',
  buzzing: 'Жужжание',
  highlyRated: 'Высокий рейтинг',
  nearlyDecisionReady: 'Почти готово решение',
  needsSupport: 'Нуждается в поддержке',
  awarded: 'Награжден',
  endingSoon: 'Скоро заканчивается',
  missionGoals: 'Цели миссии',
  sponsorsAndStakeholders: 'Спонсоры и заинтересованные стороны',
  escalationMetrics: 'Метрики эскалации',
  missionMilestones: 'Вехи миссии',
  missionGoalsDescriptionText: 'При подаче предложений, пожалуйста, помните об этих целях Миссии. Мы стремимся',
  decisionsDescriptionText: 'на данный момент не подходит',
  declinedDescription: 'на данный момент не подходит',
  acceptedDescription: 'будут выдвинуты на реализацию',
  implementedDescription: 'будут приняты меры немедленно',
  awardsDescription: '',
  configureBranding: 'Настроить брендинг', // Onboarding page
  configureQuestionnaire: 'Настроить анкету',
  configureWorkflow: 'Настройка рабочего процесса',
  manageUsers: 'Управление пользователями',
  view: 'Вид',
  bookACall: 'ЗАКАЗАТЬ ЗВОНОК',
  introText: 'Привет, я',
  welcomeBack: 'Добро пожаловать обратно {name}', // Admin Dashboard page
  welcomeBackDescription: 'Ваш Swae гудит, есть несколько вещей, которые нужно сделать!',
  flaggedComments: 'Помеченные комментарии',
  flaggedComment: 'Помеченный комментарий',
  item: 'Пункт',
  whoAndWhen: 'Кто и когда',
  metrics: 'Метрики',
  dueDate: 'Срок',
  action: 'Действие',
  snapshot: 'Снимок',
  activeProposals: 'Активные предложения',
  avgProposalVote: 'Среднее голосование за предложение',
  loading: 'Загрузка...',
  activity: 'Мероприятия',
  siteViews: 'Просмотры сайта',
  proposalStages: 'Этапы предложения',
  getMoreInsights: 'Получите больше информации',
  ignored: 'Игнорируется',
  deleted: 'Удалено',
  missionLeader: 'Лидер миссии',
  who: 'ВОЗ',
  intervene: 'вмешиваться',
  reason: 'Причина',
  postedBy: 'Сообщение от',
  reportedBy: 'Сообщает',
  deletedBy: 'Удалено',
  ignoredBy: 'Игнорируется',
  readLess: 'Читать меньше',
  readMore: 'Читать далее',
  abidesByCodeOfConduct: 'Соблюдает Кодекс поведения',
  allowComment: 'Разрешить комментарий',
  removeComment: 'Удалить комментарий',
  ignoreComment: 'Игнорировать комментарий',
  createNewMission: 'Создать новую миссию',
  commentsViolateCommunityGuidelinesAndStandards: 'Комментарии нарушают принципы и стандарты сообщества',
  commentsAreInaccurateMisleadingOrNonFactual: 'Комментарии являются неточными, вводящими в заблуждение или не соответствуют действительности',
  commentsUseProhibitedProfanity: 'В комментариях используется запрещенная ненормативная лексика',
  commentsIntendedToSlanderOtherCommunityMembers: 'Comentarios destinados a calumniar a otros miembros de la comunidad',
  customReasonOther: 'Пользовательская причина/другое',
  reportedFor: 'Сообщается о',
  showMeMoreInsights: 'Покажи мне больше понимания',
  reviewNow: 'Обзор сейчас',
  oneMinTask: '1 мин задача',
  activeUsers: 'Активные пользователи', // Insights page
  monthlyActiveUsers: 'Ежемесячные активные пользователи',
  averageOverPeriod: 'Среднее за период',
  changeOverPeriod: 'Изменение за период',
  inactiveUsers: 'Неактивные пользователи',
  totalViews: 'Всего просмотров',
  totalEngagements: 'Всего вовлечений',
  totalSuggestions: 'Всего предложений',
  totalSuggestedEdits: 'Всего предложенных правок',
  totalComments: 'Всего комментариев',
  totalProposals: 'Всего предложений',
  engagements: 'Вовлечение',
  votes: 'Голоса',
  languages: 'Языки',
  nationality: 'Национальность',
  suggestedEdits: 'Предлагаемые изменения',
  engagementsSubtitle: 'Количество Предложений, Голосов, Замечаний и Предложений',
  proposalsSubtitle: 'Где находятся предложения на платформе',
  votesSubtitle: 'Количество голосов',
  commentsSubtitle: 'Количество комментариев',
  suggestedEditsSubtitle: 'Количество предложений',
  languagesSubtitle: 'Какие языки люди используют на платформе',
  nationalitySubtitle: 'Пользователи из какой страны',
  usersSubtitle: 'Сколько людей используют Swae {clientname}',
  hallofFame: 'зал славы',
  filterBy: 'Фильтровать по',
  overview: 'Обзор',
  downloadXLSReport: 'Скачать отчет XLS',
  addYourMissionTitleHere: 'Добавьте название вашей миссии здесь', // Mission Creation
  missionIsContinuous: 'Миссия непрерывна',
  questionnaire: 'Опросник',
  saveAsDraft: 'Сохранить как черновик',
  launchMission: 'Миссия запуска',
  updateMission: 'Обновить миссию',
  goals: 'Цели',
  enableGoals: 'Включить цели',
  goalsDescription: 'При подаче предложений для этой миссии, пожалуйста, работайте в этих целях ...',
  addGoalsHeading: 'Добавить цели к вашей миссии',
  addCustomGoals: 'Добавьте пользовательские цели',
  decisionHeaderForMissionCreation: 'Разрешить предложения по обогащению решений',
  decisionMakersQuestion: 'Кто такие лица, принимающие решения?',
  searchLeadership: 'Поиск руководства',
  howDoProposalsEscalate: 'Как вознаграждаются предложения?',
  proposalReviewProcessAndMetrics: 'Процесс обзора предложения и метрики',
  reviewProcess: 'Обзор процесса',
  reviewProcessStar: 'Обзор процесса*',
  manuallyEscalatedByLeadership: 'Вручную обострился руководством',
  sendProposalsToReviewAutomatically: 'Отправьте предложения для автоматического просмотра, на основе метрик',
  escalatedUsingMetrics: 'Обострился с использованием метрик',
  escalationCriteria: 'Критерии эскалации',
  escalationCriteriaDescription: 'Предложения, соответствующие этим критериям, будут отправлены на рассмотрение лицам, принимающим решения',
  proposalsEscalationMetrics: 'Предложения эскалации метрики',
  maximumLengthProposalCanBeActive: 'Предложение максимальной длины может быть активным',
  howLongCanProposalStayActive: 'Как долго предлагается предложение',
  minimumAverageScoreFromVotes: 'Минимальный средний балл от голосов',
  minimumNumberOfVoters: 'Минимальное количество избирателей',
  minimumNumberOfCollaborativeEdits: 'Минимальное количество совместных изменений',
  minimumNumberOfStrengths: 'Минимальное количество сильных сторон',
  minimumNumberOfConcerns: 'Минимальное количество проблем',
  minimumNumberOfQuestions: 'Минимальное количество вопросов',
  minimumNumberOfProposalViews: 'Минимальное количество представлений предложения',
  awardsHeaderDescription: 'Чтобы перезарядить свои идеи, мы даем несколько отличных наград на эту миссию ...',
  addYourCustomAwardTitle: 'Добавьте свой таможенный заголовок награды',
  addYourCustomAwardDescription: 'Добавьте еще больше деталей о вашей наградной награду здесь',
  orUploadYourOwn: 'или загрузить свой собственный',
  awardIcon: 'Икона на премии',
  awardType: 'Тип награды',
  awardGoesToPerson: 'Награда идет к человеку',
  awardGoesToProposal: 'Награда идет к предложению',
  addAward: 'Добавить награду',
  addAnotherAward: 'Добавьте еще одну награду',
  awardGoesTo: 'Награда идет',
  to: 'К',
  awardName: 'Назовите награду',
  assignAwards: 'Назначить награды',
  addFaqsHere: 'Добавить Faq здесь',
  addYourQuestionHere: 'Добавьте свой вопрос здесь',
  addYourAnswerHere: 'Добавьте свой ответ здесь',
  reorder: 'Изменение порядка',
  delete: 'Удалить',
  addAnyMissionMilestones: 'Добавьте любые миссии',
  addMissionMilestonesDescription: 'Вот основные обновления из этой миссии',
  addMilestoneHere: 'Добавьте шелую здесь',
  addUpdateHere: 'Добавить обновление здесь',
  addUpdateHereStar: 'Добавить обновление здесь*',
  addYourDetailHere: 'Добавьте свои детали здесь',
  imagesJpgPngGif: 'Изображения: JPG, PNG, GIF',
  videoMpegMov: 'Видео: MPEG, MOV',
  maxFilesize20MbMaxFilesize: '20 МБ максимальный файл',
  // Wizard Builder
  bestForSimpleMissions: 'Лучше всего для простых миссий',
  createReacherFullFeaturedMissions: 'Создать ритейль, полнофункциональные миссии',
  around3Minutes: 'Около 3 минут',
  whatOutcomeDoYouWant: 'Какой результат вы хотите ...',
  outcomeAnswer:
    'SWae можно использовать просто для мозгового штурма идей или может дать вам однократный рабочий процесс от идей до полного процесса принятия решений. При принятии принятия решений пользователи, вероятно, будут более заниматься, зная, что их идеи могут быть выполнены.',
  around5Minutes: 'Около 5 минут',
  around4Minutes30Seconds: 'Около 4 минут 30 секунд',
  optional: 'По желанию',
  recommended: 'рекомендуемые',
  letsSetUpYourMission: 'Давайте настроим свою миссию',
  collectIdeasMakeDecisions: 'Собирать идеи и принимать решения',
  justCollectIdeas: 'Просто собирать идеи',
  back: 'Назад',
  next: 'Следующий',
  cancel: 'Отмена',
  required: 'необходимые',
  titleAndDescription: 'Название Описание*',
  addMedia: 'Добавить медиа',
  missionDuration: 'Продолжительность миссии *',
  decisionMakers: 'Лица, принимающие решения',
  proposalEscalation: 'Эскалация предложения *',
  addFaQs: 'Добавить FAQ\'S.',
  inviteUsers: 'Пригласить пользователей',
  customiseQuestions: 'Настроить вопросы',
  missionTitle: 'Название миссии *',
  whatDoYouWantToSolve: 'Что вы хотите решить?',
  description: 'Описание',
  addMoreDetailsHere: 'Добавьте больше удовольствий здесь ..',
  missionMedia: 'Миссия СМИ',
  addFromLibrary: 'Добавить из библиотеки',
  poweredBy: 'Питаться от',
  findAnImage: 'Найти изображение',
  orAddYourOwn: '... или добавьте свой собственный ...',
  startAtSpecificTime: 'Начать в определенное время',
  startRightAway: 'Начать сразу',
  missionHasAClosingDate: 'Миссия имеет дату закрытия',
  proposalsEscalation: 'Эскалция предложений',
  howAreProposalsEscalated: 'Как эскалированы предложения?',
  escalatedManuallyByLeaders: 'Обострился вручную лидерами',
  whatHappensOnEscalation: 'Что происходит на эскалации?',
  bothMethods: 'Оба метода',
  proposalGoesToReview: 'Предложение идет на рассмотрение',
  proposalIsAutomaticallyAccepted: 'Предложение автоматически принято',
  whatAreYourGoalsForThisMission: 'Каковы ваши цели для этой миссии?',
  generateAHighQuantityOfProposals: 'Генерировать большое количество предложений',
  idPreferFewerHigherQualityProposals: 'Я бы предпочел меньше, более качественные предложения',
  iWantALotOfDiscussionOnProposals: 'Я хочу много обсуждений по предложениям',
  illCustomiseTheMetricsMyself: 'Я сам настроить метрики',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  addAnotherFaq: 'Ajouter une autre faq',
  access: 'Доступ',
  missionIsOpenToThePublic: 'Миссия открыта для общественности',
  onlySelectedUsers: 'Только выбранные пользователи',
  searchUsers: 'Поиск пользователей',
  inviteByEmail: 'Пригласить по электронной почте',
  searchForPeopleOrTeams: 'Поиск людей или команд',
  customiseProposalBuilder: 'Personnaliser la proposition Builder',
  defaultQuestionsAddCustomOnes: 'Вопросы по умолчанию + Добавить пользовательские',
  usingDefaultQuestions: 'Используя вопросы по умолчанию',
  around30Seconds: 'Около 30 секунд',
  launchYourMissionRightAway: 'Запустите свою миссию прямо сейчас!',
  missionTitleSubCardText: 'Короткие и разбитые названия помогают продать вашу миссию. Используйте Savocative язык и закончить вопрос, чтобы получить интерес ваших читателей ....',
  missionDurationSubCardText: 'Как долго вы хотите, чтобы ваша миссия осталась активной, чтобы принять предложения?',
  missionGoalsSubCardText: 'Цели помогают пользователям понять, что такое и недопустимо для их представлений.',
  missionGoalsSubCardDescriptionText: 'Вы можете помочь руководить предложениями к успеху, добавив хорошо считать целы.',
  proposalEscalationSubCardVideoDescription: 'Короткий клип помогает объяснить эскалацию в рабочем процессе SWAE',
  awardSubCardText: 'Поскольку краудсорсинг требует участия толпы, разъясняющие ваши цели с самого начала, все видят, какие успехи смотрят и устанавливают ожидания о процессе принятия решений.',
  inviteUsersSubCardText: 'Предложение должно соответствовать нижему показателям ниже, чтобы перейти от «активного» на «по рассмотрению».',
  inviteUsersDescription: 'Обратите внимание, что вы можете использовать некоторые или ни один из них ...',
  reviewStar: 'Рассмотрение*',
  review: 'Рассмотрение',
  typeYourTitleHere: 'Введите свой заголовок здесь', // Proposal creation page
  typeCustomTagsHere: 'Введите пользовательские теги здесь',
  addTrendingTags: 'Добавьте трендовые теги',
  anonymous: 'Аноним',
  remindMe: 'Напомни мне',
  addImageOrVideoHere: 'Добавить изображение или видео здесь',
  submit: 'Отправить',
  mission: 'Миссия',
  spellingGrammar: 'Правописание и грамматика',
  readability: 'Читаемость',
  readingTime: 'Время чтения',
  sentiment: 'Настроение',
  emotion: 'Эмоция',
  titleRecommendation: 'Рекомендация названия',
  titleRecommendationQuestion: 'Вы хотели бы, чтобы SWae рекомендую название?',
  titleRecommendationFailedText: 'Не удалось получить предложение заголовка, попробуйте еще раз с еще более текстом.',
  replaceCurrentTitleText: 'Заменить текущее название «{proposalTitle}» с',
  suggest: 'Предложить',
  search: 'Поиск',
  viewLess: 'Осматривать меньше',
  showAll: 'Показать все',
  decisionsHeaderDescription:
    'Миссии - это будущее {clientname}. Они являются отправной точкой для того, что нам нужно инновации в будущем или найти решения для проблем, которые удерживают нас назад.',
  activeMissions: 'Активные миссии',
  archivedMissions: 'Архивные миссии',
  deletedMissions: 'Удаленные миссии',
  collaborators: 'Сотрудники',
  creators: 'Создатели',
  voters: 'Избиратели',
  hi: 'Привет {name}',
  heyFirstName: 'Привет {name}!',
  welcomeTo: 'Добро пожаловать в',
  editHereToAddDescription: 'Редактировать здесь, чтобы добавить описание',
  getStarted: 'Начать',
  missionUsers: 'Пользователи миссии',
  missionViews: 'Миссия просмотров',
  missionProposals: 'Предложения миссии',
  missionVotes: 'Миссия голосов',
  awardMissionInfoDescription: 'Лучшие предложения в этой миссии будут награждены некоторыми великими стимулами.',
  milestonesMissionInfoDescription: 'Вот основные обновления из этой миссии',
  integration: 'Интеграция',
  aboutHowItWorks: 'О {teamName}',
  welcomeDialogHeading1: 'Привет {userFirstName}...',
  welcomeDialogHeading2: 'Ищете туз Swae? Мы вас прикрыли',
  welcomeDialogContent1: 'Привыкание к новым инструментам может показаться большой переменой, но мы здесь, чтобы помочь вам сделать первый маленький шаг.',
  welcomeDialogContent2: 'Выберите из нашей библиотеки демонстраций по запросу, чтобы увидеть, насколько просто использовать Swae.',
  viewKnowledgebase: 'Посмотреть базу знаний',
  seeFaqs: 'См. часто задаваемые вопросы',
  watchTutorials: 'Смотреть учебники',
  continueToSwae: 'Продолжайте движение в Свае',
  polls: 'Опросы',
  poll: 'Голосование',
  comment: 'Комментарий',
  newPoll: 'Новый опрос',
  dialogContentTextPollVoter: 'Добавьте адрес электронной почты ниже, чтобы пригласить нового избирателя к этому опросу!',
  useTemplate: '继续斯韦',
  dailyActiveUsers: 'Ежедневные активные пользователи',
  aiGuidedBuilder: 'Ай -управляемый строитель',
  diyProposalBuilder: 'DIY предложение Строитель',
  weWillDoThisStepBySTep: 'Мы сделаем это шаг за шагом',
  justGiveMeABlankPage: 'Просто дай мне пустую страницу',
  ifYouHaveGotAnIdeaAndWantSomeStructuredHelp: 'Если у вас есть идея и хотите какую -то структурированную помощь',
  bestIfYouHaveAlreadyThoughtYourProposalThrough: 'Лучше, если вы уже думали о своем предложении через',
  whatIsYourTitle: 'Какое у вас название?',
  imSwaeAI: "Я AI Swae's AI",
  imHereToHelpYouBuildYourProposal: 'Я здесь, чтобы помочь вам построить ваше предложение',
  illMakeAFewSuggestionsAlongTheWay: 'Я сделаю несколько предложений на этом пути',
  helpEngagePeopleWithAGreatImageOrVideo: 'Помогите привлечь людей с отличным изображением или видео',
  yourProposalWillBuildHere: 'Ваше предложение построит здесь',
  proposalMedia: 'Предложение СМИ',
  adminSecurityOpenSwaeText: 'Весь основной контент (миссии, предложения, опросы, корма и т. Д.) Подходит для всех в Интернете, но пользователи должны быть вписаны для взаимодействия с ними (чтобы комментировать, голосовать, редактировать и т. Д.).',
  adminSecurityOpenSwaeTextInfoText: 'Вы можете индивидуально устанавливать миссии, опросы, чтобы быть «частными», поэтому они видны только приглашенным пользователям.',
  makeMySwaePublic: 'Сделать мой Swae публичным',
  visibility: 'Видимость',
  oneClickSignOnOptions: 'Параметры входа в один щелчок',
  enableFeatures: 'Включить функции',
  enableOrDisableTheFollowingFeatures: 'Включить или отключить следующие функции',
  whichSignInOptionDoYouWantToUse: 'Какой вариант входа вы хотите использовать?',
  microsoftSignInDisclaimer: 'Microsoft Win In работает только с Active Directory, а не с личными учетными записями. Чтобы настроить вход Microsoft, войдите в SWAE в качестве администратора и следуйте инструкциям, указанным в настройках/безопасности.',
  checkTutorial: 'Проверить учебник',
  domainAllowedError: 'Введите адрес электронной почты домена',
  emailDomainError: 'Пожалуйста, введите действительный адрес электронной почты домена',
  restrictSignupAccess: 'Ограничить доступ к регистрации',
  gotThisFarAndNotSeenAnyProposalYouLike: 'Получил это далеко и не видели какого -либо предложения, которое вам нравится?',
  youNeedToCreateYourOwn: 'Вам нужно создать свой собственный ...',
  addUrl: 'Добавить URL',
  url: 'URL'
}

export default ru
