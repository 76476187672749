import { API } from 'aws-amplify'
import constants from '../constants/constants'
import httpconstants from '../constants/httpconstants'
import helper from './helper'
import { web3LoginRefresh } from '../components/auth-new/Web3Auth'
import { AwsClient } from 'aws4fetch'

const getUri = (uri, params, queryParams, type = 0) => {
  let newUri = uri

  if (params) {
    if (type === 5) {
      newUri = new URL(uri, process.env.REACT_APP_SNAPSHOT_ENDPOINT).toString()
    }
    for (const param of params) {
      newUri = newUri.replace(param.param, param.value)
    }
  }
  if (queryParams) {
    const url = new URL(
      uri,
      type === 0 ? process.env.REACT_APP_API_URL : type === 2 ? process.env.REACT_APP_UNSPLASH_API_URL : type === 4 ? process.env.REACT_APP_SLACK_ENDPOINT : process.env.REACT_APP_AI_API_URL
    )
    const oldQParams = new URLSearchParams(url.search.slice(1))
    let index = 0
    for (const [key] of oldQParams) {
      oldQParams.set(key, queryParams[index])
      index++
    }
    if (type === 4) {
      for (const [key, value] of Object.entries(queryParams)) {
        oldQParams.append(key, value)
      }
    }
    url.search = oldQParams.toString()
    newUri = url.toString()
    newUri = newUri.replace(process.env.REACT_APP_AI_API_URL, '')
  }
  return newUri
}

const put = async (preUri, uri, postBody) => {
  try {
    const response = constants.PUT_AUTHORIZED_URLS.includes(preUri) && isIamAccess()
      ? await prepareAwsClientPutApi(uri, postBody)
      : await API.put(constants.API_NAME, uri, postBody)
    return response
  } catch (e) {
    await helper.handleUnauthorizedError(e)
    throw e
  }
}

const prepareAwsClientPutApi = async (uri, postBody) => {
  let data = await awsClientPutApi(undefined, uri, postBody)
  if (data.status === httpconstants.STATUS_403) {
    const appAuth = await web3LoginRefresh()
    data = await awsClientPutApi(appAuth, uri, postBody)
  }
  return data.response
}

const awsClientPutApi = async (appAuth, uri, postBody) => {
  const { aws, request } = await getAwsClient(
    appAuth,
    `${process.env.REACT_APP_IAM_API_URL}${uri}`,
    {
      method: 'PUT',
      ...(postBody && postBody.body ? { body: JSON.stringify(postBody.body) } : {})
    })
  const responseData = await aws.fetch(request)
  const response = await responseData.json()
  return { status: responseData.status, response }
}

const del = async (preUri, uri, postBody) => {
  try {
    const response = constants.DELETE_AUTHORIZED_URLS.includes(preUri) && isIamAccess()
      ? await prepareAwsClientDelApi(uri, postBody)
      : await API.del(constants.API_NAME, uri, postBody)
    return response
  } catch (e) {
    await helper.handleUnauthorizedError(e)
    throw e
  }
}

const prepareAwsClientDelApi = async (uri, postBody) => {
  let data = await awsClientDelApi(undefined, uri, postBody)
  if (data.status === httpconstants.STATUS_403) {
    const appAuth = await web3LoginRefresh()
    data = await awsClientDelApi(appAuth, uri, postBody)
  }
  return data.response
}

const awsClientDelApi = async (appAuth, uri, postBody) => {
  const { aws, request } = await getAwsClient(
    appAuth,
    `${process.env.REACT_APP_IAM_API_URL}${uri}`,
    {
      method: 'DELETE',
      ...(postBody && postBody.body ? { body: JSON.stringify(postBody.body) } : {})
    })
  const responseData = await aws.fetch(request)
  const response = await responseData.json()
  return { status: responseData.status, response }
}

const isIamAccess = () => {
  const appAuth = (localStorage.getItem('appAuth') && JSON.parse(localStorage.getItem('appAuth'))) || {}
  return appAuth && appAuth.accessKeyId && appAuth.accessKeyId !== null && appAuth.accessKeyId !== undefined && appAuth.accessKeyId !== 'undefined' && appAuth.secretKey && appAuth.secretKey !== null && appAuth.secretKey !== undefined && appAuth.secretKey !== 'undefined' && appAuth.sessionToken && appAuth.sessionToken !== null && appAuth.sessionToken !== undefined && appAuth.sessionToken !== 'undefined'
}
const getAwsClient = async (newAppAuth, url, data) => {
  const appAuth = (localStorage.getItem('appAuth') && JSON.parse(localStorage.getItem('appAuth'))) || {}
  const accessKeyId = appAuth.accessKeyId
  const secretAccessKey = appAuth.secretKey
  const sessionToken = appAuth.sessionToken
  const aws = new AwsClient({
    accessKeyId: newAppAuth && newAppAuth.AccessKeyId ? newAppAuth.AccessKeyId : accessKeyId,
    secretAccessKey: newAppAuth && newAppAuth.SecretKey ? newAppAuth.SecretKey : secretAccessKey,
    sessionToken: newAppAuth && newAppAuth.SessionToken ? newAppAuth.SessionToken : sessionToken,
    region: process.env.REACT_APP_COGNITO_REGION,
    service: 'execute-api'
  })
  const request = await aws.sign(url, data)
  return { aws, request }
}

const post = async (preUri, uri, postBody, type = 0) => {
  // type 0 is General and 1 is AI
  try {
    const response = !type && constants.POST_AUTHORIZED_URLS.includes(preUri) && isIamAccess()
      ? await prepareAwsClientPostApi(uri, postBody)
      : await API.post(
        type === 4 ? constants.PARAPHRASING_AI_API_NAME : type === 3 ? constants.AI_READ_ABILITY_API_NAME : type === 2 ? constants.AI_API_NAME : type === 1 ? constants.AI_TITLE_SUGGESTION_API_NAME : constants.API_NAME,
        uri,
        postBody
      )
    return response
  } catch (e) {
    await helper.handleUnauthorizedError(e)
    throw e
  }
}

const prepareAwsClientPostApi = async (uri, postBody) => {
  let data = await awsClientPostApi(undefined, uri, postBody)
  if (data.status === httpconstants.STATUS_403) {
    const appAuth = await web3LoginRefresh()
    data = await awsClientPostApi(appAuth, uri, postBody)
  }
  return data.response
}

const awsClientPostApi = async (appAuth, uri, postBody) => {
  const { aws, request } = await getAwsClient(
    appAuth,
    `${process.env.REACT_APP_IAM_API_URL}${uri}`,
    {
      method: 'POST',
      ...(postBody && postBody.body ? { body: JSON.stringify(postBody.body) } : {})
    })
  const responseData = await aws.fetch(request)
  const response = await responseData.json()
  return { status: responseData.status, response }
}

const get = async (preUri, uri, init, type = 0) => {
  // type 0 is General and 1 is AI
  try {
    const response = !type && constants.GET_AUTHORIZED_URLS.includes(preUri) && isIamAccess()
      ? await prepareAwsClientGetApi(uri, init)
      : await API.get(type === 0 ? constants.API_NAME : constants.AI_API_NAME, uri, init)
    return response
  } catch (e) {
    await helper.handleUnauthorizedError(e)
    throw e
  }
}

const prepareAwsClientGetApi = async (uri, init) => {
  let data = await awsClientGetApi(undefined, uri, init)
  if (data.status === httpconstants.STATUS_403) {
    const appAuth = await web3LoginRefresh()
    data = await awsClientGetApi(appAuth, uri, init)
  }
  return data.response
}

const awsClientGetApi = async (appAuth, uri, init) => {
  let startKey
  if (init && init.queryStringParameters) {
    startKey = helper.objToParam(init.queryStringParameters)
  }
  const { aws, request } = await getAwsClient(
    appAuth,
    `${process.env.REACT_APP_IAM_API_URL}${uri}${startKey || ''}`,
    {
      method: 'GET'
    })
  const responseData = await aws.fetch(request)
  const response = await responseData.json()
  return { status: responseData.status, response }
}

export default { getUri, put, post, get, del }
