const ch = {
  myFeed: '我的饲料',
  recentlyViewed: '最近瀏覽過的',
  feed: '喂养',
  searchWhatInterestsYou: '搜索您感兴趣的内容',
  create: '创建',
  collaborate: '合作',
  selectAMission: '选择一个任务',
  newMission: '新使命',
  myActivities: '我的活动',
  howItWorks: '怎么运行的',
  following: '下列的',
  viewAll: '查看全部',
  support: '支持',
  addMission: '添加任务',
  insights: '见解',
  siteAdminSettings: '站点管理员设置',
  settings: '设置',
  inviteAColleague: '邀请同事',
  suggestMission: '建议任务',
  proposalReview: '提案审查',
  users: '用户',
  branding: '品牌推广',
  security: '安全s',
  onboarding: '入职',
  adminDashboard: '管理员仪表板',
  editHomepage: '编辑主页',
  billing: '计费',
  notifications: '通知',
  organizationName: '机构名称', // Branding Page
  accountUrl: '帐户网址',
  logo: '标识s',
  siteDesign: '网站设计',
  termsAndConditionDescription: '条款和条件说明',
  termsAndConditions: '条款和条件',
  type: '类型',
  externalLink: '外部链接',
  editor: '编辑',
  link: '关联',
  jpgPng2MBmax: 'JPG、PNG、最大 2MB',
  webAddress: '网址',
  totalUsers: '总用户', // User Management Page
  pendingInvites: '待处理的邀请',
  pending: '待办的',
  invite: '邀请',
  firstName: '名',
  surname: '姓',
  status: '地位',
  memberSince: '会员自',
  lastSeenLocation: '最后一次露面/ 地点',
  email: '电子邮件',
  permissions: '权限',
  save: '保存',
  searchForUser: '搜索用户',
  inviteDescriptionText: '请输入电子邮件以邀请新用户。在每封电子邮件之后，单击键盘上的 Enter（或 Return）键。请添加任意数量的电子邮件。',
  ByNameOrEmail: '按名称或电子邮件',
  resend: '重发',
  home: '家',
  about: '关于',
  editAbout: '编辑',
  sectionType: '截面类型',
  richText: '富文本',
  updates: '更新',
  missionCreator: '任务创造者',
  start: '开始',
  end: '结尾',
  sponsors: '赞助商',
  decisions: '决定',
  escalation: '升级',
  awards: '奖项',
  faQs: '常见问题',
  engagement: '订婚',
  leaderboard: '排行榜',
  milestones: '里程碑',
  missions: '任务',
  comments: '评论',
  proposals: '提案',
  createNewProposal: '创建新提案',
  active: '积极的',
  drafts: '草稿',
  draft: '草稿',
  underReview: '审查中',
  archived: '存档',
  declined: '拒绝',
  accepted: '公认',
  implemented: '实施的',
  edit: '编辑',
  new: '新的',
  trending: '趋势',
  buzzing: '嗡嗡声',
  highlyRated: '高度評價',
  nearlyDecisionReady: '幾乎可以做出決定',
  needsSupport: '需要支持',
  awarded: '獲獎',
  endingSoon: '即将结束',
  missionGoals: '使命目标',
  sponsorsAndStakeholders: '赞助商和利益相关者',
  escalationMetrics: '升级指标',
  missionMilestones: '使命里程碑',
  missionGoalsDescriptionText: '提交提案时，请牢记这些使命目标。我们的目标是',
  decisionsDescriptionText: '以下领导将对所有成功的提案进行审查。他们将给出结果的原因，可以是以下原因之一',
  declinedDescription: '目前不适合',
  acceptedDescription: '将提出实施',
  implementedDescription: '将立即采取行动',
  configureBranding: '配置品牌', // Onboarding Page
  configureQuestionnaire: '配置问卷',
  configureWorkflow: '配置工作流程',
  manageUsers: '管理用户',
  view: '看法',
  bookACall: '预约电话',
  introText: '你好我是',
  welcomeBack: '欢迎回来 {name}', // Admin Dashboard page
  welcomeBackDescription: '你的 Swae 嗡嗡作响，有几件事要做！',
  flaggedComments: '标记的评论',
  flaggedComment: '标记评论',
  item: '物品',
  whoAndWhen: '谁和何时',
  metrics: '指标',
  dueDate: '截止日期',
  action: '行动s',
  snapshot: '快照',
  activeProposals: '积极提案',
  avgProposalVote: '平均提案投票',
  loading: '正在加载...',
  activity: '活动',
  siteViews: '站点视图',
  proposalStages: '提案阶段',
  getMoreInsights: '获得更多见解',
  ignored: '忽略',
  deleted: '已删除',
  missionLeader: '任务负责人',
  who: '谁',
  intervene: '干预',
  reason: '原因',
  postedBy: '发表者',
  reportedBy: '报告人',
  deletedBy: '删除者',
  ignoredBy: '被忽略',
  readLess: '少读',
  readMore: '阅读更多',
  abidesByCodeOfConduct: '遵守行为准则',
  allowComment: '允许评论',
  removeComment: '删除评论',
  ignoreComment: '忽略评论',
  createNewMission: '创建新任务',
  commentsViolateCommunityGuidelinesAndStandards: '评论违反社区准则和标准',
  commentsAreInaccurateMisleadingOrNonFactual: '评论不准确、具有误导性或不真实',
  commentsUseProhibitedProfanity: '评论使用禁止的亵渎',
  commentsIntendedToSlanderOtherCommunityMembers: '旨在诽谤其他社区成员的评论',
  customReasonOther: '自定义原因/其他',
  reportedFor: '据报道',
  showMeMoreInsights: '告诉我更多的见解',
  reviewNow: '立即审查',
  oneMinTask: '1分钟任务',
  activeUsers: '活跃用户', // Insights page
  monthlyActiveUsers: '月活躍用戶',
  averageOverPeriod: '整個期間的平均值',
  changeOverPeriod: '期間變化',
  inactiveUsers: '非活动用户',
  totalViews: '总浏览',
  totalEngagements: '总参与度',
  totalSuggestions: '总建议',
  totalSuggestedEdits: '建議編輯總數',
  totalComments: '总评论',
  totalProposals: '提案总数',
  engagements: '举办',
  votes: '投票',
  languages: '语言',
  nationality: '国籍',
  suggestedEdits: '建议的编辑',
  engagementsSubtitle: '提案、投票、评论和建议的数量',
  proposalsSubtitle: '提案在平台中的位置',
  votesSubtitle: '票数',
  commentsSubtitle: '评论数量',
  suggestedEditsSubtitle: '建议数量',
  languagesSubtitle: '人们在平台上使用哪些语言',
  nationalitySubtitle: '用户来自哪个国家',
  usersSubtitle: '有多少人在使用 {clientname} 的 Swae',
  hallofFame: '名人堂',
  filterBy: '过滤',
  overview: '概述',
  downloadXLSReport: '下载XLS报告',
  addYourMissionTitleHere: '在此添加您的使命的标题', // Mission Creation
  missionIsContinuous: '使命是连续的',
  questionnaire: '调查问卷',
  saveAsDraft: '保存为草稿',
  launchMission: '启动任务',
  updateMission: '更新任务',
  goals: '目标',
  enableGoals: '启用目标',
  goalsDescription: '在提交这项任务的提案时，请在这些目标内工作......',
  addGoalsHeading: '为您的使命增加目标',
  addCustomGoals: '添加自定义目标',
  decisionHeaderForMissionCreation: '允许建议升级决定',
  decisionMakersQuestion: '谁是决策者？',
  searchLeadership: '搜索领导力',
  howDoProposalsEscalate: '提案如何升级？',
  proposalReviewProcessAndMetrics: '提案审查流程和指标',
  reviewProcess: '审查过程',
  reviewProcessStar: '审查过程*',
  manuallyEscalatedByLeadership: '由领导力手动升级',
  sendProposalsToReviewAutomatically: '根据指标，发送提案以自动审查',
  escalatedUsingMetrics: '使用指标升级',
  escalationCriteria: '升级标准',
  escalationCriteriaDescription: '符合这些标准的建议将被派往决策者审查',
  proposalsEscalationMetrics: '提案升级度量标准',
  maximumLengthProposalCanBeActive: '最大长度提案可以是活动的',
  howLongCanProposalStayActive: '建议可以保持活跃多久',
  minimumAverageScoreFromVotes: '投票的最低平均分数',
  minimumNumberOfVoters: '最少选民人数',
  minimumNumberOfCollaborativeEdits: '最小合作编辑次数',
  minimumNumberOfStrengths: '最小的优势数量',
  minimumNumberOfConcerns: '最小令人担忧',
  minimumNumberOfQuestions: '最小问题数量',
  minimumNumberOfProposalViews: '最低提案视图数量',
  awardsHeaderDescription: '为了加强您的想法，我们正在为这项任务提供一些伟大的奖项......',
  addYourCustomAwardTitle: '添加您的自定义奖项标题',
  addYourCustomAwardDescription: '在此添加有关您的自定义奖项的更多详细信息',
  orUploadYourOwn: '或上传自己的',
  awardIcon: '奖学图标',
  awardType: '奖项类型',
  awardGoesToPerson: '奖励去了人',
  awardGoesToProposal: '奖项提出提案',
  addAward: '添加奖项',
  addAnotherAward: '添加另一个奖项',
  awardGoesTo: '奖励前往',
  to: '到',
  awardName: '奖名称',
  assignAwards: '分配奖励',
  addFaqsHere: '添加常见问题解答',
  addYourQuestionHere: '在此添加您的问题',
  addYourAnswerHere: '在这里添加答案',
  reorder: '重新订购',
  delete: '删除',
  addAnyMissionMilestones: '添加任何任务里程碑',
  addMissionMilestonesDescription: '这是这个使命的主要更新',
  addMilestoneHere: '在此添加里程碑',
  addUpdateHere: '在此處添加更新',
  addUpdateHereStar: '在此處添加更新*',
  addYourDetailHere: '在此添加您的细节',
  imagesJpgPngGif: '图片：JPG，PNG，GIF',
  videoMpegMov: '视频：MPEG，MOV',
  maxFilesize20MbMaxFilesize: '20MB最大文件大小',
  // Wizard Builder
  bestForSimpleMissions: '最好的简单任务',
  createReacherFullFeaturedMissions: '创建到达，全功能任务',
  whatOutcomeDoYouWant: '你想要什么结果......',
  outcomeAnswer: 'Swae可以简单地用于头脑风暴的想法，或者可以为您提供一站式工作流程，从想法到完整的决策过程。通过启用决策，用户可能会更加订婚，了解他们的想法可能是采取的。',
  around5Minutes: '大约5分钟',
  around4Minutes30Seconds: '大约4分30秒',
  optional: '选修的',
  recommended: '受到推崇的',
  letsSetUpYourMission: '让我们设置你的使命',
  collectIdeasMakeDecisions: '收集想法和做出决定',
  justCollectIdeas: '只是收集想法',
  back: '后退',
  next: '下一个',
  cancel: '取消',
  required: '必需的',
  titleAndDescription: '标题描述*',
  addMedia: '添加媒体',
  missionDuration: '任务持续时间*',
  decisionMakers: '决定者',
  proposalEscalation: '提案升级*',
  addFaQs: '添加常见问题解答',
  inviteUsers: '邀请用户',
  customiseQuestions: '自定义问题',
  missionTitle: '使命标题*',
  whatDoYouWantToSolve: '你想解决什么？',
  description: '描述',
  addMoreDetailsHere: '在这里添加更多潜水..',
  missionMedia: '使命媒体',
  addFromLibrary: '从图书馆添加',
  poweredBy: '供电',
  findAnImage: '找到一个镜头',
  orAddYourOwn: '......或添加自己...',
  startAtSpecificTime: '从特定时间开始',
  startRightAway: '立即开始',
  missionHasAClosingDate: '使命有一个截止日期',
  proposalsEscalation: '提案升级',
  howAreProposalsEscalated: '建议如何升级？',
  escalatedManuallyByLeaders: '通过领导者手动升级',
  whatHappensOnEscalation: '升级会发生什么？',
  bothMethods: '两种方法',
  proposalGoesToReview: '提案要审查',
  proposalIsAutomaticallyAccepted: '提案自动接受',
  whatAreYourGoalsForThisMission: '你这个任务的目标是什么？',
  generateAHighQuantityOfProposals: '产生大量提案',
  idPreferFewerHigherQualityProposals: '我更喜欢更少，更高的质量提案',
  iWantALotOfDiscussionOnProposals: '我想要有很多关于提案的讨论',
  illCustomiseTheMetricsMyself: '我会自己定制度量标准',
  startDate: '开始日期',
  endDate: '结束日期',
  addAnotherFaq: '添加另一个常见问题解答',
  access: '使用权',
  missionIsOpenToThePublic: '使命对公众开放',
  onlySelectedUsers: '只有选择的用户',
  searchUsers: '搜索用户',
  inviteByEmail: '通过电子邮件邀请',
  searchForPeopleOrTeams: '寻找人或团队',
  customiseProposalBuilder: '自定义提案建设者',
  defaultQuestionsPlusAddCustomOnes: '默认问题+添加自定义',
  usingDefaultQuestions: '使用默认问题',
  around30Seconds: '大约30秒',
  launchYourMissionRightAway: '立即启动您的使命！',
  missionTitleSubCardText: '短而干净的职称有助于出售您的使命。使用令人兴奋的语言，并以一个问题结束，以获得读者的兴趣....',
  missionDurationSubCardText: '您希望使使命保持积极待接受提案多久了？',
  missionGoalsSubCardText: '目标帮助用户了解其提交的内容，也不是可接受的。',
  missionGoalsSubCardDescriptionText: '您可以通过增加考虑的目标来帮助指导成功的建议。',
  proposalEscalationSubCardVideoDescription: '短剪辑有助于解释SWAE的工作流程的升级',
  awardSubCardText: '由于众包需要人群的参与从一开始就澄清您的目标，每个人都会看到成功的看法和对决策过程的期望。',
  inviteUsersSubCardText: '提案必须符合下面的指标，以从“积极”到“正在审查”中移动。',
  inviteUsersSubCardDescription: '请注意，您可以使用一些，或者这些...',
  reviewStar: '审查*',
  review: '审查',
  typeYourTitleHere: '在此输入您的标题', // Proposal creation page
  typeCustomTagsHere: '在此处键入自定义标记',
  addTrendingTags: '添加趋势标签',
  anonymous: '匿名的',
  remindMe: '提醒我',
  addImageOrVideoHere: '在此添加图像或视频',
  submit: '提交',
  mission: '使命',
  spellingGrammar: '拼写和语法',
  readability: '可读性',
  readingTime: '阅读时间',
  sentiment: '情绪',
  emotion: '感情',
  titleRecommendation: '标题建议书',
  titleRecommendationQuestion: '你想要Swae推荐标题吗？',
  titleRecommendationFailedText: '无法获得标题建议，再次尝试更多的文字。',
  replaceCurrentTitleText: '替换当前标题“{proposalTitle}”',
  suggest: '建议',
  search: '搜索',
  viewLess: '视图少',
  showAll: '显示所有',
  decisionsHeaderDescription: '任务是{clientname}的未来。他们是我们需要创新未来的目的，或者找到解决我们背部问题的解决方案。',
  activeMissions: '积极任务',
  archivedMissions: '存档任务',
  deletedMissions: '删除任务',
  collaborators: '合作者',
  creators: '创作者',
  voters: '选民',
  hi: '嗨{name}',
  heyFirstName: '嘿{name}！',
  welcomeTo: '欢迎来到',
  editHereToAddDescription: '在此处编辑以添加描述',
  getStarted: '开始',
  missionUsers: '特派团用户',
  missionViews: '特派团观点',
  missionProposals: '特派团提案',
  missionVotes: '特派团投票',
  milestonesMissionInfoDescription: '这是这个使命的主要更新',
  awardMissionInfoDescription: '该特派团的最高提议将获得一些伟大的激励措施。',
  integration: '一体化',
  aboutHowItWorks: '关于 {teamName}',
  welcomeDialogHeading1: '嘿 {userFirstName}...',
  welcomeDialogHeading2: '想成为王牌 Swae？ 我们为您提供保障',
  welcomeDialogContent1: '习惯新工具可能会带来很大的变化，但我们在这里帮助您迈出小小的第一步。',
  welcomeDialogContent2: '从我们的按需演示库中进行选择，看看使用 Swae 是多么容易',
  viewKnowledgebase: '查看知识库',
  seeFaqs: '查看常见问题',
  watchTutorials: '观看教程',
  continueToSwae: '继续斯韦',
  polls: '民意调查',
  poll: '輪詢',
  comment: '評論',
  newPoll: '新民意调查',
  dialogContentTextPollVoter: '添加下面的電子郵件地址以邀請新選民參加此投票！',
  useTemplate: '使用模板',
  dailyActiveUsers: '每日活躍用戶',
  aiGuidedBuilder: 'AI指导建造者',
  diyProposalBuilder: 'DIY提案构建器',
  weWillDoThisStepBySTep: '我们将逐步做这个',
  justGiveMeABlankPage: '只是给我一个空白页',
  ifYouHaveGotAnIdeaAndWantSomeStructuredHelp: '如果您有一个主意并想要一些结构化的帮助',
  bestIfYouHaveAlreadyThoughtYourProposalThrough: '最好如果您已经考虑了您的建议',
  whatIsYourTitle: '你的标题是什么？',
  imSwaeAI: '我是Swae的AI',
  imHereToHelpYouBuildYourProposal: '我在这里帮助您建立建议',
  illMakeAFewSuggestionsAlongTheWay: '我将在此过程中提出一些建议',
  helpEngagePeopleWithAGreatImageOrVideo: '帮助人们使用出色的图像或视频吸引人们',
  yourProposalWillBuildHere: '您的建议将在这里建立',
  proposalMedia: '提案媒体',
  adminSecurityOpenSwaeText: '所有主要内容（任务，提案，民意调查，供稿等）均可访问Internet上的任何人，但必须签署用户与他们进行互动（以评论，投票，编辑等）。',
  adminSecurityOpenSwaeTextInfoText: '您可以单独设置任务，将民意调查为“私人”，因此仅邀请用户才能看到它们。',
  makeMySwaePublic: '公开我的天鹅',
  visibility: '能见度',
  oneClickSignOnOptions: '键登录选项',
  enableFeatures: '启用功能',
  enableOrDisableTheFollowingFeatures: '启用或禁用以下功能',
  whichSignInOptionDoYouWantToUse: '您希望使用哪个登录选项？',
  microsoftSignInDisclaimer: 'Microsoft签名仅使用Active Directory而不是个人帐户。要配置Microsoft登录，请登录SWAE作为管理员，并按照设置/安全性中指定的说明进行登录。',
  checkTutorial: '檢查教程',
  domainAllowedError: '輸入域電子郵件地址',
  emailDomainError: '請輸入有效的域電子郵件地址',
  restrictSignupAccess: '限制註冊訪問',
  gotThisFarAndNotSeenAnyProposalYouLike: '这么远，看不到您喜欢的任何建议吗？',
  youNeedToCreateYourOwn: '您需要创建自己的...',
  addUrl: '添加網址',
  url: '網址'
}

export default ch
