const ar = {
  myFeed: 'خلاصتي',
  recentlyViewed: 'شوهدت مؤخرا',
  feed: 'يطعم',
  searchWhatInterestsYou: 'ابحث عما يثير اهتماماتك',
  create: 'يخلق',
  collaborate: 'يتعاون',
  selectAMission: 'اختر مهمة',
  newMission: 'مهمة جديدة',
  myActivities: 'نشاطاتي',
  howItWorks: 'كيف تعمل',
  following: 'التالية',
  viewAll: 'مشاهدة الكل',
  support: 'يدعم',
  addMission: 'أضف المهمة',
  insights: 'أفكار',
  settings: 'إعدادات',
  inviteAColleague: 'دعوة زميل',
  siteAdminSettings: 'إعدادات إدارة الموقع',
  suggestMission: 'أقترح المهمة',
  proposalReview: 'مراجعة الاقتراح',
  users: 'المستخدمون',
  branding: 'العلامة التجارية',
  security: 'حماية',
  onboarding: 'لوحة تحكم المسؤول',
  adminDashboard: 'لوحة تحكم المسؤول',
  editHomepage: 'تحرير الصفحة الرئيسية',
  billing: 'الفواتير',
  notifications: 'إشعارات',
  organizationName: 'اسم المنظمة', // Branding Page
  accountUrl: 'عنوان URL للحساب',
  logo: 'شعار',
  siteDesign: 'تصميم الموقع',
  termsAndConditionDescription: 'وصف الشروط والأحكام',
  termsAndConditions: 'البنود و الظروف',
  type: 'نوع',
  externalLink: 'رابط خارجي',
  editor: 'محرر',
  link: 'وصلة',
  jpgPng2MBmax: 'JPG، PNG، 2MB ماكس',
  webAddress: 'عنوان صفحة انترنت',
  totalUsers: 'إجمالي المستخدمين', // User Management page
  pendingInvites: 'الدعوات المعلقة',
  pending: 'قيد الانتظار',
  invite: 'يدعو',
  firstName: 'الاسم الاول',
  surname: 'اسم العائلة',
  status: 'حالة',
  memberSince: 'عضو منذ',
  lastSeenLocation: 'اخر ظهور / موقع',
  email: 'بريد الالكتروني',
  permissions: 'أذونات',
  save: 'يحفظ',
  searchForUser: 'ابحث عن المستخدم',
  inviteDescriptionText:
    'الرجاء إدخال بريد إلكتروني لدعوة مستخدم جديد. بعد كل بريد إلكتروني ، انقر فوق مفتاح الإدخال (أو الرجوع) على لوحة المفاتيح. الرجاء إضافة العديد من رسائل البريد الإلكتروني كما يحلو لك.',
  ByNameOrEmail: 'بالاسم أو البريد الإلكتروني',
  resend: 'إعادة إرسال',
  home: 'مسكن', // Edit home page
  about: 'عن',
  editAbout: 'تحرير عن',
  sectionType: 'نوع القسم',
  richText: 'النص الغني',
  updates: 'التحديثات',
  missionCreator: 'خالق المهمة',
  start: 'يبدأ',
  end: 'نهاية',
  sponsors: 'الرعاة',
  decisions: 'قرارات',
  escalation: 'التصعيد',
  awards: 'الجوائز',
  faQs: 'الأسئلة الشائعة',
  engagement: 'الخطوبة',
  leaderboard: 'ليدربورد',
  milestones: 'معالم',
  missions: 'البعثات',
  comments: 'تعليقات',
  proposals: 'اقتراحات',
  createNewProposal: 'إنشاء اقتراح جديد',
  active: 'نشيط',
  drafts: 'المسودات',
  draft: 'مسودة',
  underReview: 'قيد المراجعة',
  archived: 'مؤرشف',
  declined: 'رفض',
  accepted: 'قبلت',
  implemented: 'منفذ',
  edit: 'تعديل',
  new: 'جديد',
  trending: 'الشائع',
  buzzing: 'الأز',
  highlyRated: 'تقييمه مرتفع',
  nearlyDecisionReady: 'القرار جاهز تقريبًا',
  needsSupport: 'يحتاج إلى دعم',
  awarded: 'منحت',
  endingSoon: 'ستنتهى قريبا',
  missionGoals: 'أهداف المهمة',
  sponsorsAndStakeholders: 'الرعاة وأصحاب المصلحة',
  escalationMetrics: 'مقاييس التصعيد',
  missionMilestones: 'معالم المهمة',
  missionGoalsDescriptionText: 'عند تقديم المقترحات ، يرجى وضع أهداف المهمة هذه في الاعتبار. نحن نهدف إلى',
  decisionsDescriptionText: 'سيتم إجراء مراجعة لجميع المقترحات الناجحة من قبل القادة أدناه. سيعطون أسباب النتيجة ، والتي يمكن أن تكون واحدة مما يلي',
  declinedDescription: 'غير مناسب حاليًا',
  acceptedDescription: 'سيطرح للتنفيذ',
  implementedDescription: 'سيتم التصرف بناءً عليه على الفور',
  awardsDescription: '',
  configureBranding: 'تكوين العلامة التجارية', // Onboarding Page
  configureQuestionnaire: 'تكوين الاستبيان',
  configureWorkflow: 'تكوين سير العمل',
  manageUsers: 'ادارة المستخدمين',
  view: 'رأي',
  bookACall: 'احجز مكالمة',
  introText: 'أهلا أنا',
  welcomeBack: 'مرحبا بعودتك {name}', // Admin Dashboard page
  welcomeBackDescription: 'Swae الخاص بك يطن ، هناك بعض الأشياء التي يجب القيام بها!',
  flaggedComments: 'التعليقات المعلمة',
  flaggedComment: 'تعليق تم وضع علامة عليه',
  item: 'العنصر',
  whoAndWhen: 'من ومتى',
  metrics: 'المقاييس',
  dueDate: 'تاريخ الاستحقاق',
  action: 'عمل',
  snapshot: 'لمحة',
  activeProposals: 'مقترحات نشطة',
  avgProposalVote: 'متوسط ​​تصويت الاقتراح',
  loading: 'جار التحميل...',
  activity: 'نشاط',
  siteViews: 'مشاهدات الموقع',
  proposalStages: 'مراحل الاقتراح',
  getMoreInsights: 'احصل على المزيد من الأفكار',
  ignored: 'تم التجاهل',
  deleted: 'تم الحذف',
  missionLeader: 'قائد المهمة',
  who: 'من',
  intervene: 'تدخل',
  reason: 'سبب',
  postedBy: 'منشور من طرف',
  reportedBy: 'تم عمل تقرير بواسطة',
  deletedBy: 'تم الحذف بواسطة',
  ignoredBy: 'تم تجاهله بواسطة',
  readLess: 'أقرأ أقل',
  readMore: 'اقرأ أكثر',
  abidesByCodeOfConduct: 'يلتزم بقواعد السلوك',
  allowComment: 'السماح بالتعليق',
  removeComment: 'إزالة التعليق',
  ignoreComment: 'تجاهل التعليق',
  createNewMission: 'إنشاء مهمة جديدة',
  commentsViolateCommunityGuidelinesAndStandards: 'التعليقات تنتهك إرشادات المجتمع والمعايير',
  commentsAreInaccurateMisleadingOrNonFactual: 'التعليقات غير دقيقة أو مضللة أو غير واقعية',
  commentsUseProhibitedProfanity: 'التعليقات تستخدم الألفاظ النابية المحظورة',
  commentsIntendedToSlanderOtherCommunityMembers: 'التعليقات تهدف إلى التشهير بأعضاء المجتمع الآخرين',
  customReasonOther: 'سبب مخصص / آخر',
  reportedFor: 'ذكرت',
  showMeMoreInsights: 'أرني المزيد من الأفكار',
  reviewNow: 'مراجعة الآن',
  oneMinTask: '1 دقيقة المهمة',
  activeUsers: 'المستخدمين النشطين', // Insights page
  monthlyActiveUsers: 'المستخدمون النشطون شهريًا',
  changeOverPeriod: 'التغيير خلال فترة',
  averageOverPeriod: 'متوسط ​​على مدى فترة',
  inactiveUsers: 'المستخدمون غير النشطين',
  totalViews: 'عدد المشاهدات',
  totalEngagements: 'إجمالي المشاركات',
  totalSuggestions: 'مجموع الاقتراحات',
  totalSuggestedEdits: 'إجمالي التعديلات المقترحة',
  totalComments: 'إجمالي التعليقات',
  totalProposals: 'إجمالي المقترحات',
  engagements: 'ارتباطات',
  votes: 'الأصوات',
  languages: 'اللغات',
  nationality: 'جنسية',
  suggestedEdits: 'التعديلات المقترحة',
  engagementsSubtitle: 'مقدار الاقتراحات والأصوات والتعليقات والاقتراحات',
  proposalsSubtitle: 'حيث توجد الاقتراحات في المنصة',
  votesSubtitle: 'مقدار الأصوات',
  commentsSubtitle: 'كمية التعليقات',
  suggestedEditsSubtitle: 'كمية الاقتراحات',
  languagesSubtitle: 'ما هي اللغات التي يستخدمها الأشخاص على المنصة',
  nationalitySubtitle: 'المستخدمين من أي دولة',
  usersSubtitle: 'كم عدد الأشخاص الذين يستخدمون Swae الخاص بـ {clientname}',
  hallofFame: 'صالة الشهرة',
  filterBy: 'مصنف بواسطة',
  overview: 'ملخص',
  downloadXLSReport: 'تحميل تقرير XLS',
  addYourMissionTitleHere: 'أضف عنوان مهمتك هنا', // Mission Creation
  missionIsContinuous: 'المهمة مستمرة',
  questionnaire: 'استبيان',
  saveAsDraft: 'حفظ كمسودة',
  launchMission: 'إطلاق مهمة',
  updateMission: 'تحديث البعثة',
  goals: 'الأهداف',
  enableGoals: 'تمكين الأهداف',
  goalsDescription: 'عند تقديم مقترحات لهذه المهمة، يرجى العمل ضمن هذه الأهداف ...',
  addGoalsHeading: 'إضافة أهداف إلى مهمتك',
  addCustomGoals: 'إضافة أهداف مخصصة',
  decisionHeaderForMissionCreation: 'السماح للمقترحات بالتصعيد للقرارات',
  decisionMakersQuestion: 'من هم صانعو القرار؟',
  searchLeadership: 'بحث القيادة',
  howDoProposalsEscalate: 'كيف تصعيد المقترحات؟',
  proposalReviewProcessAndMetrics: 'عملية مراجعة الاقتراح ومقاييس',
  reviewProcess: 'عملية الاستعراض',
  reviewProcessStar: '*عملية الاستعراض',
  manuallyEscalatedByLeadership: 'تصاعدت يدويا من القيادة',
  sendProposalsToReviewAutomatically: 'إرسال المقترحات المراجعة تلقائيا، بناء على المقاييس',
  escalatedUsingMetrics: 'تصاعدت باستخدام المقاييس',
  escalationCriteria: 'معايير التصعيد',
  escalationCriteriaDescription: 'سيتم إرسال المقترحات التي تلبي هذه المعايير للمراجعة لصناع القرار',
  proposalsEscalationMetrics: 'مقترحات تصعيد المقاييس',
  maximumLengthProposalCanBeActive: 'أقصى اقتراح الطول يمكن أن يكون نشطا',
  howLongCanProposalStayActive: 'كم من الوقت يمكن أن يقود الاقتراح نشطا',
  minimumAverageScoreFromVotes: 'الحد الأدنى متوسط ​​النتيجة من الأصوات',
  minimumNumberOfVoters: 'الحد الأدنى لعدد الناخبين',
  minimumNumberOfCollaborativeEdits: 'الحد الأدنى لعدد التعديلات التعاونية',
  minimumNumberOfStrengths: 'الحد الأدنى لعدد نقاط القوة',
  minimumNumberOfConcerns: 'الحد الأدنى لعدد المخاوف',
  minimumNumberOfQuestions: 'الحد الأدنى لعدد الأسئلة',
  minimumNumberOfProposalViews: 'الحد الأدنى لعدد وجهات النظر الاقتراح',
  awardsHeaderDescription: 'لغرار أفكارك، نحن نعطي بعض الجوائز الرائعة في هذه المهمة ...',
  addYourCustomAwardTitle: 'أضف عنوان جائزة مخصص الخاص بك',
  addYourCustomAwardDescription: 'أضف أي تفاصيل أخرى حول جائزة حسب الطلب هنا',
  orUploadYourOwn: 'أو تحميل الخاصة بك',
  awardIcon: 'أيقونة جائزة',
  awardType: 'نوع الجائزة',
  awardGoesToPerson: 'جائزة تذهب إلى شخص',
  awardGoesToProposal: 'جائزة تذهب إلى الاقتراح',
  addAward: 'إضافة جائزة',
  addAnotherAward: 'إضافة جائزة أخرى',
  awardGoesTo: 'جائزة تذهب إلى',
  to: 'ل',
  awardName: 'اسم الجائزة',
  assignAwards: 'تعيين الجوائز',
  addFaqsHere: 'إضافة أسئلة وأجوبة هنا',
  addYourQuestionHere: 'أضف سؤالك هنا',
  addYourAnswerHere: 'أضف إجابتك هنا',
  reorder: 'إعادة ترتيب',
  delete: 'حذف',
  addAnyMissionMilestones: 'إضافة أي معالم البعثة',
  addMissionMilestonesDescription: 'إليك التحديثات الرئيسية من هذه المهمة',
  addMilestoneHere: 'إضافة المعلم هنا',
  addUpdateHere: 'أضف التحديث هنا',
  addUpdateHereStar: 'أضف التحديث هنا *',
  addYourDetailHere: 'أضف التفاصيل الخاصة بك هنا',
  imagesJpgPngGif: 'الصور: JPG، PNG، GIF',
  videoMpegMov: 'الفيديو: MPEG، MOV',
  maxFilesize20MbMaxFilesize: '20 ميغا بايت كحد أقصى للملف',
  // Wizard Builder
  bestForSimpleMissions: 'الأفضل للبعثات البسيطة',
  createReacherFullFeaturedMissions: 'إنشاء الركن، البعثات كاملة الميزات',
  around3Minutes: 'حوالي 3 دقائق',
  whatOutcomeDoYouWant: 'ما النتيجة التي تريدها ...',
  outcomeAnswer:
    'يمكن استخدام Swae ببساطة لأفكار العصف الذهني أو يمكن أن تعطيك سير عمل محبط واحد من الأفكار إلى عملية صنع القرار الكامل. مع تمكين اتخاذ القرارات، من المحتمل أن يكون المستخدمون أكثر مشاركة، ومعرفة أفكارهم قد يتم تنفيذها.',
  around5Minutes: 'حوالي 5 دقائق',
  around4Minutes30Seconds: 'حوالي 4 دقائق 30 ثانية',
  optional: 'اختياري',
  recommended: 'موصى به',
  letsSetUpYourMission: 'دعونا أنشئ مهمتك',
  collectIdeasMakeDecisions: 'جمع الأفكار واتخاذ القرارات',
  justCollectIdeas: 'مجرد جمع الأفكار',
  back: 'عودة',
  next: 'التالي',
  cancel: 'يلغي',
  required: 'مطلوب',
  titleAndDescription: 'وصف العنوان*',
  addMedia: 'إضافة وسائل الإعلام',
  missionDuration: 'مدة المهمة *',
  decisionMakers: 'صناع القرار',
  proposalEscalation: 'تصعيد الاقتراح *',
  addFaQs: 'إضافة أسئلة وأجوبة',
  inviteUsers: 'دعوة المستخدمين',
  customiseQuestions: 'تخصيص الأسئلة',
  missionTitle: 'عنوان المهمة *',
  whatDoYouWantToSolve: 'ماذا تريد حلها؟',
  description: 'وصف',
  addMoreDetailsHere: 'إضافة المزيد من العجز هنا ..',
  missionMedia: 'مهمة وسائل الإعلام',
  addFromLibrary: 'أضف من المكتبة',
  poweredBy: 'مشغل بواسطة',
  findAnImage: 'العثور على صورة',
  orAddYourOwn: '... أو أضف الخاصة بك ...',
  startAtSpecificTime: 'تبدأ في وقت محدد',
  startRightAway: 'تبدأ على الفور',
  missionHasAClosingDate: 'المهمة لها تاريخ إغلاق',
  proposalsEscalation: 'الاقتراحات المتصاعدة',
  howAreProposalsEscalated: 'كيف تصاعد المقترحات؟',
  escalatedManuallyByLeaders: 'تصاعدت يدويا من قبل القادة',
  whatHappensOnEscalation: 'ماذا يحدث في التصعيد؟',
  bothMethods: 'كلتا الطريقتين',
  proposalGoesToReview: 'اقتراح يذهب إلى مراجعة',
  proposalIsAutomaticallyAccepted: 'يتم قبول الاقتراح تلقائيا',
  whatAreYourGoalsForThisMission: 'ما هي أهدافك لهذه المهمة؟',
  generateAHighQuantityOfProposals: 'توليد كمية عالية من المقترحات',
  idPreferFewerHigherQualityProposals: 'أفضل مقترحات أقل جودة',
  iWantALotOfDiscussionOnProposals: 'أريد الكثير من المناقشة على المقترحات',
  illCustomiseTheMetricsMyself: 'سوف تخصيص المقاييس بنفسي',
  startDate: 'تاريخ البدء',
  endDate: 'تاريخ البدء',
  addAnotherFaq: 'إضافة أسئلة وأجوبة أخرى',
  access: 'وصول',
  missionIsOpenToThePublic: 'المهمة مفتوحة للجمهور',
  onlySelectedUsers: 'المستخدمين المحددين فقط',
  searchUsers: 'بحث المستخدمين',
  inviteByEmail: 'ادعو بواسطة البريد الإلكتروني',
  searchForPeopleOrTeams: 'البحث عن أشخاص أو فرق',
  customiseProposalBuilder: 'تخصيص باني الاقتراح',
  defaultQuestionsAddCustomOnes: 'الأسئلة الافتراضية + إضافة مخصص منها',
  usingDefaultQuestions: 'باستخدام الأسئلة الافتراضية',
  around30Seconds: 'حوالي 30 ثانية',
  launchYourMissionRightAway: 'إطلاق مهمتك على الفور!',
  missionTitleSubCardText: 'العناوين القصيرة واللمسية تساعد على بيع مهمتك. استخدام اللغة التوجيهية ونهاية مع سؤال لاكتساب مصلحة القراء ....',
  missionDurationSubCardText: 'كم من الوقت تريد أن تظل مهمتك نشاطا لقبول المقترحات؟',
  missionGoalsSubCardText: 'الأهداف تساعد المستخدمين على فهم ما هو وغير مقبول لتقديم طلباتهم.',
  missionGoalsSubCardDescriptionText: 'يمكنك المساعدة في توجيه المقترحات نحو النجاح من خلال إضافة أهداف مدروسة.',
  proposalEscalationSubCardVideoDescription: 'مقطع قصير يساعد في شرح التصعيد في سير عمل Swae',
  awardSubCardText: 'نظرا لأن الجماعة الجماعية تتطلب مشاركة الحشود توضح أهدافك من البداية، فإن الجميع يرون أن النجاح يبدو وتعيين توقعات بشأن عملية صنع القرار.',
  inviteUsersSubCardText: 'يجب أن يلبي الاقتراح المقاييس أدناه للانتقال من "نشط" إلى "قيد المراجعة".',
  inviteUsersSubCardDescription: 'يرجى ملاحظة أنه يمكنك استخدام بعض، أو لا شيء من هذه ...',
  reviewStar: 'مراجعة*',
  review: 'مراجعة',
  typeYourTitleHere: 'اكتب العنوان الخاص بك هنا', // Proposal Creation Page
  typeCustomTagsHere: 'اكتب علامات مخصصة هنا',
  addTrendingTags: 'إضافة علامات تتجه',
  anonymous: 'مجهول',
  remindMe: 'ذكرني',
  addImageOrVideoHere: 'إضافة صورة أو فيديو هنا',
  submit: 'إرسال',
  mission: 'مهمة',
  spellingGrammar: 'قواعد التدقيق الإملائي',
  readability: 'مقروئية',
  readingTime: 'وقت القراءة',
  sentiment: 'مشاعر',
  emotion: 'المشاعر',
  titleRecommendation: 'توصية العنوان',
  titleRecommendationQuestion: 'هل تريد Swae أن توصي بلقب؟',
  titleRecommendationFailedText: 'فشل في الحصول على اقتراح العنوان، حاول مرة أخرى مع بعض النص.',
  replaceCurrentTitleText: 'استبدال العنوان الحالي "{proposalTitle}" مع',
  suggest: 'يقترح',
  search: 'بحث',
  viewLess: 'عرض أقل',
  showAll: 'عرض الكل',
  decisionsHeaderDescription: 'البعثات هي مستقبل {clientname}. إنها نقطة انطلاق لما نحتاج إلى ابتكار المستقبل، أو ابحث عن حلول للمشاكل التي تعيقنا.',
  activeMissions: 'البعثات النشطة',
  archivedMissions: 'البعثات المؤرشفة',
  deletedMissions: 'البعثات المحذوفة',
  collaborators: 'المتعاونين',
  creators: 'المبدعين',
  voters: 'الناخبين',
  hi: 'مرحبا {name}',
  heyFirstName: 'مرحبًا {name}!',
  welcomeTo: 'مرحبا بكم في',
  editHereToAddDescription: 'تحرير هنا لإضافة الوصف',
  getStarted: 'البدء',
  missionUsers: 'مستخدمي المهمة',
  missionViews: 'وجهات النظر مهمة',
  missionProposals: 'مقترحات البعثة',
  missionVotes: 'أصوات المهمة',
  milestonesMissionInfoDescription: 'إليك التحديثات الرئيسية من هذه المهمة',
  awardMissionInfoDescription: 'سيتم منح المقترحات العليا في هذه المهمة بعض الحوافز الرائعة.',
  integration: 'دمج',
  aboutHowItWorks: '{teamName} حول',
  welcomeDialogHeading1: 'مرحبًا {userFirstName}...',
  welcomeDialogHeading2: 'تبحث عن ايس سواي؟ لقد حصلت على تغطيتها',
  welcomeDialogContent1: 'قد يبدو التعود على الأدوات الجديدة بمثابة تغيير كبير ، لكننا هنا لمساعدتك في اتخاذ خطوتك الأولى الصغيرة.',
  welcomeDialogContent2: 'اختر من مكتبتنا للعروض التوضيحية عند الطلب لترى مدى سهولة استخدام Swae',
  viewKnowledgebase: 'عرض Knowledgebase',
  seeFaqs: 'انظر الأسئلة الشائعة',
  watchTutorials: 'مشاهدة البرامج التعليمية',
  continueToSwae: 'تابع إلى Swae',
  polls: 'استطلاعات الرأي',
  poll: 'تصويت',
  comment: 'تعليق',
  newPoll: 'استطلاع جديد',
  dialogContentTextPollVoter: 'أضف عنوان البريد الإلكتروني أدناه لدعوة ناخب جديد لهذا الاستطلاع!',
  useTemplate: 'استخدم القالب',
  dailyActiveUsers: 'المستخدمون النشطون يوميًا',
  aiGuidedBuilder: 'باني مرشد منظمة العفو الدولية',
  diyProposalBuilder: 'DIY اقتراح منشئ',
  weWillDoThisStepBySTep: 'سنفعل هذه الخطوة بخطوة',
  justGiveMeABlankPage: 'فقط أعطني صفحة فارغة',
  ifYouHaveGotAnIdeaAndWantSomeStructuredHelp: 'إذا كان لديك فكرة وتريد بعض المساعدة المنظمة',
  bestIfYouHaveAlreadyThoughtYourProposalThrough: 'من الأفضل إذا كنت قد فكرت بالفعل في اقتراحك من خلال',
  whatIsYourTitle: 'ما هو العنوان الخاص بك؟',
  imSwaeAI: 'أنا SWAE من الذكاء الاصطناعي',
  imHereToHelpYouBuildYourProposal: 'أنا هنا لمساعدتك في بناء اقتراحك',
  illMakeAFewSuggestionsAlongTheWay: 'سأقدم بعض الاقتراحات على طول الطريق',
  helpEngagePeopleWithAGreatImageOrVideo: 'ساعد في إشراك الأشخاص بصورة رائعة أو فيديو',
  yourProposalWillBuildHere: 'اقتراحك سيبني هنا',
  proposalMedia: 'الاقتراح وسائل الإعلام',
  adminSecurityOpenSwaeText: 'يمكن مشاهدة جميع المحتوى الرئيسي (المهام ، المقترحات ، استطلاعات الرأي ، الخلاصة ، إلخ) لأي شخص على الإنترنت ولكن يجب تسجيل المستخدمين للتفاعل معهم (للتعليق ، التصويت ، تحرير إلخ).',
  adminSecurityOpenSwaeTextInfoText: 'يمكنك تعيين المهام بشكل فردي ، واستطلاعات الرأي لتكون "خاصًا" بحيث تكون مرئية فقط للمستخدمين المدعوين.',
  makeMySwaePublic: 'اجعل بلدي swae علني',
  visibility: 'الرؤية',
  oneClickSignOnOptions: 'انقر فوق خيارات تسجيل الدخول',
  enableFeatures: 'تمكين الميزات',
  enableOrDisableTheFollowingFeatures: 'تمكين أو تعطيل الميزات التالية',
  whichSignInOptionDoYouWantToUse: 'ما هو خيار تسجيل الدخول الذي ترغب في استخدامه؟',
  microsoftSignInDisclaimer: 'يعمل تسجيل الدخول Microsoft فقط مع Active Directory وليس الحسابات الشخصية. لتكوين تسجيل الدخول Microsoft ، قم بتسجيل الدخول إلى SWAE كمسؤول واتبع الإرشادات المحددة في الإعدادات/الأمان.',
  checkTutorial: 'تحقق من البرنامج التعليمي',
  domainAllowedError: 'أدخل عنوان البريد الإلكتروني للمجال',
  emailDomainError: 'الرجاء إدخال عنوان بريد إلكتروني صالح للمجال',
  restrictSignupAccess: 'تقييد الوصول إلى التسجيل',
  gotThisFarAndNotSeenAnyProposalYouLike: 'هل حصلت على هذا بعيدًا ولم ير أي اقتراح تريده؟',
  youNeedToCreateYourOwn: 'تحتاج إلى إنشاء ...',
  addUrl: 'إضافة رابط',
  url: 'URL'
}

export default ar
