import Web3 from 'web3'
import helper from '../../shared/helper'
import httpHelper from '../../shared/httpHelper'
import urlConstants from '../../constants/urlConstants'
const web3 = new Web3(Web3.givenProvider)

export const getWindowEthereum = async () => {
  const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts'
  })
  return accounts
}

export const web3UpdateNonce = async (address) => {
  const params = [{ param: '{actionName}', value: 'web3UpdateNonce' }]
  const postData = {
    body: {
      teamId: helper.getDomain(),
      address
    }
  }
  return await httpHelper.post(urlConstants.action, httpHelper.getUri(urlConstants.action, params), postData)
}

export const getWeb3EthereumSignature = async (nonce, address) => {
  const signature = await web3.eth.personal.sign(
    web3.utils.sha3(`Welcome message, nonce: ${nonce}`),
    address
  )
  return signature
}

export const web3Login = async (nonce, address, signature) => {
  const params = [{ param: '{actionName}', value: 'web3Login' }]
  const postData = {
    body: {
      teamId: helper.getDomain(),
      nonce,
      address,
      signature
    }
  }
  const data = await httpHelper.post(urlConstants.action, httpHelper.getUri(urlConstants.action, params), postData)
  localStorage.setItem('appAuth', JSON.stringify({ address, sessionToken: data.SessionToken, accessKeyId: data.AccessKeyId, secretKey: data.SecretKey, expiration: data.Expiration }))
  return data
}

export const web3LoginRefresh = async () => {
  try {
    const address = helper.metaMaskAddress()
    const nonce = await web3UpdateNonce(address)
    const signature = await getWeb3EthereumSignature(nonce, address)
    const data = await web3Login(nonce, address, signature)
    return data
  } catch (e) {
    window.localStorage.removeItem('appAuth')
    window.localStorage.removeItem('walletconnect')
    window.location.reload()
  }
}
