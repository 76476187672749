export default {
  APP_DOMAIN_NAME: 'swae.io',
  APP_DOMAIN_NAMES: ['swae.io', 'swaedev.com', 'deepswae.com', 'deepfunding.ai'],
  DEFAULT_TEAM_ID: 'localhost',
  ET_STAGING_MAP_TO_TEAM_ID: 'etihadsso.swae.io',
  ET_STAGING: 'etihadstaging',
  DEFAULT_CHALLENGE_ID: '0',
  DONT_FETCH_CHALLENGE_ID: '-100',
  API_NAME: 'api_swae_2.0',
  AI_API_NAME: 'ai_api_swae_2.0',
  PARAPHRASING_AI_API_NAME: 'paraphrasing_ai_api_swae_2.0',
  AI_TITLE_SUGGESTION_API_NAME: 'title_suggestion_api_swae_2.0',
  AI_READ_ABILITY_API_NAME: 'ai_read_ability_api_swae_2.0',
  AI_SUMMARY_API_NAME: 'summary_api_swae_2.0',
  AI_ARTICLE_API_NAME: 'article_api_swae_2.0',
  QUESTIONNAIRE: 'questionnaire',
  WORKFLOW: 'workflow',
  DATA_FETCH_Limit_10: 10,
  DATA_FETCH_LIMIT_5: 5,
  VERSION_INCREMENT: 1,
  PUT_AUTHORIZED_URLS: ['/comment', '/updateQuestionnaire/{version}/{lang}/{challengeId}', '/updateQuestionnaireNew/{type}/{version}/{lang}/{challengeId}', '/proposal/state/{documentId}/{preState}/{postState}/{teamId}', '/proposal/update/{object}/{documentId}', '/update/users/{teamId}/{challengeId}', '/user/{teamId}', '/delete/user/{userId}', '/update/virtualTour/{state}', '/create/virtualTour', '/proposal/notification/settings/{documentId}/{type}', '/update/document', '/team/team/{teamId}/{lang}/{challengeId}', '/challenges/challenge/{action}/{challengeId}', '/challenges/update/challenge/{teamId}/{challengeId}', '/update/challenge/user/{filterBy}/{challengeId}', '/comments/flagged/{commentId}', '/comment/endorse/{documentId}/{commentId}', '/views/{state}/{proposalId}', '/volunteer/{documentId}/{teamId}', '/partialAIRatingFeedback', '/inline-comment', '/awards/{teamId}/{userName}', '/manager/delete-flagged-comments/{documentId}/{commentId}', '/inline-suggestion', '/updateNotification'],
  POST_AUTHORIZED_URLS: ['/create', '/create/proposal/builderType', '/sendEmail', '/vote'],
  GET_AUTHORIZED_URLS: ['/get/challenge/awards/{teamId}/{challengeId}', '/getQuestionnaire/{lang}/{challengeId}', '/teams/branding/{teamId}/{lang}/{challengeId}', '/challenges/faqs/{teamId}/{lang}/{challengeId}', '/getDraft/{docId}', '/users/{filterBy}/{from}/{pgsize}/{search}/{sort}/{challengeId}', '/user/list/{filterBy}/{from}/{pgsize}/{search}/{sort}/{challengeId}', '/pendingUsers/{from}/{pgsize}/{sort}', '/getPreSignedUrl/{object}/{thumbnailUrl}/{fileType}', '/getPreSignedUrl/{object}/{thumbnailUrl}/{fileType}/{extensions}', '/vote/votedProposalList', '/get/virtualTour', '/get/virtualTourTemplate', '/challenge/users/{pgsize}/{challengeId}', '/get/my/state/challenges/{teamId}/{state}', '/vote/{documentId}', '/update/reports/{teamId}/{challengeId}', '/get/reports/{teamId}/{challengeId}', '/get/active/user/reports/{teamId}/{type}/{startDate}/{endDate}', '/get/periodic/reports/{teamId}/{challengeId}/{type}/{startdate}/{endDate}', '/get/graph/reports/{teamId}/{challengeId}/{type}/{startdate}/{endDate}', '/awards/{teamId}', '/awards/assign/{teamId}/{userName}/{challengeId}', '/reports/{teamId}/{challengeId}/{startDate}/{endDate}', '/user/by/username/{teamId}/{userName}', '/comments/flagged-comments/{pgsize}/{challengeId}', '/refresh-email-templates'],
  DELETE_AUTHORIZED_URLS: ['/proposal/delete/{documentId}/{teamId}'],
  DATE_FORMAT: {
    MMM_D_COMMA_YYYY_H_COLON_MM_A_Z: 'MMM D, YYYY h:mm a z',
    HH_MM_D_MMM_YYYY_Z: 'HH:mm \' on \' dd MMM yyyy',
    HH_MM_D_MMM_YYYY_Z_12hr: 'h:mm a\' on \' dd MMM yyyy',
    D_MMM_YYYY_Z: 'dd MMM yyyy',
    D_MMM_YYYY: 'DD MMM YYYY',
    HH_MM_MMM_D_COMMA_YYYY_Z: 'HH:MM MMM D, YYYY z',
    HH_MM_MMM_D_COMMA_YYYY_Z_12HR: 'h:mm A MMM D, YYYY z',
    MMM_D_COMMA_YYYY: 'MMM D, YYYY',
    MMM_d_COMMA_yyyy: 'MMM d, yyyy',
    H_COLON_MM_A_Z: 'h:mm a z',
    H_COLON_MM_A: 'h:mm a',
    Z: 'z'
  },
  COUNTRY_NATIONALITY: [
    'Prefer to not disclose',
    'Afghanistan | 004',
    'Åland Islands | 248',
    'Albania | 008',
    'Algeria | 012',
    'American Samoa | 016',
    'Andorra | 020',
    'Angola | 024',
    'Anguilla | 660',
    'Antarctica | 010',
    'Antigua and Barbuda | 028',
    'Argentina | 032',
    'Armenia  | 051',
    'Aruba | 533',
    'Australia | 036',
    'Austria | 040',
    'Azerbaijan  | 031',
    'Bahamas  | 044',
    'Bahrain | 048',
    'Bangladesh | 050',
    'Barbados | 052',
    'Belarus | 112',
    'Belgium | 056',
    'Belize | 084',
    'Benin | 204',
    'Bermuda | 060',
    'Bhutan | 064',
    'Bolivia (Plurinational State of) | 068',
    'Bonaire, Sint Eustatius and Saba | 535',
    'Bosnia and Herzegovina | 070',
    'Botswana | 072',
    'Bouvet Island | 074',
    'Brazil | 076',
    'British Indian Ocean Territory | 086',
    'British Virgin Islands | 092',
    'Brunei Darussalam | 096',
    'Bulgaria | 100',
    'Burkina Faso | 854',
    'Burundi | 108',
    'Cabo Verde | 132',
    'Cambodia | 116',
    'Cameroon | 120',
    'Canada | 124',
    'Cayman Islands | 136',
    'Central African Republic | 140',
    'Chad | 148',
    'Chile | 152',
    'China | 156',
    'China, Hong Kong Special Administrative Region | 344',
    'China, Macao Special Administrative Region | 446',
    'Christmas Island | 162',
    'Cocos (Keeling) Islands | 166',
    'Colombia | 170',
    'Comoros | 174',
    'Congo | 178',
    'Cook Islands | 184',
    'Costa Rica | 188',
    'Côte d’Ivoire | 384',
    'Croatia | 191',
    'Cuba | 192',
    'Curaçao | 531',
    'Cyprus | 196',
    'Czechia | 203',
    'Democratic People\'s Republic of Korea | 408',
    'Democratic Republic of the Congo | 180',
    'Denmark | 208',
    'Djibouti | 262',
    'Dominica | 212',
    'Dominican Republic | 214',
    'Ecuador | 218',
    'Egypt | 818',
    'El Salvador | 222',
    'Equatorial Guinea | 226',
    'Eritrea | 232',
    'Estonia | 233',
    'Eswatini | 748',
    'Ethiopia | 231',
    'Falkland Islands (Malvinas) | 238',
    'Faroe Islands | 234',
    'Fiji | 242',
    'Finland | 246',
    'France | 250',
    'French Guiana | 254',
    'French Polynesia | 258',
    'French Southern Territories | 260',
    'Gabon | 266',
    'Gambia | 270',
    'Georgia | 268',
    'Germany | 276',
    'Ghana | 288',
    'Gibraltar | 292',
    'Greece | 300',
    'Greenland | 304',
    'Grenada | 308',
    'Guadeloupe | 312',
    'Guam | 316',
    'Guatemala | 320',
    'Guernsey | 831',
    'Guinea | 324',
    'Guinea-Bissau | 624',
    'Guyana | 328',
    'Haiti | 332',
    'Heard Island and McDonald Islands | 334',
    'Holy See | 336',
    'Honduras | 340',
    'Hungary | 348',
    'Iceland | 352',
    'India | 356',
    'Indonesia | 360',
    'Iran (Islamic Republic of) | 364',
    'Iraq | 368',
    'Ireland | 372',
    'Isle of Man | 833',
    'Israel | 376',
    'Italy | 380',
    'Jamaica | 388',
    'Japan | 392',
    'Jersey | 832',
    'Jordan | 400',
    'Kazakhstan | 398',
    'Kenya | 404',
    'Kiribati | 296',
    'Kuwait | 414',
    'Kyrgyzstan | 417',
    'Lao People\'s Democratic Republic | 418',
    'Latvia | 428',
    'Lebanon | 422',
    'Lesotho | 426',
    'Liberia | 430',
    'Libya | 434',
    'Liechtenstein | 438',
    'Lithuania | 440',
    'Luxembourg | 442',
    'Madagascar | 450',
    'Malawi | 454',
    'Malaysia | 458',
    'Maldives | 462',
    'Mali | 466',
    'Malta | 470',
    'Marshall Islands | 584',
    'Martinique | 474',
    'Mauritania | 478',
    'Mauritius | 480',
    'Mayotte | 175',
    'Mexico | 484',
    'Micronesia (Federated States of) | 583',
    'Monaco | 492',
    'Mongolia | 496',
    'Montenegro | 499',
    'Montserrat | 500',
    'Morocco | 504',
    'Mozambique | 508',
    'Myanmar | 104',
    'Namibia | 516',
    'Nauru | 520',
    'Nepal | 524',
    'Netherlands | 528',
    'New Caledonia | 540',
    'New Zealand | 554',
    'Nicaragua | 558',
    'Niger | 562',
    'Nigeria | 566',
    'Niue | 570',
    'Norfolk Island | 574',
    'North Macedonia | 807',
    'Northern Mariana Islands | 580',
    'Norway | 578',
    'Oman | 512',
    'Pakistan | 586',
    'Palau | 585',
    'Panama | 591',
    'Papua New Guinea | 598',
    'Paraguay | 600',
    'Peru | 604',
    'Philippines | 608',
    'Pitcairn | 612',
    'Poland | 616',
    'Portugal | 620',
    'Puerto Rico | 630',
    'Qatar | 634',
    'Republic of Korea | 410',
    'Republic of Moldova | 498',
    'Réunion | 638',
    'Romania | 642',
    'Russian Federation | 643',
    'Rwanda | 646',
    'Saint Barthélemy | 652',
    'Saint Helena | 654',
    'Saint Kitts and Nevis | 659',
    'Saint Lucia | 662',
    'Saint Martin (French Part) | 663',
    'Saint Pierre and Miquelon | 666',
    'Saint Vincent and the Grenadines | 670',
    'Samoa | 882',
    'San Marino | 674',
    'Sao Tome and Principe | 678',
    'Sark | 680',
    'Saudi Arabia | 682',
    'Senegal | 686',
    'Serbia | 688',
    'Seychelles | 690',
    'Sierra Leone | 694',
    'Singapore | 702',
    'Sint Maarten (Dutch part) | 534',
    'Slovakia | 703',
    'Slovenia | 705',
    'Solomon Islands | 090',
    'Somalia | 706',
    'South Africa | 710',
    'South Georgia and the South Sandwich Islands | 239',
    'South Sudan | 728',
    'Spain | 724',
    'Sri Lanka | 144',
    'State of Palestine | 275',
    'Sudan | 729',
    'Suriname | 740',
    'Svalbard and Jan Mayen Islands | 744',
    'Sweden | 752',
    'Switzerland | 756',
    'Syrian Arab Republic | 760',
    'Tajikistan | 762',
    'Thailand | 764',
    'Timor-Leste | 626',
    'Togo | 768',
    'Tokelau | 772',
    'Tonga | 776',
    'Trinidad and Tobago | 780',
    'Tunisia | 788',
    'Turkey | 792',
    'Turkmenistan | 795',
    'Turks and Caicos Islands | 796',
    'Tuvalu | 798',
    'Uganda | 800',
    'Ukraine | 804',
    'United Arab Emirates | 784',
    'United Kingdom of Great Britain and Northern Ireland | 826',
    'United Republic of Tanzania | 834',
    'United States Minor Outlying Islands | 581',
    'United States of America | 840',
    'United States Virgin Islands | 850',
    'Uruguay | 858',
    'Uzbekistan | 860',
    'Vanuatu | 548',
    'Venezuela (Bolivarian Republic of) | 862',
    'Viet Nam | 704',
    'Wallis and Futuna Islands | 876',
    'Western Sahara | 732',
    'Yemen | 887',
    'Zambia | 894',
    'Zimbabwe | 716'
  ],
  AGE_INTERVAL_SCALE: ['Prefer to not disclose', '<= 15 years old', '16 - 30 years old', '31 - 45 years old', '46 - 60 years old', '61 >'],
  GENDER: ['Prefer to not disclose', 'Female', 'Male', 'Non-Binary', 'Other'],
  SECTOR: [
    'Non-Governmental Organizations',
    'Private Sector',
    'Cities and Local Authorities',
    'Parliamentarians',
    'Workers and Trade Unions',
    'Philanthropic Organizations',
    'Private Social Impact Enterprises',
    'Universities',
    'Think Tanks',
    'Other'
  ],
  UNDER_REPRESENTED_GROUPS: [
    'No',
    'Indigenous Peoples',
    'People with Disabilities',
    'Women and Girls',
    'LGBTQ+',
    'Ethnic, Racial and Religious Minorities',
    'Children and Youth',
    'Conflict-affected, Displaced and Stateless People, incl. Refugees',
    'Trafficked Persons and Forced Labourers',
    'Rural Communities and Smallholder Farmers',
    'Faith-based Organizations',
    'Migrant and Domestic Workers',
    'Other'
  ],
  LANGUAGE: ['Prefer to not disclose', 'English', 'French', 'Russian', 'Mandarin', 'Arabic', 'Spanish'],
  PROPOSAL: ['Draft', 'Active', 'Under Review', 'Accepted', 'Rejected', 'Implemented', 'Archived', 'Deleted'],
  PROPOSALS: ['Proposals'],
  TOTAL_ENGAGEMENT: ['Engagements'],
  USER: ['Daily Active Users', 'New Users', 'Monthly Active Users', 'Site Admin', 'Total Users'],
  ENGAGEMENT: ['Missions', 'Proposals', 'Votes', 'Comments', 'Suggestions'],
  ENGAGEMENT_IN_MISSION: ['Proposals', 'Votes', 'Comments', 'Suggestions'],
  VOTE: ['Votes'],
  COMMENT: ['Comments'],
  SUGGESTION: ['Suggestions'],
  ACTIVE_USER_TYPES: ['dailyActive', 'monthlyActive'],
  PERIODIC_GRAPH_MAPPING: {
    missions: 'mission',
    proposals: 'proposal',
    votes: 'vote',
    comments: 'comment',
    suggestions: 'suggestion',
    dailyActiveUsers: 'dailyActiveUser',
    newUsers: 'newUser',
    monthlyActiveUsers: 'monthlyActiveUser',
    totalUsers: 'user',
    engagements: 'engagement',
    active: 'published',
    underReview: 'underManagementReview'
  },
  CREATE_CHALLENGE_STEPS: {
    ADD_INFO: 0,
    ADD_USERS: 1,
    UPDATE_QUESTIONNAIRE: 2,
    UPDATE_WORKFLOW: 3,
    ADD_FAQS: 4,
    REVIEW_CHALLENGE: 5
  },
  PROPOSAL_IMAGE_COLORS: ['#71FFC4', '#D571FF', '#FF7171', '#FFCA71', '#8271FF'],
  CHART_COLORS: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
  MULTIPLE_CHOICE: 'Multiple choice',
  // Proposal Question Types
  QUESTION_TYPES: [
    'Short Answer',
    'Paragraph',
    'Image',
    'Radio Button',
    'Checkboxes'
    // "Dropdown",
    // "Date", "Time",
    // "Tags"
  ],
  EMOTION_TYPES: {
    against: 'Against',
    neutral: 'Neutral',
    pro: 'Pro'
  },
  EMOTION_TYPES_VALUE: {
    against: -1,
    neutral: 0,
    pro: 1
  },
  EMOTION_TYPES_TAB_VALUE: {
    Against: 0,
    Neutral: 1,
    Pro: 2
  },
  DURATION_IN_DAYS: {
    SEVEN: 7
  },
  DOCUMENT_LEVEL_STATE_1: '1',
  QUESTION_TYPES_IMAGE: 'Image',
  DRAG_TYPES: ['move'],
  ALL_CHALLENGE_OBJECT: {
    challengeId: '1',
    title: 'All Missions',
    description: 'All Missions'
  },
  SHOW_ALL_POLL: {
    createdAt: Date.now(),
    name: 'Show All',
    id: 'all'
  },
  NEW_CHALLENGE_OBJECT: {
    title: 'New Mission',
    description: 'New Mission'
  },
  PROPOSAL_STATE: {
    CREATE: 'Create',
    DRAFT: 'Draft',
    SENT_TO_AI_REVIEW: 'Sent to AI review',
    AI_REVIEWED: 'AI reviewed',
    PUBLISHED: 'Published',
    VOTE: 'Vote',
    UNDER_MANAGEMENT_REVIEW: 'Under Management Review',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
    IMPLEMENTED: 'Implemented',
    ARCHIVED: 'Archived'
  },
  PROPOSAL_INFO_STATE_URL: {
    draft: '/new-proposal',
    published: '/collaborate',
    underManagementReview: '/graduated/under-review',
    archived: '/graduated/archived',
    rejected: '/graduated/rejected',
    accepted: '/graduated/accepted',
    implemented: '/graduated/implemented'
  },
  RECENTLY_VIEWED_URL: '#recentlyviewed',
  PROPOSAL_BUBBLE_TAGS_MAPPING: {
    new: 'New',
    trending: 'Trending',
    buzzing: 'Buzzing',
    controversial: 'Controversial',
    endingSoon: 'Ending soon',
    highlyRated: 'Highly Rated',
    nearlyDecisionReady: 'Nearly Decision Ready',
    needsSupport: 'Needs Support',
    published: 'Active',
    awarded: 'Awarded',
    underManagementReview: 'Under Review',
    accepted: 'Accepted',
    rejected: 'Declined',
    declined: 'Declined',
    implemented: 'Implemented',
    archived: 'Archived'
  },
  CHALLENGE_STATE: {
    DRAFT: 'Draft',
    CREATED: 'Created',
    ARCHIVED: 'Archived',
    DELETED: 'Deleted'
  },
  POLL_STATE: {
    DRAFT: 'Draft',
    CREATED: 'Created',
    ACTIVE: 'Active',
    CLOSED: 'Closed'
  },
  TAG_SEARCH_CATEGORY_NAME: {
    POPULAR: 'Popular',
    NEWEST: 'Newest',
    ALL: 'All'
  },
  TAG_SEARCH_CATEGORY_VALUE: {
    popular: '0',
    newest: '1',
    all: '2'
  },
  SLACK_SCOPES: {
    scope: [
      'chat:write',
      'chat:write.public',
      'im:history',
      'im:read',
      'im:write',
      'mpim:history',
      'mpim:write',
      'mpim:read',
      'team:read',
      'users:read',
      'users:read.email',
      'users.profile:read',
      'users:write',
      'channels:manage',
      'groups:write',
      'app_mentions:read',
      'channels:history',
      'groups:history'
    ],
    user_scope: ['chat:write', 'im:history', 'mpim:history', 'mpim:read', 'mpim:write', 'users.profile:read']
  },
  ALLOWED_INTEGRATIONS: {
    EMAIL: { type: 'email', title: 'Email' },
    SLACK: { type: 'slack', title: 'Slack' },
    SNAPSHOT_WORKSPACE: { type: 'snapshot', title: 'Snapshot Workspace' }
  },
  LINKS: 'links',
  UPDATES: 'updates',
  QUESTIONS: 'questions',
  TEAM_NAME: 'teamName',
  TEAM_DESCRIPTION: 'teamDescription',
  TERMS_CONDITIONS_LINK_NAME: 'termsConditionsLinkName',
  TERMS_CONDITIONS_LINK: 'termsConditionsLink',
  TERMS_AND_CONDITIONS: 'Terms and Conditions.',
  TERMS_AND_CONDITIONS_LINK: 'https://localhost/assets/html/tc.html',
  TERMS_AND_CONDITIONS_LINK_TYPE: 'link',
  TERMS_AND_CONDITIONS_EDITOR_TYPE: 'editor',
  WELCOME_VIDEO_DESCRIPTION: 'welcomeVideoDescription',
  CLICK_TEXT: 'click here and enter text',
  CLICK_AWARD: 'click here and enter award',
  CLICK_DESCRIPTION: 'click here and enter description',
  CLICK_QUESTION: 'click here and enter question',
  CLICK_UPDATES: 'click here and enter title',
  CLICK_NOTE: 'click here and enter note',
  CLICK_ANSWER: 'click here and enter answer',
  CLICK_LINK: 'click here and enter link',
  RESOURCES_NAME: 'resource name',
  FILL_ALL_FIELDS: 'Please fill out all fields',
  QUESTION: 'question',
  ANSWER: 'answer',
  TITLE: 'title',
  NOTE: 'note',
  AWARD: 'award',
  DESCRIPTION: 'description',
  LINK: 'link',
  LOGO_IMAGE: 'Logo',
  AWARD_LOGO: 'award-logo',
  PROPOSAL_FILE: 'proposal-file',
  FIRST_VERSION: 0.1,
  CHALLENGE_STATE_VALUE: {
    DRAFT: 0,
    CREATED: 1,
    ARCHIVED: 2,
    DELETED: 3
  },
  TEAM_REQUEST_VALUE: {
    PENDING: 0
  },
  TEAM_REQUEST: {
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected'
  },
  POLL_VIEW_AUDIT_TYPE: '1',
  POLL_COMMENT_TYPE: '2',
  POLL_REPLY_TYPE: '3',
  POLL_COMMENT_ENDORSE_TYPE: '4',
  POLL_COMMENT_REACTION_TYPE: '5',
  POLL_REPLY_ENDORSE_TYPE: '6',
  POLL_REPLY_REACTION_TYPE: '7',
  TEAMS_ALL: 'all',
  CHALLENGE_STATE_CREATED: 'Created',
  CHALLENGE_ALL_STATE: 'all',
  PROPOSAL_STATE_VALUE: {
    CREATE: 0,
    DRAFT: 1,
    SENT_TO_AI_REVIEW: 2,
    AI_REVIEWED: 3,
    PUBLISHED: 4,
    // VOTE: 5,
    UNDER_MANAGEMENT_REVIEW: 5,
    ACCEPTED: 6,
    REJECTED: 7,
    IMPLEMENTED: 8,
    ARCHIVED: 9
  },
  THEME_LIST: ['Orange', 'Main'],
  THEME_COLOR_MAPPING: {
    default: '#66daed',
    orange: '#e84e0f'
  },

  THEME: 'theme',
  ORANGE: 'orange',
  FIXED_CATEGORIES: [
    {
      categoryId: '0',
      name: 'Most Recent'
    },
    {
      categoryId: '7',
      name: 'Most Engagement'
    },
    {
      categoryId: '8',
      name: 'Most Suggested Edits'
    },
    {
      categoryId: '1',
      name: 'Most Feedback'
    },
    {
      categoryId: '4',
      name: 'Most Strengths'
    },
    {
      categoryId: '5',
      name: 'Most Questions'
    },
    {
      categoryId: '6',
      name: 'Most Concerns'
    },
    {
      categoryId: '2',
      name: 'Most Votes'
    },
    {
      categoryId: '9',
      name: 'Highest Average Vote'
    },
    {
      categoryId: '10',
      name: 'Lowest Average Vote'
    },
    {
      categoryId: '3',
      name: 'Most Views'
    }
    // {
    //   categoryId: '4',
    //   name: 'Most Suggestions'
    // },
  ],
  LEADER_BOARD_FILTERS: [
    {
      categoryId: '0',
      name: 'Most Active User',
      keyName: 'totalActivityCount'
    },
    {
      categoryId: '1',
      name: 'Most Proposals Created',
      keyName: 'proposalCount'
    },
    {
      categoryId: '2',
      name: 'Most Proposals Graduated',
      keyName: 'underManagementReviewCount'
    },
    {
      categoryId: '3',
      name: 'Most Comments Made',
      keyName: 'commentCount'
    },
    {
      categoryId: '4',
      name: 'Most Votes Provided',
      keyName: 'voteCount'
    },
    {
      categoryId: '5',
      name: 'Most Strengths Identified',
      keyName: 'totalPositiveCommentCount'
    },
    {
      categoryId: '6',
      name: 'Most Weaknesses Identified',
      keyName: 'totalNegativeCommentCount'
    },
    {
      categoryId: '7',
      name: 'Volunteered',
      keyName: 'volunteerCount'
    }
  ],
  DEFAULT_CATEGORY: {
    category: 'All Categories',
    fixedCategory: 'Most Recent',
    fixedCategoryId: 0
  },
  UPDATE_SIZE: {
    USER: 10
  },
  UNSPLASH_CONSTANTS: {
    MAX_NUMBER_OF_IMAGES: 30
  },
  PAGE_SIZE: {
    proposal: 10,
    poll: 15,
    pollTemplate: 10,
    proposal_50: 50,
    proposal_500: 500,
    comment_200: 200,
    team: 10,
    user: 10,
    user_500: 500,
    mission: 10,
    limit_500: 500
  },
  // User Types
  USER_TYPE: {
    USER: '0', // Regular User (currently known as User or Normal User) => Update the name of the user type, User to Regular User. Can create Teams (private group) - a Regular User who creates a Team becomes the owner of that Team + Can create a team-specific Challenge.
    MANAGEMENT_USER: '1', // Site Admin (currently known as Manager) => Update the name of the user type, Manager to Site Admin. Can assign Challenge Leader.
    AI: '2', // AI Reviewer (Swae team only) => same as now. Only Super Admin can assign AI Reviewers.
    ADMIN: '3', // Super Admin (Swae team only) => Site Admin permissions for all platforms - Can collect statistics for all platforms.
    EXPERT: '4', // Expert (Currently known as User Endorser) => Update the name of the user type, User Endorser to Expert. Remove Manager Endorser.
    DECISION_MAKER: '5', // Decision Maker => Expert permissions + Users + Reports + Proposal Review.
    CHALLENGE_LEADER: '6', // Challenge Leader => Update the name, Questionnaire to Challenge. Decision Maker permissions + Challenges (Multi-Questionnaires) + Workflows + Can assign Teams, Decision Makers and Experts to specific Challenges + Cannot have endorse ability.
    EDITOR: '7',
    ALL_EXCEPT_MANAGEMENT_USER: '-1'
  },

  TEAM_TYPE: {
    ORG: '1',
    AI: '2',
    SUPER: '3',
    CREATE: '4'
  },

  DURATION_INDEFINITELY: 'indefinitely',
  DURATION_NON_APPLICABLE: 'N/A',

  THRESHOLD_WORKFLOW: {
    AI_REVIEW: 'AI Review',
    COLLABORATION: 'Collaboration',
    VOTING: 'Voting',
    MANAGEMENT_REVIEW: 'Management Review'
  },
  THRESHOLD_WORKFLOW_MAPPING: {
    aiReviewed: 'AI Review',
    published: 'Collaboration',
    underManagement: 'Management Review'
  },

  ALLOWED_LOGIN: [
    {
      title: 'Only allow Metamask',
      enabled: false,
      type: 'onlyAllowMetamask'
    },
    {
      title: 'Wallet Connect',
      enabled: false,
      type: 'WalletConnect'
    },
    {
      title: 'Metamask',
      enabled: false,
      type: 'Metamask'
    },
    {
      title: 'Google',
      enabled: false,
      type: 'Google'
    },
    {
      title: 'Microsoft',
      enabled: false,
      type: 'microsoftOpenId'
    },
    {
      title: 'Azure AD',
      enabled: false,
      type: 'azureAd'
    }
  ],
  AZURE_AD: 'azureAd',
  AZURE_AD_SORT_FROM: 'AD',

  ROLE_FOR_ID: {
    0: 'Regular User',
    1: 'Site Admin',
    2: 'AI',
    3: 'Admin',
    4: 'Expert',
    5: 'Decision Maker',
    6: 'Challenge Leader',
    7: 'Editor'
  },

  ROLE_MAPPING: {
    Editor: 'Leader'
  },
  USER_ROLES_INFO: {
    'Regular User': 'This is the regular role. Create proposals and collaborate with others.',
    Editor: 'Advanced leadership role. Can also create and edit missions.',
    'Site Admin': 'Full access role. Get access to environment settings and billing.'
  },

  USER_ROLES_FOR_MANAGER: ['Regular User', 'Site Admin', 'Expert', 'Challenge Leader'],
  USER_ROLES_FOR_CHALLENGE_LEADER: ['Regular User', 'Expert'],
  // Un comment 2 lines below and comment 2 lines above to enable role 'Decision Maker'
  // USER_ROLES_FOR_MANAGER: ["Regular User", "Site Admin", "Expert", "Decision Maker", "Challenge Leader"],
  // USER_ROLES_FOR_CHALLENGE_LEADER: ["Regular User", "Expert", "Decision Maker"],
  USER_ROLES_FOR_ADMIN: ['Regular User', 'AI'],
  USER_ROLES_FOR_SITE_ADMIN: ['Regular User', 'Site Admin', 'Editor'],
  USER_MANAGER: 'Manager',
  ID_FOR_ROLE: {
    'Regular User': '0',
    'Site Admin': '1',
    AI: '2',
    Admin: '3',
    Expert: '4',
    'Decision Maker': '5',
    'Challenge Leader': '6',
    Editor: '7'
  },

  // all proposal
  ALL_PROPOSAL_DEFAULT_VALUE: {
    STATE: undefined,
    SORT: 0, // any number value
    CATEGORY: 'all',
    FROM: 0,
    IS_OWN: false
  },
  ALL_USER_DEFAULT_VALUE: {
    FILTER_BY: 'all',
    FROM: 0,
    SORT: 'userName.keyword:asc'
  },
  // my proposal
  MY_PROPOSAL_DEFAULT_VALUE: {
    STATE: 1, // any integer value
    SORT: 0, // any number value
    CATEGORY: 'all',
    FROM: 0,
    PG_SIZE: 500,
    IS_OWN: true
  },

  // my book mark
  MY_BOOK_MARK_DEFAULT_VALUE: {
    STATE: 1, // any integer value
    SORT: 0, // any number value
    CATEGORY: 'all',
    FROM: 0,
    PG_SIZE: 500,
    IS_OWN: false
  },

  QUESTIONNAIRE_PAGINATION_VALUE: {
    FROM: 0,
    PG_SIZE: 10
  },

  MISSION_VERSIONS: {
    VERSION_1: 1
  },

  INLINE_SUGGESTIONS_STATES: {
    open: '0',
    accepted: '1',
    rejected: '-1'
  },

  ACTION: {
    SIGN_IN: 'Sign in',
    REGISTER: 'Register',
    FORGET_PASSWORD: 'forgetPassword',
    LOGOUT: 'logout',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    EDIT: 'edit',
    CHANGE: 'change',
    RETRY: 'retry',
    ACCEPT: 'accept',
    GENERATE: 'generate',
    SAVE: 'save',
    TOTAL_ENDORSE_UP_COUNT: 'totalEndorseUpCount',
    TOTAL_ENDORSE_DOWN_COUNT: 'totalEndorseDOWNCount',
    REFRESH_ET: 'refreshEmailTemplates',
    ADD_REACTION_TO_COMMENT: 'addReactionToComment',
    ADD_COMMENT: 'addComment',
    ADD_VOTE: 'addVote',
    ADD_REACTION: 'addReaction',
    ADD_REPLY: 'addReply',
    CREATE_REPLY: 'createReply',
    GET_REPLIES: 'getReplies',
    UPDATE_REPLY: 'updateReply',
    STATE_UPDATE: 'stateUpdate',
    NEXT: 'next',
    BACK: 'back',
    CLOSE: 'close',
    DONE: 'done'
  },

  UP_ENDORSE: 'UpEndorse',
  DOWN_ENDORSE: 'DownEndorse',

  TEAM_UPDATE_ACTION: {
    WORKFLOW: 0,
    BRANDING: 1,
    CHALLENGE: 2,
    REQUEST: 3,
    EDIT_HOME_PAGE: 4,
    EDIT_DEFAULT_MISSION: 5,
    CUSTOMIZE_ORGANISTAION: 6,
    SET_ROLES: 7,
    CONFIGURE_NOTIFICATION: 8,
    SECURITY: 9,
    SLACK_INTEGRATION: 10,
    SLACK_DEACTIVATION: 11,
    SNAPSHOT_INTEGRATION: 12,
    SNAPSHOT_DEACTIVATION: 13
  },

  THRESHOLD: 'Threshold',
  WORKFLOW_DESCRIPTION: 'Workflow Description',

  Home_CARDS: [
    // {
    //   position: '1',
    //   title: 'My Dashboard',
    //   description: 'Manage proposals and stats',
    //   imageName: 'my-dashboard.jpg',
    //   link: '/my-proposals'
    // },
    {
      position: '2',
      title: 'New Proposal',
      description: 'Start a new proposal',
      imageName: 'new-proposal.png',
      link: '/new-proposal',
      titleId: 'newProposal',
      descId: 'startANewProposal'
    },
    {
      position: '3',
      title: 'Collaborate',
      description: 'Find new proposals and collaborate',
      imageName: 'collaborate.jpg',
      link: '/collaborate',
      titleId: 'collaborateText',
      descId: 'collabDesc'
    }
    // {
    //   position: '4',
    //   title: 'Vote',
    //   description: 'Help find our next big success',
    //   imageName: 'vote.jpg',
    //   link: '/vote',
    //   titleId: 'voteTitle',
    //   descId: 'voteDesc'
    // },
  ],
  NOTIFICATION_TYPE_YOUR_PROPOSAL: 'your',
  NOTIFICATION_TYPE_OTHERS_PROPOSAL: 'others',
  NOTIFICATION_TYPE_ALL_PROPOSAL: 'all',

  FREQ_ASK_QUESTION: 'FreqAskQuestion',
  AWARDS: 'awards',

  SUCCESS_MESSAGE: {
    TYPE: 'success',
    SAVE_DRAFT: 'Saved draft successfully',
    PUBLISH_PROPOSAL: 'Published proposal successfully',
    SEND_TO_AI_REVIEW: 'Sent proposal to AI Review successfully',
    UPDATE_USER_ROLE: 'Updated user role successfully',
    UPDATE_USER_PERMISSION: 'Updated user permission successfully',
    INVITE_USER: 'Invited user successfully',
    UPDATE_DOCUMENT: 'Updated proposal successfully',
    UPDATE_PROPOSAL_STATE: 'Updated proposal state successfully',
    UPDATE_VOLUNTEER: 'Updated volunteer successfully',
    UPDATE_ASSIGN_AWARDS: 'Assigned awards successfully',
    INVITE_TO_COLLABORATE: 'Invite email sent successfully',
    WORKFLOW: 'Updated workflow successfully',
    UPDATE_REPORTS: 'Updated reports successfully',
    CREATE_CHALLENGE: 'Created challenge successfully',
    UPDATE_CHALLENGE: 'Updated challenge successfully',
    SAVE_DRAFT_CHALLENGE: 'Saved draft successfully',
    UPDATE_EXPIRY_CHALLENGE: 'Update challenge expiry successfully',
    BRANDING: 'Updated branding successfully',
    SECURITY: 'Updated security successfully',
    INVITE_EMAIL: 'Invite email sent successfully',
    SUBMIT_VOTE: 'Your vote is submitted',
    UPDATE_NOTIFICATION: 'Notification settings updated successfully',
    PARTIAL_AI_FEEDBACK: 'Partial AI feedback submitted successfully',
    WAIT_SUGGESTION: 'Getting suggestions please wait',
    BOOK_MARKED_PROPOSAL: 'Bookmarked proposal successfully',
    UN_DONE_BOOK_MARK_PROPOSAL: 'Undone bookmark successfully',
    DELETE_USER: 'Deleted user successfully',
    DELETE_PROPOSAL: 'Deleted proposal successfully',
    DELETE_CHALLENGE: 'Deleted challenge successfully',
    FLAG_COMMENT_SUBMIT: 'Flagged feedback successfully',
    ACCEPT_PROPOSAL: 'Accepted proposal successfully',
    REJECT_PROPOSAL: 'Declined proposal successfully',
    ACCEPT_TEAM: 'Accepted team request successfully',
    REJECT_TEAM: 'Declined team request successfully',
    DELETE_FLAGGED_COMMENT: 'Deleted flagged feedback successfully',
    ALLOW_FLAGGED_COMMENT: 'Allowed flagged feedback successfully',
    UPDATE_SUMMARY: 'Updated summary successfully',
    UPDATE_IMAGE: 'Updated proposal image successfully',
    UPDATE_MEDIA: 'Updated media successfully',
    FETCHING_QUESTIONNAIRE: 'Fetching Questionnaire',
    UPDATE_MY_PROFILE: 'Updated your profile successfully',
    UPDATE_RESET_ONBOARDING_TUTORIAL: 'Reset Onboarding Tutorial successfully',
    UPDATE_MY_ANSWER: 'Updated your answer successfully',
    REFRESH_ET_INITED: 'Initiated refreshing email templates',
    REFRESH_ET_SUCCESS: 'Successfully refreshed email templates',
    UPDATE_UPDATES: 'Updated updates section successfully',
    SUGGEST_MISSION_EMAIL: 'Your suggestion sent successfully',
    EDIT_HOME_PAGE: 'Updated home page successfully',
    UPDATE_MY_REPLY: 'Updated your reply successfully',
    DELETE_MY_REPLY: 'Deleted your reply successfully',
    SLACK_INTEGRATION_SUCCESS: 'Slack is integrated successfully',
    SNAPSHOT_INTEGRATION_SUCCESS: 'Snapshot is integrated successfully',
    LAUNCH_TEMPLATE_SUCCESS: 'Missions launched successfully',
    UPDATE_BOOKMARK: 'Update Bookmark successfully',
    ADD_NEW_POLL_OPTION: 'New option added successfully',
    EXPORTED_TO_SNAPSHOT: 'Proposal exported to Snapshot successfully'
  },
  THEMES: ['Our Future', 'Our World', 'Our Society', 'Our United Nations'],

  FROM_DATE_ID: 0,
  TO_DATE_ID: 1,
  CONTRIBUTION_UNIQUE_COUNT: 'ContributionUniqueCount',
  TOTAL_VOTE_COUNT: 'totalVoteCount',
  REPORTS_INTERVAL: ['From Launch Date', 'Last 7 days', 'Last 15 days', 'Last 30 days', 'Custom date picker'],
  FROM_LAUNCH_DATE: 'From Launch Date',
  CUSTOM_DATE_PICKER_INTERVAL: 'Custom date picker',
  GRAPH_REPORTS_INTERVAL: ['Last 7 days', 'Last 15 days', 'Last 30 days', 'Last 90 days', 'Custom date picker'],
  GRAPH_USERS_REPORTS_INTERVAL: ['Last 7 days', 'Last 15 days', 'Last 30 days', 'Custom date picker'],
  CUSTOM_DATE_PICKER: 'Custom date picker',

  PROPOSAL_WORKFLOW_MAPPING: {
    aiReview: 'AI Review',
    collaboration: 'Collaboration',
    voting: 'Voting',
    managementReview: 'Management Review'
  },

  PROPOSAL_WORKFLOW_STATE_COUNT_MAPPING: {
    'AI Review': 'sentToAiReviewStateCount',
    Collaboration: 'publishedStateCount',
    Voting: 'voteStateCount',
    'Management Review': 'underManagementReviewStateCount'
  },

  PROPOSAL_WORKFLOW_LINK_MAPPING: {
    Collaboration: '/collaborate',
    Voting: '/vote',
    'Management Review': '/management/proposal-review'
  },

  PROPOSAL_WORKFLOW_STATE_MAPPING: {
    aiReview: 'Sent to AI review',
    collaboration: 'Published',
    voting: 'Vote',
    managementReview: 'Under Management Review'
  },

  EMOTION_TYPES_MAPPING: {
    against: 'totalNegativeCommentCount',
    neutral: 'totalNeutralCommentCount',
    pro: 'totalPositiveCommentCount'
  },

  PROPOSAL_STATE_COUNT_MAPPING: {
    createdStateCount: 'Created',
    draftStateCount: 'Draft',
    sentToAiReviewStateCount: 'Sent to AI review',
    aiReviewedStateCount: 'AI reviewed',
    publishedStateCount: 'Published',
    // voteStateCount: "Vote",
    underManagementReviewStateCount: 'Under Management Review',
    acceptedStateCount: 'Accepted',
    rejectedStateCount: 'Declined',
    implementedStateCount: 'Implemented',
    archivedStateCount: 'Archived'
  },
  PROPOSAL_STATE_COUNT_MAPPING_FOR_DISABLED_AI_REVIEW: {
    createdStateCount: 'Created',
    draftStateCount: 'Draft',
    publishedStateCount: 'Published',
    // voteStateCount: "Vote",
    underManagementReviewStateCount: 'Under Management Review',
    acceptedStateCount: 'Accepted',
    rejectedStateCount: 'Declined',
    implementedStateCount: 'Implemented',
    archivedStateCount: 'Archived'
  },

  PROPOSAL_TIME_SPENT_MAPPING: {
    averageSpentTimeCreate: 'Average time spent creating proposals',
    averageSpentTimeReview: 'Average time spent in AI review'
  },
  PROPOSAL_TIME_SPENT_MAPPING_FOR_DISABLED_AI_REVIEW: {
    averageSpentTimeCreate: 'Average time spent creating proposals'
  },

  USERS_MAPPING: {
    userCount: 'Total Users',
    contributionCount: 'Contributors (Proposal, Feedback, Vote)'
  },

  ON_BOARDING_List: [
    {
      position: '1',
      title: 'resources',
      link: '/management/branding'
    },
    {
      position: '2',
      title: 'questionnaire',
      link: '/challenge/all-challenges/edit/'
    },
    {
      position: '3',
      title: 'workflow',
      link: '/challenge/all-challenges/edit/'
    },
    {
      position: '4',
      title: 'setPermissions',
      link: '/management/user-management'
    }
    // {
    //   position: '5',
    //   title: "createWorkSpace",
    //   checked: true
    // },
    // {
    //   position: '6',
    //   title: "profileInfo",
    // },
    // {
    //   position: '7',
    //   title: "emailTemplate",
    // },
    // {
    //   position: '8',
    //   title: "teamDescription",
    //   link: '/management/branding'
    // }
  ],

  TOTALS_MAPPING: {
    createdStateCount: 'Proposals',
    commentCount: 'Feedback',
    voteCount: 'Votes',
    volunteerCount: 'Volunteers',
    aiSuggestionCount: 'AI Suggestion'
  },
  ACTIVE_USERS_MAPPING: {
    dailyActiveUsers: 'Daily Active Users',
    monthlyActiveUsers: 'Monthly Active Users'
  },
  AVERAGES_MAPPING: {
    averageProposal: 'Proposals',
    averageComment: 'Feedback',
    averageVote: 'Votes',
    averageVolunteer: 'Volunteers'
  },
  TOTALS_MAPPING_FOR_DISABLED_AI_REVIEW: {
    createdStateCount: 'Proposals',
    commentCount: 'Feedback',
    voteCount: 'Votes',
    volunteerCount: 'Volunteers'
  },
  TOTALS_MAPPING_EXCLUDING_VOLUNTEEER_FEATURE: {
    createdStateCount: 'Proposals',
    commentCount: 'Feedback',
    voteCount: 'Votes'
  },

  ERROR_MESSAGE: {
    TYPE: 'error',
    INVALID_EMAIL: 'Please enter a valid email address.',
    SIGN_OUT: 'Failed to sign out. Please retry later',
    SAVE_DRAFT: 'Failed to save draft',
    // SAVE_DRAFT_MINIMUM_REQUIREMENT: 'Please fill at least Title and Description to save proposal as draft!',
    PUBLISH_PROPOSAL: 'Failed to publish proposal',
    SEND_TO_AI_REVIEW: 'Failed to send proposal to AI Review',
    GET_ON_BOARDING: 'Failed to get Onboarding',
    UPDATE_USER_ROLE: 'Failed to update user role',
    UPDATE_USER_PERMISSION: 'Failed to update user permission',
    INVITE_USER: 'Failed to Invite user',
    ALL_FILL_REQUIRED_FIELDS: 'Please fill out all required fields.',
    UPDATE_DOCUMENT: 'Failed to update proposal',
    UPDATE_PROPOSAL_STATE: 'Failed to update proposal state',
    RESOLVE_PROPOSAL_SUGGESTIONS: 'Please resolve all suggestions and comments before publishing.',
    UPDATE_DOCUMENT_COLLABORATE: 'Failed to update proposal. Your recent comment might have been lost. Please refresh the page to verify.',
    INVITE_TO_COLLABORATE: 'Failed to send invite email',
    POST_REPLY: 'Failed to post reply',
    CREATE_WORK_SPACE: 'Failed to create workspace.',
    GET_WORK_SPACE: 'Failed to get workspace',
    GET_LEADERBOARD: 'Failed to get leaderboard',
    GET_POLL: 'Failed to get poll',
    GET_PROPOSAL_BUILDER: 'Failed to get proposal builder',
    PLEASE_SELECT_CHALLENGE: 'Please select challenge',
    PLEASE_SELECT_AWARDS: 'Please select awards',
    THERE_ARE_NO_CHANGES_TO_SAVE: 'There are no changes to save',
    UPDATE_WORKFLOW: 'Failed to update workflow',
    UPDATE_REPORTS: 'Failed to update reports',
    CREATE_CHALLENGE: 'Failed to create mission',
    UPDATE_CHALLENGE: 'Failed to update mission',
    SAVE_DRAFT_CHALLENGE: 'Failed to save draft',
    UPDATE_EXPIRY_CHALLENGE: 'Failed to Update challenge expiry',
    START_DATE_END_DATE_WORKFLOW: 'Please select Date and Time greater than Start Date',
    UPDATE_BRANDING: 'Failed to update branding',
    UPDATE_SECURITY: 'Failed to update security',
    UPDATE_VOLUNTEER: 'Failed to update volunteer',
    UPDATE_ASSIGN_AWARDS: 'Failed to assign awards',
    INVITE_EMAIL: 'Failed to send invite email',
    UPDATE_NOTIFICATION: 'Failed to update notification settings',
    SELECT_REPORTS: 'Select any reports',
    FAILED_TO_GET_REPORTS: 'Failed to get reports',
    GET_USER: 'Failed to get user',
    NO_DATA_FOUND: 'No data found!',
    SUBMIT_VOTE: 'Failed to submit vote',
    BOOK_MARK_PROPOSAL: 'Failed to bookmark proposal',
    BOOK_MARK_MISSION: 'Failed to bookmark mission',
    UN_DO_BOOK_MARK_PROPOSAL: 'Failed to undo bookmark',
    DELETE_USER: 'Failed to delete user',
    DELETE_PROPOSAL: 'Failed to delete proposal',
    DELETE_CHALLENGE: 'Failed to delete challenge',
    ACCEPT_PROPOSAL: 'Failed to accept proposal',
    REJECT_PROPOSAL: 'Failed to decline proposal',
    ACCEPT_TEAM: 'Failed to accept team request',
    REJECT_TEAM: 'Failed to decline team request',
    PARTIAL_AI_FEEDBACK: 'Failed to submit for AI feedback',
    FAILED_SUGGESTIONS: 'AI could not generate the summary of your proposal due to insufficient data',
    EDIT_NOT_ALLOWED: 'Please accept or reject all suggestions before editing the document.',
    DELETE_FLAGGED_COMMENT: 'Failed to delete flagged feedback',
    ALLOW_FLAGGED_COMMENT: 'Failed to ignore flagged feedback',
    EMPTY_COMMENT: 'Please add your feedback first',
    COMMENT_UNAVAILABLE: 'Feedback not available',
    UNSUPPORTED_FORMAT: 'Failed to upload file due to unsupported format',
    SIZE_LIMIT_EXCEEDED: 'Failed to upload file due to exceeding size limit',
    UPDATE_IMAGE: 'Failed to update proposal image',
    UPDATE_MEDIA: 'Failed to update media',
    UPDATE_FILE: 'Failed to upload file',
    ANSWER_AT_LEAST_ONE_QUESTION: 'Please answer at least one question',
    EDIT_TO_SAVE: 'Please edit something to save',
    POST_FEEDBACK: 'Failed to post feedback',
    ANSWER_CURRENT: 'Please answer current section last question to be able to navigate to next question',
    PREVIEW_FAILED: 'Failed to preview due to large file size, You can still continue to upload by clicking on Save',
    RADIO_OPTION_NO: 'Your answer is "No". Unfortunately, you won\'t be able to move forward.',
    SIGN_UP_FOR_INVITE: 'Sorry, You are not invited to sign up for this platform.',
    SET_VALID_TIME_INTERVALS: 'Please set valid time interval for all the phases',
    UPDATE_MY_PROFILE: 'Failed to update your profile',
    UPDATE_RESET_ONBOARDING_TUTORIAL: 'Failed to Reset Onboarding Tutorial',
    CHOOSE_AN_OPTION: 'Please choose an option to continue',
    UPDATE_MY_ANSWER: 'Failed to update your answer',
    TYPE_TO_SAVE: 'Please type something to save',
    REFRESH_ET_FAILED: 'Failed to refresh email templates',
    ENTER_VALID_HEX_CODE: 'Please enter a valid hex color code',
    DELETE_DEFAULT_CHALLENGE: 'You cannot delete this challenge as your team members already submitted their proposals to this challenge',
    CHALLENGE_WORKFLOW_TYPE_EDIT_NOT_ALLOWED: 'You cannot edit workflow type of this challenge as your team members already submitted their proposals to this challenge',
    SUGGEST_MISSION_EMAIL: 'Failed to send your suggestion',
    UPDATE_UPDATES: 'Failed to update updates section',
    EDIT_HOME_PAGE: 'Failed to update home page',
    UPDATE_MY_REPLY: 'Failed to update your reply',
    ADD_COMMENT: 'Failed to add comment',
    ADD_REPLY: 'Failed to add reply',
    GET_COMMENT: 'Failed to get comment',
    GET_REPLY: 'Failed to get reply',
    UPDATE_MY_COMMENT: 'Failed to update your comment',
    DELETE_MY_REPLY: 'Failed to delete your reply',
    DELETE_COMMENT: 'Failed to delete your comment',
    LOAD_PROPOSAL: 'Failed to load proposals',
    LOAD_MISSIONS: 'Failed to load missions',
    UNSPLASH_ERROR: 'Failed to get unsplash images',
    GET_QUESTIONNAIRE_ERROR: 'Failed to get questionnaire',
    UPDATE_QUESTIONNAIRE: 'Failed to update questionnaire',
    PROPOSAL_TITLE_SUGGESTION: 'Failed to suggest a title',
    ADD_COMMENT_REACTION: 'Failed to react for this comment',
    ADD_REPLY_REACTION: 'Failed to react for this reply',
    UPLOAD_IMAGE: 'Failed to upload image',
    COMMENT_REACTED_USERLIST: 'Failed to get reacted users list',
    SLACK_CREDENTIAL_ERROR: 'Please provide all slack credentials',
    FAILED_TO_UPDATE_TEAM: 'Failed to update team',
    SLACK_INTEGRATION_FAILED: 'Failed to integrate slack!',
    SNAPSHOT_INTEGRATION_FAILED: 'Failed to integrate Snapshot!',
    SLACK_WORKSPACE_CONNECTION_ERROR: 'Your email is not connected to team workspace!',
    FAILED_TO_GET_INTEGRATION_SETTINGS: 'Failed to get integration settings',
    CUSTOMER_BILLING_PORTAL: 'Failed to create customer billing portal session',
    UPDATE_SEATS: 'Failed to update seats',
    AWARDS_TITLE_FIELD_ERROR: 'Please fill the title field for awards',
    MISSION_TEMPLATE_CATEGORY_ERROR: 'Failed to load categories',
    ADD_TEMPLATE_ERROR: 'Please add templates',
    LOAD_TEMPLATE_ERROR: 'Failed to load template',
    LAUNCH_TEMPLATE_ERROR: 'Failed to launch templates',
    WORKSPACE_ALREADY_CONNECTED_TRY_DIFFERENT_WORKSPACE: 'This slack workspace is already connected. Try connecting to another workspace!',
    UPDATE_USER_CHECKLIST: 'Failed to update user checklist',
    GET_POLL_TEMPLATE_CATEGORIES: 'Failed to get poll template categories',
    GET_POLL_TEMPLATES: 'Failed to get poll templates',
    CREATE_POLL: 'Failed to create poll',
    ADD_ATLEAST_TWO_OPTIONS: 'Add atleast two options to your poll',
    FILL_THE_EMPTY_OPTION: 'Please fill the empty option or delete it manually',
    ADD_NEW_POLL_OPTION: 'Failed to add new option',
    UPLOAD_MEDIA: 'Failed to upload media',
    FAILED_TO_EXPORT_SNAPSHOT_PROPOSAL_TITLE_IS_MISSING: 'Failed to export in Snapshot. Proposal title is missing.',
    FAILED_TO_EXPORT_SNAPSHOT_MISSION_IS_CONTINUOUS: 'Failed to export in Snapshot. Mission is continuous.',
    EXPORTED_TO_SNAPSHOT: 'Failed to export in Snapshot.',
    FAILED_TO_EXPORT_SNAPSHOT_YOU_NEED_TO_BE_AN_AUTHOR: 'Failed to export in Snapshot. You need to be an author of the space in order to submit a proposal.',
    FAILED_TO_GET_SNAPSHOT_SPACES: 'Failed to get Snapshot spaces.',
    GET_VOTES: "Failed to get Votes!",
    GET_SNAPSHOT_PROPOSAL:"Failed to fetch Snapshot Proposal Information!"
  },

  WARNING_MESSAGE: {
    TYPE: 'warning',
    LOSS_OF_EDITS_AI_SUMMARY: 'Your edits in AI Summary will be lost on leaving this page',
    UPDATE_USER_ROLE: 'Please at time allowed 10 records to update',
    LOSS_OF_EDITS_QUESTIONNAIRE: 'It looks like you have been editing something.\nIf you leave before saving, your changes will be lost.',
    ONE_STEP_CLOSER_FOR_CREATION: 'You are one step closer for creating a challenge. Kindly go through the process to continue.\n\nClick on "Cancel" to stay on this page.',
    WARNING_BEFORE_LAUNCH_MISSION: 'Check users and customise questionnaire before launching a mission'
  },

  CATEGORY_NAME: {
    GCEO_CHALLENGE: 'GCEO Challenge',
    OTHER: 'Other'
  },
  CATEGORY_ENUM_VALUE: {
    GCEO_CATEGORY: 1000,
    DEFAULT: 0
  },
  TITLE_MAX_LENGTH: 126,
  TITLE_MAX_LENGTH_25: 25,
  TITLE_DESCRIPTION_NOT_FOUND: 'NOT_FOUND',
  FLAGGED_COMMENTS_LIMIT_20: 20,

  // Used to specify attributes returned from api GET /inline-comments...
  INLINE_COMMENT_ATTRIBUTES: {
    ALL: 0,
    USERS_ONLY: 1
  },
  REGEX_FOR_EMAIL_VALIDATION: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  REGEX_FOR_DOMAIN_VALIDATION: /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
  REGEX_FOR_HEX_CODE_VALIDATION: /^#([0-9A-F]{3}){1,2}$/i,
  REGEX_FOR_MATCHING_HTML_P_TAG: /<p(|\s+[^>]*)>(.*?)<\/p\s*>/g,
  REGEX_FOR_REPLACING_HTML_P_TAG: /<\/?p>/g,
  REGEX_FOR_REMOVING_SPACES_IN_STRING: / +/g,
  REGEX_FOR_REMOVING_SPECIAL_CHARACTERS_IN_STRING: /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi,
  INVITED_TO_COLLABORATE: 'invitedToCollaborate',
  TEAM_INVITED: 'teamInvited',
  INVITED_TO_VOTE: 'invitedToVote',
  INVITED_TO_POLL_VOTE: 'invitedToPollVote',
  INVITED_TO_MANAGEMENT_USER: 'teamInvitedAsManagementUser',
  EVENT_INVITE_MANAGER: 'invitedManagementUser',
  HASH_EDIT_KEYWORD: 'edit',
  HASH_VOTE_KEYWORD: 'vote',
  HASH_STRENGTHS_KEYWORD: 'strengths',
  HASH_QUESTIONS_KEYWORD: 'questions',
  HASH_METRICS_KEYWORD: 'metrics',
  HASH_WEAKNESSES_KEYWORD: 'weaknesses',
  HASH_INVITE_COLLABORATE_KEYWORD: 'inviteToCollaborate',
  HASH_INVITE_A_COLLEAGUE: '#invite',
  HASH_PLANS: '#plans',

  ESCALATED_LIST: {
    BY_LEADERS: 'Escalated manually by leaders',
    BY_BOTH_METHODS: 'Both methods'
  },
  REMAINING_CRITERIA_APPEND_TEXT: {
    'Average Score': '% more average score required',
    'Minimum number of votes': ' more votes required',
    'Total number of comments': ' more feedback required',
    'Total number of suggestions': 'more collaborative edits required',
    'Total number of Pro comments': ' more strengths required',
    'Total number of Nor comments': ' more Questions required',
    'Total number of Con comments': ' more weakness required',
    'Total number of Proposal Views': ' more views required',
    'Total number of volunteers': ' more volunteers required'
  },
  CUSTOMIZED_ESCALATION_THRESHOLD_WORDINGS: {
    'Average Score': 'Minimum Average Score from Votes',
    'Minimum number of votes': 'Minimum Number of Voters',
    'Total number of comments': 'Minimum Number of Feedback',
    'Total number of suggestions': 'Minimum Number of Collaborative Edits',
    'Total number of Pro comments': 'Minimum Number of Strengths',
    'Total number of Nor comments': 'Minimum Number of Questions',
    'Total number of Con comments': 'Minimum Number of Concerns',
    'Total number of Proposal Views': 'Minimum Number of Proposal Views',
    'Total number of volunteers': 'Minimum Number of Volunteers'
  },
  NEW_PROPOSALS_ESCALATION_METRICS: {
    'Maximum length Proposal': 'How long can proposal stay active',
    'Average Score': 'Minimum Average Score from Votes',
    'Minimum number of votes': 'Minimum Number of Voters',
    'Total number of suggestions': 'Minimum Number of Collaborative Edits',
    'Total number of Pro comments': 'Minimum Number of Strengths',
    'Total number of Con comments': 'Minimum Number of Concerns',
    'Total number of Nor comments': 'Minimum Number of Questions',
    'Total number of Proposal Views': 'Minimum Number of Proposal Views'
  },
  PARTIAL_AI_RATING_QUESTIONS: [
    { rating_position: '1', rating_question: 'How was your experience with the AI review?', rating_answer: '0', rating_type: 'Star Rating' },
    { rating_position: '2', rating_question: 'Rate the quality of your AI’s Suggestions:', rating_answer: '0', rating_type: 'Star Rating' },
    { rating_position: '3', rating_question: 'What did you like most about this experience?', rating_answer: '', rating_type: 'Text Box' },
    { rating_position: '4', rating_question: 'What did you like least about this experience?', rating_answer: '', rating_type: 'Text Box' },
    { rating_position: '5', rating_question: 'What is missing?', rating_answer: '', rating_type: 'Text Box' }
  ],
  PROPOSAL_STATE_ROUTER_NAME: {
    Published: 'collaborate',
    Vote: 'vote',
    Accepted: 'graduated/accepted',
    Rejected: 'graduated/rejected',
    Archived: 'graduated/archived',
    'Under Management Review': 'management/proposal-review'
  },
  IMAGE_SIZE_LIMIT_IN_MB: 5,
  VIDEO_SIZE_LIMIT_IN_MB: 150,
  IMAGE_VIDEO_SHOW_PREVIEW_LIMIT: 15,
  USER_SIZE_LIMIT: 1000,
  IMAGE_KEYWORD: 'image',
  VIDEO_KEYWORD: 'video',

  NO_SUMMARY: 'No Summary received from AI engine.',
  AVOID_VIRTUAL_TOUR_FOR_TEAM_ID: ['swaepentesting.swae.io', 'wethepeoples.swae.io'],
  SEARCH_LOCATION_URL: [
    '/home',
    '/my-activities',
    '/my-bookmarks',
    '/collaborate',
    '/vote',
    '/management/proposal-review',
    '/graduated/under-review',
    '/graduated/rejected',
    '/graduated/accepted',
    '/graduated/archived',
    '/graduated/implemented',
    '/management/user-management',
    '/team/request'
  ],
  SEARCH_USER_URL: ['/management/user-management', '/challenge/create-challenge', '/challenge/my-challenges/edit', '/challenge/all-challenges/edit'],
  MENU_HOTSPOT: {
    missions: 'missions',
    proposals: 'proposals',
    profileNotifications: 'profileNotificationstTpRightCornerNav',
    support: 'support',
    bubblesFilter: 'bubblesFilter',
    bookmark: 'bookmark',
    missionInfoRightTabs: 'missionInfoRightTabs',
    missionInfoWorkflowTabs: 'missionInfoWorkflowTabs',
    missionInfoActive: 'missionInfoActive',
    missionInfoUnderReview: 'missionInfoUnderReview',
    missionInfoDeclined: 'missionInfoDeclined',
    missionInfoAccepted: 'missionInfoAccepted',
    missionInfoImplemented: 'missionInfoImplemented',
    // proposalInfoOnRightSideOfPageNextToProposal: 'proposalInfoOnRightSideOfPageNextToProposal',
    proposalInfoDebateSection: 'proposalInfoDebateSection',
    proposalInfoVote: 'proposalInfoVote'
  },
  TIMELINE_PROPOSAL_STATE_LIST_NAMES: {
    publishedAt: 'Published',
    voteAt: 'Moved to Vote',
    underManagementReviewAt: 'Escalated',
    acceptedAt: 'Accepted',
    rejectedAt: 'Declined'
  },
  PROPOSAL_KEYWORD: 'Proposal',
  DOCUMENT_FILE_KEYWORD: 'DocumentFile',
  CHALLENGE_KEYWORD: 'Challenge',
  USER_KEYWORD: 'User',
  DEFAULT_IMAGE_URL: 'https://swae-etihadsso-documents.s3.us-west-2.amazonaws.com/etihadsso.swae.io/images/Proposal/1cbff7ce-d8fe-4acc-1b01-e577bd4de614-1.png',
  DEFAULT_APP_LOGO_SMALL: 'https://swae-dev-documents.s3-us-west-2.amazonaws.com/defaults/applogoSmall.svg',
  DEFAULT_APP_LOGO: 'https://swae-dev-documents.s3.us-west-2.amazonaws.com/defaults/applogo.png',
  HEX_COLOR_CODE_MAX_LENGTH: 7,
  COMPATIBLE_BROWSER_ALERT_TEXT:
    'Your browser is not compatible with Swae. Please use the following supported browsers: \nChrome (version 67 and above) \nFirefox (version 65 and above) \nEdge (version 42 and above) \nSafari (version 11 and above).\nPlease note that Internet Explorer is not recommended.',

  // keywords to enable team specific features
  MSF_KEYWORD: 'MSF', // MSF Canada
  IN_KEYWORD: 'IN', // Identity North

  MSF_VOTING_BAR: {
    Against: {
      value: '-100',
      label: 'Against'
    },
    Abstain: {
      value: '0',
      label: 'Abstain'
    },
    For: {
      value: '100',
      label: 'For'
    }
  },
  CHALLENGE_PHASES: {
    AI_REVIEW: 'aireview',
    COLLABORATE: 'collaborate',
    VOTE: 'vote',
    PROPOSAL_REVIEW: 'proposalReview'
  },
  WORKFLOW_TYPES: {
    TIMEBOUND: '0',
    CONTINUOUS: '1'
  },
  SUB_DOMAIN_REGEXP: '^[A-Za-z0-9-]*$',
  OPTION_NO: 'no',
  ONLINE_KEYWORD: 'Online',
  OFFLINE_KEYWORD: 'Offline',
  SUGGESTION_TYPES: {
    GRAMMAR: 'grammar',
    TEXT: 'text',
    TONE: 'tone'
  },
  EXPANSION_VIEW_TYPES: {
    REASON: 0,
    CHALLENGE: 1
  },
  CONFIRM_DELETE_TYPE: {
    PROPOSAL: 0,
    QSECTION: 1,
    AIRTE: 2
  },
  IMAGE_TYPE: {
    BROWSER: 'browser',
    UNSPLASH: 'unsplash'
  },
  DISCARDED_NOTIFICATION_TYPES: [
    'sentForUserApproval',
    'aiUserCreated',
    'proposalCreated',
    'proposalPublished',
    'teamInvited',
    'signedUp',
    'teamRequestAccept',
    'createdTeamRequest',
    'joinTeamRequest',
    'teamInvitedAsManagementUser',
    'proposalDeletedByManagement',
    'teamRequestReject',
    'teamCreatedByAdmin',
    'joinTeamRequestAccept',
    'joinTeamRequestReject',
    'joinRequestManagementUser',
    'createTeamRequestAdminUser',
    'proposalPublishedSentForAllUser',
    'submitForVote',
    'proposalCreatedUser',
    'addUserToChallenge'
  ],
  NOTIFICATIONS_TYPE_MAPPING: {
    managementReviewed: {
      title: 'The verdict is in, your proposal has been reviewed by management.',
      icon: 'RATE_REVIEW_ICON'
    },
    commented: {
      title: 'Your proposal has received a comment',
      icon: 'QUESTION_ANSWER_ICON'
    },
    mentionComment: {
      title: 'You have been mentioned in a comment.',
      icon: 'QUESTION_ANSWER_ICON'
    },
    Suggested: {
      title: 'Your proposal has been suggested.',
      icon: 'QUESTION_ANSWER_ICON'
    },
    repliedForSuggestion: {
      title: 'You got reply to the suggestion you made.',
      icon: 'QUESTION_ANSWER_ICON'
    },
    suggestionAccept: {
      title: 'Want to change your vote? A proposal you have voted on has been edited.',
      icon: 'TOUCH_APP_ICON'
    },
    contributed: {
      title: 'Someone has voted on a proposal you contributed to',
      icon: 'TOUCH_APP_ICON'
    },
    flaggedCommentsDelete: {
      title: 'Your comment has been deleted.',
      icon: 'HIGH_LIGHT_OFF_ICON'
    },
    volunteered: {
      title: 'Someone has volunteered on your proposal',
      icon: 'GROUP_ICON'
    },
    thresholdMet: {
      title: 'Your proposal has been escalated upwards for a management review',
      icon: 'DONE_ALL_ICON'
    },
    acceptReject: {
      title: 'Your proposal has been reviewed by management',
      icon: 'RATE_REVIEW_ICON'
    },
    invitedToCollaborate: {
      title: 'Invitation to collaborate on proposal',
      icon: 'EDIT_ICON'
    },
    voted: {
      title: 'Your proposal has received a new vote',
      icon: 'TOUCH_APP_ICON'
    },
    replied: {
      title: 'You received a reply to the comment you made on a proposal',
      icon: 'QUESTION_ANSWER_ICON'
    }
  },
  EMOTION_URL_MAPPING: {
    Pro: 'strengths',
    Neutral: 'questions',
    Against: 'weaknesses'
  },
  UN_CHALLENGE_CATEGORY_MAPPING: ['Our Future', 'Our World', 'Our Society', 'Our United Nations', 'View All'],
  UN_CHALLENGE_CATEGORY_MAPPING_FOR_OBJECT_KEYS: ['ourFuture', 'ourWorld', 'ourSociety', 'ourUnitedNations', 'viewAll'],
  UN_CHALLENGE_CATEGORY_MAPPING_FOR_OBJECTS: {
    viewAll: 'View All',
    ourSociety: 'Our Society',
    ourWorld: 'Our World',
    ourFuture: 'Our Future',
    ourUnitedNations: 'Our United Nations'
  },
  UN_CHALLENGE_CATEGORY_MAPPING_FOR_PANELS: {
    0: 'ourFuture',
    1: 'ourWorld',
    2: 'ourSociety',
    3: 'ourUnitedNations'
  },
  UN_CHALLENGE_CATEGORY_MAPPING_FOR_PANELS_VICE_VERSA: {
    ourFuture: 0,
    ourWorld: 1,
    ourSociety: 2,
    ourUnitedNations: 3
  },
  SECTOR_MAPPING: {
    ngoCount: 'NGOs',
    privateSectorCount: 'Private Sector',
    citiesAndLocalAuthoritiesCount: 'Cities and Local Authorities',
    parliamentariansCount: 'Parliamentarians',
    workersAndTradeUnionsCount: 'Workers and Trade Unions',
    philanthropicOrganizationsCount: 'Philanthropic Organizations',
    privateSocialImpactEnterprisesCount: 'Private Social Impact Enterprises',
    universitiesCount: 'Universities',
    thinkTanksCount: 'Think Tanks',
    otherSectorCount: 'Other'
  },
  UNDER_REPRESENTED_GROUPS_MAPPING: {
    noCount: 'Do not identify',
    indigenousPeoplesCount: 'Indigenous Peoples',
    peopleWithDisabilitiesCount: 'People with Disabilities',
    womenAndGirlsCount: 'Women and Girls',
    LGBTQCount: 'LGBTQ+',
    ethnicRacialAndReligiousMinoritiesCount: 'Ethnic, Racial and Religious Minorities',
    childrenAndYouthCount: 'Children and Youth',
    statelessPeopleCount: 'Conflict-affected, Displaced and Stateless People, incl. Refugees',
    traffickedPersonsAndForcedLabourersCount: 'Trafficked Persons and Forced Labourers',
    ruralCommunitiesAndSmallholderFarmersCount: 'Rural Communities and Smallholder Farmers',
    faithBasedOrganizationsCount: 'Faith-based Organizations',
    migrantAndDomesticWorkersCount: 'Migrant and Domestic Workers',
    otherUnderRepresentedGroupsCount: 'Other'
  },
  LANGUAGES_MAPPING: {
    englishCount: 'English',
    mandarinCount: 'Mandarin',
    russianCount: 'Russian',
    frenchCount: 'French',
    spanishCount: 'Spanish',
    arabicCount: 'Arabic'
  },
  AGE_BRACKETS_MAPPING: {
    lt15Count: 'Less than 15',
    from16to30Count: '16 - 30',
    from31to45Count: '31 - 45',
    from46to60Count: '46 - 60',
    gt61Count: 'Over 61'
  },
  GENDER_MAPPING: {
    femaleCount: 'Female',
    maleCount: 'Male',
    nonBinaryCount: 'Non-Binary',
    otherGenderCount: 'Other'
  },
  LOCATION_MAPPING: {
    afghanistan: 'Afghanistan',
    alandIslands: 'Åland Islands',
    albania: 'Albania',
    algeria: 'Algeria',
    americanSamoa: 'American Samoa',
    andorra: 'Andorra',
    angola: 'Angola',
    anguilla: 'Anguilla',
    antarctica: 'Antarctica',
    antiguaAndBarbuda: 'Antigua and Barbuda',
    argentina: 'Argentina',
    armenia: 'Armenia ',
    aruba: 'Aruba',
    australia: 'Australia',
    austria: 'Austria',
    azerbaijan: 'Azerbaijan ',
    bahamas: 'Bahamas ',
    bahrain: 'Bahrain',
    bangladesh: 'Bangladesh',
    barbados: 'Barbados',
    belarus: 'Belarus',
    belgium: 'Belgium',
    belize: 'Belize',
    benin: 'Benin',
    bermuda: 'Bermuda',
    bhutan: 'Bhutan',
    boliviaPlurinationalStateOf: 'Bolivia (Plurinational State of)',
    bonaireSintEustatiusAndSaba: 'Bonaire, Sint Eustatius and Saba',
    bosniaAndHerzegovina: 'Bosnia and Herzegovina',
    botswana: 'Botswana',
    bouvetIsland: 'Bouvet Island',
    brazil: 'Brazil',
    britishIndianOceanTerritory: 'British Indian Ocean Territory',
    britishVirginIslands: 'British Virgin Islands',
    bruneiDarussalam: 'Brunei Darussalam',
    bulgaria: 'Bulgaria',
    burkinaFaso: 'Burkina Faso',
    burundi: 'Burundi',
    caboVerde: 'Cabo Verde',
    cambodia: 'Cambodia',
    cameroon: 'Cameroon',
    canada: 'Canada',
    caymanIslands: 'Cayman Islands',
    centralAfricanRepublic: 'Central African Republic',
    chad: 'Chad',
    chile: 'Chile',
    china: 'China',
    chinaHongKongSpecialAdministrativeRegion: 'China, Hong Kong Special Administrative Region',
    chinaMacaoSpecialAdministrativeRegion: 'China, Macao Special Administrative Region',
    christmasIsland: 'Christmas Island',
    cocosKeelingIslands: 'Cocos (Keeling) Islands',
    colombia: 'Colombia',
    comoros: 'Comoros',
    congo: 'Congo',
    cookIslands: 'Cook Islands',
    costaRica: 'Costa Rica',
    coteDIvoire: 'Côte d’Ivoire',
    croatia: 'Croatia',
    cuba: 'Cuba',
    curacao: 'Curaçao',
    cyprus: 'Cyprus',
    czechia: 'Czechia',
    democraticPeoplesRepublicOfKorea: "Democratic People's Republic of Korea",
    democraticRepublicOfTheCongo: 'Democratic Republic of the Congo',
    denmark: 'Denmark',
    djibouti: 'Djibouti',
    dominica: 'Dominica',
    dominicanRepublic: 'Dominican Republic',
    ecuador: 'Ecuador',
    egypt: 'Egypt',
    elSalvador: 'El Salvador',
    equatorialGuinea: 'Equatorial Guinea',
    eritrea: 'Eritrea',
    estonia: 'Estonia',
    eswatini: 'Eswatini',
    ethiopia: 'Ethiopia',
    falklandIslandsMalvinas: 'Falkland Islands (Malvinas)',
    faroeIslands: 'Faroe Islands',
    fiji: 'Fiji',
    finland: 'Finland',
    france: 'France',
    frenchGuiana: 'French Guiana',
    frenchPolynesia: 'French Polynesia',
    frenchSouthernTerritories: 'French Southern Territories',
    gabon: 'Gabon',
    gambia: 'Gambia',
    georgia: 'Georgia',
    germany: 'Germany',
    ghana: 'Ghana',
    gibraltar: 'Gibraltar',
    greece: 'Greece',
    greenland: 'Greenland',
    grenada: 'Grenada',
    guadeloupe: 'Guadeloupe',
    guam: 'Guam',
    guatemala: 'Guatemala',
    guernsey: 'Guernsey',
    guinea: 'Guinea',
    guineaBissau: 'Guinea-Bissau',
    guyana: 'Guyana',
    haiti: 'Haiti',
    heardIslandAndMcDonaldIslands: 'Heard Island and McDonald Islands',
    holySee: 'Holy See',
    honduras: 'Honduras',
    hungary: 'Hungary',
    iceland: 'Iceland',
    india: 'India',
    indonesia: 'Indonesia',
    iranIslamicRepublicOf: 'Iran (Islamic Republic of)',
    iraq: 'Iraq',
    ireland: 'Ireland',
    isleOfMan: 'Isle of Man',
    israel: 'Israel',
    italy: 'Italy',
    jamaica: 'Jamaica',
    japan: 'Japan',
    jersey: 'Jersey',
    jordan: 'Jordan',
    kazakhstan: 'Kazakhstan',
    kenya: 'Kenya',
    kiribati: 'Kiribati',
    kuwait: 'Kuwait',
    kyrgyzstan: 'Kyrgyzstan',
    laoPeoplesDemocraticRepublic: "Lao People's Democratic Republic",
    latvia: 'Latvia',
    lebanon: 'Lebanon',
    lesotho: 'Lesotho',
    liberia: 'Liberia',
    libya: 'Libya',
    liechtenstein: 'Liechtenstein',
    lithuania: 'Lithuania',
    luxembourg: 'Luxembourg',
    madagascar: 'Madagascar',
    malawi: 'Malawi',
    malaysia: 'Malaysia',
    maldives: 'Maldives',
    mali: 'Mali',
    malta: 'Malta',
    marshallIslands: 'Marshall Islands',
    martinique: 'Martinique',
    mauritania: 'Mauritania',
    mauritius: 'Mauritius',
    mayotte: 'Mayotte',
    mexico: 'Mexico',
    micronesiaFederatedStatesOf: 'Micronesia (Federated States of)',
    monaco: 'Monaco',
    mongolia: 'Mongolia',
    montenegro: 'Montenegro',
    montserrat: 'Montserrat',
    morocco: 'Morocco',
    mozambique: 'Mozambique',
    myanmar: 'Myanmar',
    namibia: 'Namibia',
    nauru: 'Nauru',
    nepal: 'Nepal',
    netherlands: 'Netherlands',
    newCaledonia: 'New Caledonia',
    newZealand: 'New Zealand',
    nicaragua: 'Nicaragua',
    niger: 'Niger',
    nigeria: 'Nigeria',
    niue: 'Niue',
    norfolkIsland: 'Norfolk Island',
    northMacedonia: 'North Macedonia',
    northernMarianaIslands: 'Northern Mariana Islands',
    norway: 'Norway',
    oman: 'Oman',
    pakistan: 'Pakistan',
    palau: 'Palau',
    panama: 'Panama',
    papuaNewGuinea: 'Papua New Guinea',
    paraguay: 'Paraguay',
    peru: 'Peru',
    philippines: 'Philippines',
    pitcairn: 'Pitcairn',
    poland: 'Poland',
    portugal: 'Portugal',
    puertoRico: 'Puerto Rico',
    qatar: 'Qatar',
    republicOfKorea: 'Republic of Korea',
    republicOfMoldova: 'Republic of Moldova',
    reunion: 'Réunion',
    romania: 'Romania',
    russianFederation: 'Russian Federation',
    rwanda: 'Rwanda',
    saintBarthelemy: 'Saint Barthélemy',
    saintHelena: 'Saint Helena',
    saintKittsAndNevis: 'Saint Kitts and Nevis',
    saintLucia: 'Saint Lucia',
    saintMartinFrenchPart: 'Saint Martin (French Part)',
    saintPierreAndMiquelon: 'Saint Pierre and Miquelon',
    saintVincentAndTheGrenadines: 'Saint Vincent and the Grenadines',
    samoa: 'Samoa',
    sanMarino: 'San Marino',
    saoTomeAndPrincipe: 'Sao Tome and Principe',
    sark: 'Sark',
    saudiArabia: 'Saudi Arabia',
    senegal: 'Senegal',
    serbia: 'Serbia',
    seychelles: 'Seychelles',
    sierraLeone: 'Sierra Leone',
    singapore: 'Singapore',
    sintMaartenDutchPart: 'Sint Maarten (Dutch part)',
    slovakia: 'Slovakia',
    slovenia: 'Slovenia',
    solomonIslands: 'Solomon Islands',
    somalia: 'Somalia',
    southAfrica: 'South Africa',
    southGeorgiaAndTheSouthSandwichIslands: 'South Georgia and the South Sandwich Islands',
    southSudan: 'South Sudan',
    spain: 'Spain',
    sriLanka: 'Sri Lanka',
    stateOfPalestine: 'State of Palestine',
    sudan: 'Sudan',
    suriname: 'Suriname',
    svalbardAndJanMayenIslands: 'Svalbard and Jan Mayen Islands',
    sweden: 'Sweden',
    switzerland: 'Switzerland',
    syrianArabRepublic: 'Syrian Arab Republic',
    tajikistan: 'Tajikistan',
    thailand: 'Thailand',
    timorLeste: 'Timor-Leste',
    togo: 'Togo',
    tokelau: 'Tokelau',
    tonga: 'Tonga',
    trinidadAndTobago: 'Trinidad and Tobago',
    tunisia: 'Tunisia',
    turkey: 'Turkey',
    turkmenistan: 'Turkmenistan',
    turksAndCaicosIslands: 'Turks and Caicos Islands',
    tuvalu: 'Tuvalu',
    uganda: 'Uganda',
    ukraine: 'Ukraine',
    unitedArabEmirates: 'United Arab Emirates',
    unitedKingdomOfGreatBritainAndNorthernIreland: 'United Kingdom of Great Britain and Northern Ireland',
    unitedRepublicOfTanzania: 'United Republic of Tanzania',
    unitedStatesMinorOutlyingIslands: 'United States Minor Outlying Islands',
    unitedStatesOfAmerica: 'United States of America',
    unitedStatesVirginIslands: 'United States Virgin Islands',
    uruguay: 'Uruguay',
    uzbekistan: 'Uzbekistan',
    vanuatu: 'Vanuatu',
    venezuelaBolivarianRepublicOf: 'Venezuela (Bolivarian Republic of)',
    vietNam: 'Viet Nam',
    wallisAndFutunaIslands: 'Wallis and Futuna Islands',
    westernSahara: 'Western Sahara',
    yemen: 'Yemen',
    zambia: 'Zambia',
    zimbabwe: 'Zimbabwe'
  },
  TIME_ZONES_LIST: [
    'Prefer to not disclose',
    'GMT GMT',
    'UTC GMT',
    'ECT GMT+1:00',
    'EET GMT+2:00',
    'ART GMT+2:00',
    'EAT GMT+3:00',
    'MET GMT+3:30',
    'NET GMT+4:00',
    'PLT GMT+5:00',
    'IST GMT+5:30',
    'BST GMT+6:00',
    'VST GMT+7:00',
    'CTT GMT+8:00',
    'JST GMT+9:00',
    'ACT GMT+9:30',
    'AET GMT+10:00',
    'SST GMT+11:00',
    'NST GMT+12:00',
    'MIT GMT-11:00',
    'HST GMT-10:00',
    'AST GMT-9:00',
    'PST GMT-8:00',
    'PNT GMT-7:00',
    'MST GMT-7:00',
    'CST GMT-6:00',
    'EST GMT-5:00',
    'IET GMT-5:00',
    'PRT GMT-4:00',
    'CNT GMT-3:30',
    'AGT GMT-3:00',
    'BET GMT-3:00',
    'CAT GMT-1:00'
  ],
  TAG_TOOLTIPS_TEXT: {
    NEW_TAG: 'These are brand new proposals, have only been on the platform for 72 hours or less. Give them some love and help them to grow!',
    BUZZING_TAG:
      'These proposals are gaining momentum and your support could tip them over the edge. At least 20% of monthly active users have commented on these proposals and these have been on the platform for 14 days or less.',
    HIGHLY_RATED_TAG: 'People love this Proposal. Check out why!',
    NEARLY_DECISION_READY_TAG: 'This Proposal is close the \'Review\' stage - can you help collaborate to help move it towards a decision?',
    NEEDS_SUPPORT_TAG: 'This Proposal is close to being \'Archived\' - can you help collaborate to avoid it closing?',
    AWARDED_TAG: 'This proposal received an Award. See why...',
    TRENDING_TAG:
      'These proposals are popular and have received considerable attention. These proposals have met at least 30% of the engagement and progress metrics, these are  being evaluated against and have only been on the platform for 14 days or less. This is your chance to add your voice to a popular idea that very well could turn into the next decision',
    ENDING_TAG: 'These proposals are for the missions that end in less than 3 days - hurry up with your feedback!'
  },
  SWAE_FEEDBACK_MAIL: ['feedback@swae.io'],
  USER_DELETED: 'Deleted',
  PROPOSAL_CARD_SUMMARY_LENGTH: 110,
  CHECK_CONSENT_TEXT: 'I agree to receive marketing communications from Swae and understand I can unsubscribe by clicking the ‘unsubscribe’ link in any email or by contacting us.',
  REACTION_TYPES: {
    GENIUS: 'genius',
    LOVE: 'love',
    HOT: 'hot',
    CLAP: 'clap',
    CELBRATE: 'celebrate',
    HAPPY: 'happy',
    LAUGH: 'laugh',
    CURIOUS: 'curious',
    SAD: 'sad',
    ANGER: 'anger',
    THUMBS_UP: 'thumbsup',
    THUMBS_DOWN: 'thumbsdown'
  },
  AWARD_TO: [
    {
      title: 'Award goes to person',
      value: 0
    },
    {
      title: 'Award goes to proposal',
      value: 1
    }
  ],
  ALL_EMOJI_OBJ: {
    emojiType: 'all'
  },
  ICON_EXTENSIONS: ['image/svg+xml', 'image/vnd.microsoft.icon'],
  STRIPE_SUBSCRIPTION_STATUS: {
    ACTIVE: 'active',
    PAST_DUE: 'past_due',
    UNPAID: 'unpaid',
    CANCELED: 'canceled',
    INCOMPLETE: 'incomplete',
    INCOMPLETE_EXPIRED: 'incomplete_expired',
    TRIALING: 'trialing'
  },
  DEFAULT_AWARD_HEADER_DESCRIPTION: 'To supercharge your ideas, we\'re giving some great awards on this Mission...',
  DECISION_STATE_URL: {
    ACTIVE: '/decisions/active',
    UNDER_REVIEW: '/decisions/under-review',
    ARCHIVED: '/decisions/archived',
    DECLINED: '/decisions/declined',
    ACCEPTED: '/decisions/accepted',
    IMPLEMENTED: '/decisions/implemented'
  },
  PAGINATION_VALUE: {
    FROM_0: 0,
    PG_SIZE_10: 10
  },
  MISSION_URLS: {
    MISSION_TEMPLATE: '/mission-template',
    MISSION_SUCCESS_TEMPLATE: '/mission-success/templates',
    MISSION_CREATION: '/mission/create',
    DEFAULT_PAGE: '/',
    MY_ACTIVITY_DRAFT_MISSION: '/my-activities/mission/draft'
  },
  VIEW_KNOWLEDGEBASE_LINK: 'https://swae.io/help-center/#articles',
  HOW_SWAE_WORKS_LINK: 'https://swae.io/help-center/#videos',
  FAQ_LINK: 'https://swae.io/help-center/#faqs',
  HOW_SWAE_WORKS_YOUTUBE_EMBED_ID: 'gDWdM0NLL8M',
  MISSION_VIEW_AUDIT_TYPE: '0',
  PROPOSAL_VIEW_AUDIT_TYPE: '2',
  WIZARD_PROPOSAL_BUILDER_ANIMATION_TEXT_LIST: ['hi', 'imSwaeAI', 'imHereToHelpYouBuildYourProposal', 'illMakeAFewSuggestionsAlongTheWay'],
  PROPOSAL_BUILDER_TYPE: {
    DIY_PROPOSAL_BUILDER: 'diyProposalBuilder',
    AI_GUIDED_BUILDER: 'aiGuidedBuilder'
  },
  SNAPSHOT_PARAMS: {
    app: 'snapshot',
    snapshot: 15833905,
    network: '1'
  },
  SNAPSHOT_VOTING_SYSTEM_TYPES_MAPPING: {
    'Single choice voting': 'single-choice',
    'Approval voting': 'approval',
    'Quadratic voting': 'quadratic',
    'Ranked choice voting': 'ranked-choice',
    'Weighted voting': 'weighted',
    'Basic voting': 'basic'
  },
  SNAPSHOT_VOTING_SYSTEM_TYPES_REVERSE_MAPPING: {
    'single-choice': 'Single choice voting',
    'approval': 'Approval voting',
    'quadratic': 'Quadratic voting',
    'ranked-choice': 'Ranked choice voting',
    'weighted': 'Weighted voting',
    'basic': 'Basic voting'
  },
  SNAPSHOT_SINGLE_CHOICE_VOTING: 'single-choice',
  SNAPSHOT_APPROVAL_VOTING: 'approval',
  SNAPSHOT_QUADRATIC_VOTING: 'quadratic',
  SNAPSHOT_RANKED_CHOICE_VOTING: 'ranked-choice',
  SNAPSHOT_WEIGHTED_VOTING: 'weighted',
  SNAPSHOT_BASIC_VOTING: 'basic',
  SNAPSHOT_VOTING_SYSTEM_TYPES: [{
    categoryId: '0',
    name: 'Single choice voting',
    info: 'Each voter may select only one choice.'
  },
  {
    categoryId: '1',
    name: 'Approval voting',
    info: 'Each voter may select any number of choices.'
  },
  {
    categoryId: '2',
    name: 'Quadratic voting',
    info: 'Each voter may spread voting power across any number of choices. Results are calculated quadratically.'
  },
  {
    categoryId: '3',
    name: 'Ranked choice voting',
    info: 'Each voter may select and rank any number of choices. Results are calculated by instant-runoff counting method.'
  },
  {
    categoryId: '4',
    name: 'Weighted voting',
    info: 'Each voter may spread voting power across any number of choices.'
  },
  {
    categoryId: '5',
    name: 'Basic voting',
    info: 'Single choice voting with three choices: For, Against or Abstain'
  }],
  SNAPSHOT_BASIC_VOTING_CHOICES: ['For', 'Against', 'Abstain']
}
