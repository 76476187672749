const en = {
  voteTitle: 'Vote On Ideas',
  noProposalMessage: 'No Idea!',
  introductionDescription: 'some questions are automatically included: Idea Title, Summary, Photos & Videos',
  proposal: 'Idea',
  setEscalationCriteriaDescription: 'Choose criteria that escalates a idea',
  totalNumberOfProposalViews: 'Minimum number of Idea Views',
  proposalTrendsTitle: 'Idea Trends',
  proposalNumbersTitle: 'Idea Numbers',
  proposalHeaderTitle: 'Idea Email Notification settings',
  proposalWorkflowTitle: 'Idea Workflow',
  myProposalsTitle: 'My Ideas',
  collabDesc: 'Find new ideas and collaborate',
  noProposalsMessage: 'No Ideas!',
  managementProposalsTitle: 'Ideas',
  newProposal: 'New Idea',
  startANewProposal: 'Start a new idea',
  newProposalTitle: 'New Idea',
  proposalReviewTitle: 'Idea Review',
  searchProposal: 'Search Idea',
  inappropriateContentText: 'This idea has been deleted for disciplinary or inappropriate language',
  votingSubText: 'Please vote to make your voice heard on whether this idea should be actioned',
  proposalReviewText: 'Idea Review',
  yourProposalText: 'Your idea',
  editYourProposalText: 'Edit your idea',
  createCollaborateMessageText: 'Your colleagues can now collaborate on your idea and can check the status in ',
  successDialogContentText: 'Your idea has been submitted to human-augmented AI review. A human analyst will review our AI\'s work prior to sending your idea back to you, to ensure quality and add some personal touches. This process normally takes up to 24 hours.'
}
export default en
